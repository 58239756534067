import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	BarController,
	PointElement,
	LineElement,
	LineController,
	Legend,
	Title,
	Tooltip,
	registerables
} from "chart.js";
import { Chart } from "react-chartjs-2";
import {Card, Col, Image, ProgressBar, Row} from "react-bootstrap";
import {getFinanceFormattedValue, numberFormat} from "../utils/Utils";
import {addSpaceSeparator, getLossEstimate, quantizeNumber} from "./utils";
import {destroyUpdate, borderNetWorth, fullNetWorth} from "../data/data";
import {NAMES} from "../data/constants";
import {LOSS_GROUP} from "../data/combineData";
ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	BarController,
	PointElement,
	LineElement,
	LineController,
	Legend,
	Title,
	Tooltip
);

//ChartJS.register(...registerables);

function createNotchIcons(notchData) {
	const images = [];

	for (let i = 0; i < notchData.count; i++) {
		const last = i === notchData.count - 1;
		images.push(<Image key={i} alt={notchData.title} src={last ? notchData.icon_inactive : notchData.icon} style={{opacity: last ? 0.6 : 1, paddingRight: "0.1em", paddingTop: "0em", width: `${notchData.size}em`}}/>);
	}
	return(
		<div className="notch-container">{images}</div>
	);
}

const ticksData = {
	callback: function(value, index, ticks) {
		return value >= 1000 ? `${Math.floor(value/1000)}k` : value;
	}
};

const options = {
	elements: {
		point: {
			radius: 1
		}
	},
	plugins: {
		legend: {
			display: false
		}
	},
	scales: {
		total: {
			type: "linear",
			display: true,
			position: "left",
			ticks: ticksData
		},
		per_day: {
			type: "linear",
			display: true,
			position: "right",
			grid: {
				drawOnChartArea: false,
			},
			ticks: ticksData
		},
		x: {

		}
	},
};

const bigLossTitle = "Втрати вищі за середні значення";
const hugeLossTitle = "Втрати аномально високі";

const updateMobile = (obj) => {
	const isMobile = window.matchMedia("only screen and (max-width: 600px)").matches;

	if(isMobile) {
		const newObj = {...obj};
		const ticksData = {
			callback: function(value, index, ticks) {
				return '';
			}
		};

		newObj.scales.total.ticks = ticksData;
		newObj.scales.per_day.ticks = ticksData;
		newObj.scales.x.display = false;
	}

	return obj;
}

const updateTooltip = (obj, period) => {
	const titleFunc = function(context) {

		return period === LOSS_GROUP.DAILY
			? `День ${context[0].label}`
			: `Місяць: ${context[0].label}`;
	};

	obj.plugins = {...obj.plugins, ...{
			tooltip: {
				callbacks: {
					title: titleFunc
				}
			}
		}}

	return obj;
}

const checkLSUpdate = (type) => {
	if(!destroyUpdate[type]) {
		return false;
	}

	const key = `details_${type}`;
	const value = Number(localStorage.getItem(key));

	return !(value && value === destroyUpdate[type]);
};

const updateLS = (type) => {
	const key = `details_${type}`;
	localStorage.setItem(key, destroyUpdate[type]);
}

export const LineChartVertical = (props) => {
	const dayLoss = props.data.day_loss > 0
		? `+${props.data.day_loss}`
		: "-";
	const spacing = 0.24;

	let bigLoss = null;
	let hugeLoss = null;
	let lossTrend = null;

	if(props.data.day_loss > 0) {
		let icon, color;

		if(props.data.day_loss > props.data.average) {
			icon = "▲";
			color = "green";

			bigLoss = <Image title={bigLossTitle} alt={bigLossTitle} src={"img/fire_icon.png"} style={{height: "1em", paddingLeft: "0.3em", marginTop: "-0.2em"}}/>;

			if(props.data.day_loss/props.data.average > 1.7) {
				icon = "▲▲";
				hugeLoss = <Image title={hugeLossTitle} alt={hugeLossTitle} src={"img/bavovna_icon.png"} style={{height: "1em", paddingLeft: "0.3em", marginTop: "-0.2em"}}/>;
			}
		} else if(props.data.day_loss < props.data.average) {
			icon = "▼";
			color = "red";
		} else if (props.data.day_loss === props.data.average) {
			icon = "▬"; //•
			color = "grey";
		}

		lossTrend = props.data.average
			? <div style={{textAlign: "left", paddingLeft: "0.3em"}}>
				<div style={{color: color, fontSize: "0.7em", paddingTop: "0.25em", marginBottom: "-0.25em"}}>
					{icon}
				</div>
				<div style={{fontSize: "0.7em", paddingLeft: "0.1em"}}>
					<a href="#average_notice" style={{textDecoration: "none"}}>{props.data.average}*</a>
				</div>
			</div>
			: null;
	}

	let dayLossWithTrend = <div style={{display: "inline-flex"}}><h4>{dayLoss}</h4>{lossTrend}</div>

	let progress = null;
	let fullProgress = null;
	// if(borderNetWorth[props.data.type]) {
	// 	progress = (100*props.data.value/borderNetWorth[props.data.type]);
	// 	if(props.data.type === NAMES.TROOPS) {
	// 		progress *= 3;
	// 	}
	//
	// 	if(progress > 100) {
	// 		fullProgress = (100*props.data.value/fullNetWorth[props.data.type]);
	// 		if(props.data.type === NAMES.TROOPS) {
	// 			fullProgress *= 3;
	// 		}
	//
	// 		progress = null;
	// 	}
	// }

	let badge = null;

	if(props.data.badge) {
		const detailsActive = checkLSUpdate(props.data.type);
		const detailsIcon = detailsActive ? props.data.badge.icon_active : props.data.badge.icon;

		badge = <a href={props.data.badge_url} onClick={() => {
			updateLS(props.data.type);
		}}><Image src={detailsIcon} style={{height: props.data.badge.height}} className="card-honors"/></a>;
	}

	let progressBars = null;
	// if(progress) {
	// 	progressBars = <ProgressBar className="loss_progress_bar">
	// 		<ProgressBar now={progress} key={1} />
	// 		<ProgressBar variant="warning" now={100 - progress} key={2} />
	// 	</ProgressBar>
	// } else if (fullProgress) {
	// 	progressBars = <ProgressBar className="loss_progress_bar">
	// 		<ProgressBar variant="danger" now={fullProgress} key={1} />
	// 		<ProgressBar now={100 - fullProgress} key={2} />
	// 	</ProgressBar>
	// }

	const lossEstimate = getLossEstimate(props.data);
	let footer = null;
	if(lossEstimate) {
		footer = <Card.Footer>
			{/*<Image src="/img/flag.png" style={{height: "1em"}}/>*/}
			<div className="loss_est_container">
				<div className="loss_est_column left">
					{quantizeNumber(lossEstimate.prev, 1000)}
				</div>

				<div className="loss_est_column center">

				</div>

				<div className="loss_est_column right">
					{quantizeNumber(lossEstimate.next, 1000)}
				</div>
			</div>

			<ProgressBar className="loss_est_progress_bar">
				<ProgressBar striped now={lossEstimate.estimateProgress} key={1} />
				<ProgressBar striped variant="warning" now={100 - lossEstimate.estimateProgress} key={2} />
			</ProgressBar>

			<div className="loss_est_container">
				<div className="loss_est_column center">
					через ~{lossEstimate.days} {lossEstimate.daysTitle} ({lossEstimate.estimateProgress}%)
				</div>
			</div>
		</Card.Footer>
	}

	return(
		<Col>
			<Card style={{ height: "100%", textAlign: "center" }}>
				<Card.Header style={{height: "2.5em"}}>
					{/*{props.data.notch ? createNotchIcons(props.data.notch) : null}*/}
					<Image src={props.data.icon} style={{paddingRight: spacing + "em", height: props.data.icon_height}}/>
					<span className="h6">{props.data.name}</span>
					{badge}
					{progressBars || null}
				</Card.Header>
				<Card.Body>
					<Card.Title><h3>{addSpaceSeparator(props.data.value)}{bigLoss}{hugeLoss}</h3></Card.Title>
					<Card.Subtitle>{dayLossWithTrend}</Card.Subtitle>
					<Card.Text>
						<Chart data={props.data.chartData} options={updateTooltip(updateMobile(options), props.data.period)} height="150"/>
					</Card.Text>
				</Card.Body>
				{footer}
			</Card>
		</Col>
	);
};

export function LineVertical(props) {
	const spacing = 0.24;
	return(
		<Col>
			<Card style={{ height: "10rem", textAlign: "center" }}>
				<Card.Header style={{height: "2.5em"}}><Image src={props.data.icon} style={{paddingRight: spacing + "em", height: props.data.icon_height}}/><span className="h6">{props.data.name}</span></Card.Header>
				<Card.Body>
					<Card.Title>{getFinanceFormattedValue(props.data.cost)}</Card.Title>
					<Card.Text>
						{addSpaceSeparator(props.data.value)}
					</Card.Text>
				</Card.Body>
			</Card>
		</Col>
	);
}
