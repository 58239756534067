import React from "react";
import {Col, ProgressBar, Row, Table} from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {NAMES} from "../data/constants";
import {borderNetWorth, fullNetWorth} from "../data/data";

ChartJS.register(ArcElement, Tooltip, Legend);

const defaultData = {
	labels: [],
	datasets: [
		{
			label: "",
			data: [],
			backgroundColor: [
				"rgba(14,110,253)",
				"rgba(255,193,8)"
			],
			borderColor: [
				"rgb(5,41,172)",
				"rgb(198,148,3)"
			],
			borderWidth: 1,
		},
	],
};

const defaultDataFullNetworth = {
	labels: [],
	datasets: [
		{
			label: "",
			data: [],
			backgroundColor: [
				"rgba(220,53,69)",
				"rgba(14,110,253)"
			],
			borderColor: [
				"rgb(128,3,29)",
				"rgb(5,41,172)"
			],
			borderWidth: 1,
		},
	],
};

const chartOptions = {
	plugins: {
		legend: {
			display: false
		},
	},
	cutout: "70%",
	animation: false,
	events: []
};

export function PieLossTable(props) {
	const troopsLoss = (100*props.data[NAMES.TROOPS].value/fullNetWorth[NAMES.TROOPS]).toFixed(2);
	const troopsWound = troopsLoss * 2;

	return (
		<div>
			<Row style={{padding: "4px"}}>
				<Col style={{textAlign: "center"}}>
					<Row>
						<p>Особовий склад, всі збройні сили (вбиті/поранені*):</p>
						<ProgressBar>
							<ProgressBar striped variant="danger" now={troopsLoss} label={`${troopsLoss}%`}/>
							<ProgressBar striped variant="warning" now={troopsWound} label={`~${troopsWound}%`}/>
						</ProgressBar>
					</Row>
					<br/>
					<Row xs={2} sm={2} md={3} lg={4} className="g-4">
						<Col><DoughnutItem data={{fullData: props.data, id: NAMES.BMP_BTR}}/></Col>
						<Col><DoughnutItem data={{fullData: props.data, id: NAMES.TANKS}}/></Col>
						<Col><DoughnutItem data={{fullData: props.data, id: NAMES.ARTA}}/></Col>
						<Col><DoughnutItem data={{fullData: props.data, id: NAMES.HELI}}/></Col>
						<Col><DoughnutItem data={{fullData: props.data, id: NAMES.JETS}}/></Col>
						<Col><DoughnutItem data={{fullData: props.data, id: NAMES.SHIPS}}/></Col>
					</Row>
				</Col>
				<Col>
					<Table bordered hover striped>
						<tbody>
							<tr>
								<td></td>
								<td>Всі збройні сили росії ***</td>
								<td>Призначені для вторгення **</td>
								<td>Знищено</td>
							</tr>
							<tr>
								<td>{props.data[NAMES.TROOPS].name}</td>
								<td>900 000</td>
								<td>140 000</td>
								<td>{props.data[NAMES.TROOPS].value}</td>
							</tr>
							<tr>
								<td>{props.data[NAMES.TANKS].name}</td>
								<td>3 300</td>
								<td>1 200</td>
								<td>{props.data[NAMES.TANKS].value}</td>
							</tr>
							<tr>
								<td>{props.data[NAMES.BMP_BTR].name}</td>
								<td>13 758</td>
								<td>2 900</td>
								<td>{props.data[NAMES.BMP_BTR].value}</td>
							</tr>
							<tr>
								<td>{props.data[NAMES.ARTA].name}</td>
								<td>5 689</td>
								<td>1 600</td>
								<td>{props.data[NAMES.ARTA].value}</td>
							</tr>
							<tr>
								<td>{props.data[NAMES.JETS].name}</td>
								<td>1 379</td>
								<td>330</td>
								<td>{props.data[NAMES.JETS].value}</td>
							</tr>
							<tr>
								<td>{props.data[NAMES.HELI].name}</td>
								<td>961</td>
								<td>240</td>
								<td>{props.data[NAMES.HELI].value}</td>
							</tr>
							<tr>
								<td>{props.data[NAMES.SHIPS].name}</td>
								<td>519</td>
								<td>75</td>
								<td>{props.data[NAMES.SHIPS].value}</td>
							</tr>
						</tbody>
					</Table>
					<div className="left">
						<p className="left-small-text">Угрупування військ біля України - дані української розвідки<br/>
                            Втрати росії - дані генерального штабу ЗСУ<br/>
                            Розрахунки - VoxCheck</p>
						<p></p>
						<p className="left-small-text">* Кількість поранених приблизна (2 особи на кожного вбитого)<br/>
                            ** Дані про ресурси, що призначені для вторгнення в Україну<br/>
                            *** Дані довідника "Military balance 2021"</p>
					</div>
				</Col>
			</Row>
		</div>
	);
}

function DoughnutItem(props) {
	const { id, fullData } = props.data;
	const percent = 100*fullData[id].value/borderNetWorth[id];
	const percentFull = percent > 100 ? 100*fullData[id].value/fullNetWorth[id] : null;

	const chartData = preparePieData(id, fullData, percentFull, percent);
	const percentBaseString = `${Math.min(100, percent).toFixed(2)}%`
	const percentNextString = percentFull ? `${percentFull.toFixed(2)}%` : null;
	const nextPercent = <span className="next-percent-span"><br/>{percentNextString}</span>;

	return (
		<div style={{
			position: "relative"
		}}>
			<h6>{fullData[id].name}</h6>
			<Doughnut data={chartData} options={chartOptions} height="150"/>
			<div style={{
				position: "absolute",
				top: percent > 100 ? "45%" : "50%",
				left: "0",
				textAlign: "center",
				width: "100%"
			}}>
				<p>{percentBaseString}{percentNextString ? nextPercent : null}</p>
			</div>
		</div>
	);
};

function preparePieData(id, fullProps, percentFull, percent) {
	const skeletonData = JSON.parse(JSON.stringify(percentFull ? defaultDataFullNetworth : defaultData));
	//const percent = (100*fullProps[id].value/netWorth[id]).toFixed(2);

	const chartPercent = percentFull || percent;

	skeletonData.datasets[0].data.push(chartPercent, 100 - chartPercent);
	skeletonData.labels.push(`${fullProps[id].name}`);

	return skeletonData;
}
