import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {General} from "./pages/General";
import {Finance} from "./pages/Finance";
import {Relative} from "./pages/Relative";
import {Tweets} from "./pages/Tweets";
import React, {useState} from "react";
import {combineData, LOSS_GROUP, prepareOneTypeData} from "./data/combineData";
import {addDays, differenceInCalendarDays, getMonth, getYear} from "date-fns";
import {lossData, lossDataNew, warStarted} from "./data/data";
import {NAMES} from "./data/constants";
import {DestroyedShips} from "./pages/DestroyedShips";
import {DestroyedUAVs} from "./pages/DestroyedUAVs";
import {DestroyedJets} from "./pages/DestroyedJets";
import {DestroyedHeli} from "./pages/DestroyedHeli";

const combinedData = combineData(lossData, lossDataNew, warStarted);
const nameKeys = Object.keys(NAMES);

const prepareDataDays = (data, period, startIndex, endIndex) => {
	const result = {};
	const values = {};
	const diffs = {};
	const monthValues = {};
	const monthDiffs = {};
	const prevValues = {};

	const monthStarted = getMonth(warStarted);
	const yearStarted = getYear(addDays(warStarted, startIndex));
	const yearEnded = getYear(addDays(warStarted, endIndex));
	const startIndexMonth = getMonth(addDays(warStarted, startIndex)) - monthStarted + ((yearStarted - 2022)*12);
	const endIndexMonth = getMonth(addDays(warStarted, endIndex)) - monthStarted + ((yearEnded - 2022)*12) + 1;

	console.log(`${startIndex} - ${endIndex}: monthStarted ${monthStarted}, yearStarted ${yearStarted}, yearEnded ${yearEnded}, startIndexMonth ${startIndexMonth}, endIndexMonth ${endIndexMonth}`)

	nameKeys.forEach((name) => {
		values[name] = data.values[name].slice(startIndex, endIndex);
		diffs[name] = data.diffs[name].slice(startIndex, endIndex);
		monthValues[name] = data.monthValues[name].slice(startIndexMonth, endIndexMonth);
		monthDiffs[name] = data.monthDiffs[name].slice(startIndexMonth, endIndexMonth);
		prevValues[name] = data.values[name][startIndex - 1] || 0;
	});

	const cutData = {
		days: data.days.slice(startIndex, endIndex),
		months: data.months.slice(startIndexMonth, endIndexMonth),
		values,
		diffs,
		monthDiffs,
		monthValues
	};

	nameKeys.forEach((name) => {
		result[name] = prepareOneTypeData(name, cutData, prevValues, period);
	});

	//console.log(period);
	//console.log(result);

	return result;
};


let stateUpdater;

export const updateDataToDate = (startDate, endDate, period) => {
	// const diff = Math.ceil((date.getTime() - warStarted.getTime()) / (24 * 60 * 60 * 1000));
	const startIndex = differenceInCalendarDays(startDate, warStarted);
	const endIndex = differenceInCalendarDays(endDate, warStarted) + 1;

	stateUpdater({data: prepareDataDays(combinedData, period, startIndex, endIndex)});
};


const fullData = prepareDataDays(combinedData, LOSS_GROUP.MONTHLY, 0, combinedData.days.length);

function Content() {
	const [state, setState] = useState({
		data: fullData
	});

	stateUpdater = setState;

	return (
		<Router>
			<Routes>
				<Route path="/general" element={<General fullData={state.data}/>}/>
				<Route path="/finance" element={<Finance fullData={state.data}/>}/>
				<Route path="/relative" element={<Relative fullData={state.data}/>}/>
				<Route path="/destroyed-jets" element={<DestroyedJets/>}/>
				<Route path="/destroyed-ships" element={<DestroyedShips/>}/>
				<Route path="/destroyed-uavs" element={<DestroyedUAVs/>}/>
				<Route path="/destroyed-heli" element={<DestroyedHeli/>}/>
				<Route path="/tweets" element={<Tweets/>}/>
				<Route
					path="/"
					element={
						<Navigate replace to="/general"/>
					}
				/>
				<Route
					path="*"
					element={
						<Navigate replace to="/"/>
					}
				/>
			</Routes>
		</Router>
	);
}

export default Content;
