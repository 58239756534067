export const numberFormat = (value) =>
	new Intl.NumberFormat("en-EN", {
		style: "currency",
		currency: "USD",
		useGrouping: true,
		maximumSignificantDigits: 4
	}).format(value);

export const roundToBill = (value) => {
	const bill = (value / 1000000000).toFixed(2);
	return bill;
};

export const getFinanceFormattedValue = (value) => {
	if(value) {
		return `~${numberFormat(value)}`;
	} else {
		return "Невідомо"
	}
}
