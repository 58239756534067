import React from "react";
import {Badge} from "react-bootstrap";

export function Tweets() {
	return(
		<div className="App">
			<h1>
				<Badge bg="primary">Tweets</Badge>
			</h1>
		</div>
	);
}
