import React from "react";
import {Row} from "react-bootstrap";
import {NAMES} from "../data/constants";
import {LineVertical} from "./LineChart";
import {roundToBill} from "../utils/Utils";

export function FinLossTable(props) {
	const totalCost = total(props.data);

	return (

		<Row>
			<Row style={{textAlign: "center"}}>
				<h1>
					{`~$${roundToBill(totalCost)} млрд *`}
				</h1>
				<p>* Оціночна вартість, $млрд, згідно розрахунків Forbes</p>
			</Row>
			<Row xs={2} sm={2} md={3} lg={4} xl={4} className="g-2">
				<LineVertical data={props.data[NAMES.TROOPS]}/>
				<LineVertical data={props.data[NAMES.BMP_BTR]}/>
				<LineVertical data={props.data[NAMES.TANKS]}/>
				<LineVertical data={props.data[NAMES.JETS]}/>
				<LineVertical data={props.data[NAMES.UAV]}/>
				<LineVertical data={props.data[NAMES.HELI]}/>
				<LineVertical data={props.data[NAMES.ARTA]}/>
				<LineVertical data={props.data[NAMES.SHIPS]}/>
				<LineVertical data={props.data[NAMES.AUTO]}/>
			</Row>
		</Row>
	);
}

const total = (props) => {
	return Object.values(props).reduce((acc, item) => {
		return acc + item.cost;
	}, 0);
};
