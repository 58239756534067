import {estimates} from "../data/constants";

export const addSpaceSeparator = (value) => {
	const sValue = String(value);
	let result = "", counter = sValue.length%3 || 3;
	for (let i = 0; i < sValue.length; i++) {
		result += sValue[i];
		counter--;

		if(counter === 0 && i !== sValue.length-1) {
			counter = 3;
			result += " ";
		}
	}
	return result;
};

export const quantizeNumber = (value, base=1) => {
	let newValue = value/base;

	switch (base) {
		case 1000: {
			newValue += "k";
			break;
		}
	}

	return newValue;
};

export const getLossEstimate = (data) => {
	if(estimates[data.type].length) {
		const goalValue = estimates[data.type][0];
		const prevGoalValue = estimates[data.type][1];
		const estimateGoal = Math.ceil((goalValue - data.value)/data.average);
		const estimateProgress = Math.floor(100*(data.value - prevGoalValue)/(goalValue-prevGoalValue));

		const daysString = String(estimateGoal);
		const lastDigit = daysString[daysString.length-1];
		let daysTitle = "день";

		if(lastDigit > 1 && lastDigit <= 4) {
			daysTitle = "дні";
		} else if(estimateGoal > 4 || lastDigit === 0 || estimateGoal === 0) {
			daysTitle = "днів";
		}

		return {
			prev: prevGoalValue,
			next: goalValue,
			estimateProgress,
			days: estimateGoal,
			daysTitle
		}
	} else {
		return null;
	}


}
