import React from "react";
import {Card, Col, ListGroup, ListGroupItem, Row} from "react-bootstrap";

export function DestroyedShips() {
	return(
		<div>
			<h4 className="text-center">Моделі знищених в Україні російських кораблів</h4>

			<Row xs={1} sm={1} md={2} lg={3} xl={4} className="g-3">
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/ships/moskva.jpg" />
						<Card.Body>
							<Card.Title>Крейсер Москва</Card.Title>
							<Card.Text>
                                «Москва́» — колишній радянський і російський ракетний крейсер проєкту 1164 (шифр «Атлант», англ. Slava class за класифікацією НАТО).
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - $750 млн. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0_(%D1%80%D0%B0%D0%BA%D0%B5%D1%82%D0%BD%D0%B8%D0%B9_%D0%BA%D1%80%D0%B5%D0%B9%D1%81%D0%B5%D1%80)">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/ships/admiral-essen.jpg" />
						<Card.Body>
							<Card.Title>Великий десантний корабель «Саратов»</Card.Title>
							<Card.Text>
                                Великий десантний корабель «Саратов» — великий десантний корабель проєкту 1171 (шифр «Тапір», за кодифікацією НАТО — класу Alligator). Став першим у своїй серії, збудований у Калінінграді в 1964 році під заводським номером 010.
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - невідомо. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%A1%D0%B0%D1%80%D0%B0%D1%82%D0%BE%D0%B2_(%D0%B2%D0%B5%D0%BB%D0%B8%D0%BA%D0%B8%D0%B9_%D0%B4%D0%B5%D1%81%D0%B0%D0%BD%D1%82%D0%BD%D0%B8%D0%B9_%D0%BA%D0%BE%D1%80%D0%B0%D0%B1%D0%B5%D0%BB%D1%8C)">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/ships/saratov.jpg" />
						<Card.Body>
							<Card.Title>Адмірал Ессен</Card.Title>
							<Card.Text>
                                «Адмірал Ессен» — другий фрегат проєкту 11356Р, що є на озброєнні Чорноморського флоту рф. Корабель входить до складу 30-ї дивізії надводних кораблів.
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - невідомо. <Card.Link href="https://ru.wikipedia.org/wiki/%D0%90%D0%B4%D0%BC%D0%B8%D1%80%D0%B0%D0%BB_%D0%AD%D1%81%D1%81%D0%B5%D0%BD_(%D1%81%D1%82%D0%BE%D1%80%D0%BE%D0%B6%D0%B5%D0%B2%D0%BE%D0%B9_%D0%BA%D0%BE%D1%80%D0%B0%D0%B1%D0%BB%D1%8C)">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/ships/raptor.jpg" />
						<Card.Body>
							<Card.Title>Катер "Раптор"</Card.Title>
							<Card.Text>
                                Патрульні катери проєкту 03160 «Раптор» — серія швидкохідних патрульних катерів прибережної зони.
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - невідомо. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%9F%D0%B0%D1%82%D1%80%D1%83%D0%BB%D1%8C%D0%BD%D1%96_%D0%BA%D0%B0%D1%82%D0%B5%D1%80%D0%B8_%D0%BF%D1%80%D0%BE%D1%94%D0%BA%D1%82%D1%83_03160">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/ships/serna.jpg" />
						<Card.Body>
							<Card.Title>Десантний катер «Се́рна»</Card.Title>
							<Card.Text>
								Десантні катери проєкту 11770 «Се́рна» — серія російських швидкісних десантних катерів на повітряній каверні. Катери проєкту призначені для висадки на необладнаний берег, а також передових підрозділів десанту із озброєнням (92 особи) з високою швидкістю 30 вузлів.
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - невідомо. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%94%D0%B5%D1%81%D0%B0%D0%BD%D1%82%D0%BD%D1%96_%D0%BA%D0%B0%D1%82%D0%B5%D1%80%D0%B8_%D0%BF%D1%80%D0%BE%D1%94%D0%BA%D1%82%D1%83_11770">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</div>

	);
}
