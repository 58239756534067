import React from "react";
import {Card, Col, ListGroup, ListGroupItem, Row} from "react-bootstrap";

export function DestroyedJets() {
	return(
		<div>
			<h4 className="text-center">Моделі знищених в Україні російських літаків</h4>

			<Row xs={1} sm={1} md={2} lg={3} xl={4} className="g-3">
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/jets/su-37.jpg" />
						<Card.Body>
							<Card.Title>СУ-37</Card.Title>
							<Card.Text>
                                Су-37 (за кодифікації НАТО: Flanker-F) — розроблений у радянський/російський період. Експериментальний одномісний надманеврений винищувач четвертого покоління.
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - невідомо. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%A1%D1%83-37">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/jets/su-35.jpg" />
						<Card.Body>
							<Card.Title>СУ-35</Card.Title>
							<Card.Text>
                                Су-35 (Су-27М, заводський шифр Т-10М) (за кодифікації НАТО: Flanker-E) — радянський/російський багатоцільовий винищувач четвертого покоління.
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - $32 млн. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%A1%D1%83-35">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/jets/su-34.jpg" />
						<Card.Body>
							<Card.Title>СУ-34</Card.Title>
							<Card.Text>
                                Су-34 (за кодифікації НАТО: Fullback — укр. Захисник) — радянський/російський всепогодний, двомісний винищувач-бомбардувальник, розроблений в ДКБ Сухого.
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - $40 млн. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%A1%D1%83-34">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/jets/su-25.jpg" />
						<Card.Body>
							<Card.Title>Су-25</Card.Title>
							<Card.Text>
                                Су-25 (за класифікацією НАТО — Frogfoot, неформально — Грач) — одномісний броньований дозвуковий штурмовик.
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - $11 млн. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%A1%D1%83-25">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</div>

	);
}
