import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {Badge, Button} from "react-bootstrap";
import React, {useState} from "react";
import DatePicker from "react-date-picker";
import {subDays, addDays, startOfMonth, startOfWeek, isSunday, differenceInCalendarDays} from "date-fns";
import {updateDataToDate} from "./Content";
import {lossDataNew, warStarted} from "./data/data";
import {LOSS_GROUP} from "./data/combineData";

const lastUpdate = getLastDay(lossDataNew);
let warDuration = "";
let showUpdateBadge, showHintAnimation;
let now = new Date();
let stateUpdater, currentState;

function getLastDay(obj) {
	const arr = Object.keys(obj);
	return new Date(arr[arr.length-1]);
}

function getDuration(now, warStarted) {
	return Math.ceil((now.getTime() - warStarted.getTime())/(60*60*24*1000));
}

function updateWarDuration() {
	warDuration = getDuration(now, warStarted);

	try {
		if(stateUpdater) {
			currentState = {...currentState, ...{
				warDuration,
				now
			}};

			stateUpdater(currentState);
		}
	} catch (e) {
		console.log(e);
	}
}

function updateBadge() {
	if(localStorage) {
		const lastTimestamp = lastUpdate.getTime();
		const storageValue = Number(localStorage.getItem("lastUpdate"));
		const showHint = Boolean(localStorage.getItem("showHint"));
		if(storageValue !== lastTimestamp) {
			showUpdateBadge = true;
			localStorage.setItem("lastUpdate", String(lastTimestamp));
		}
		if(showHint === false) {
			showHintAnimation = true;
			localStorage.setItem("showHint", "false");
		}
	} else {
		showUpdateBadge = false;
	}


}

function showBadge(value) {
	if(stateUpdater) {
		currentState = {...currentState, ...{
			showUpdates: value
		}};

		stateUpdater(currentState);
	}
}

updateWarDuration();
updateBadge();

fetch("https://worldtimeapi.org/api/timezone/Europe/Kiev")
	.then(response=>response.json())
	.then((json) => {
		if(!json.error) {
			now = new Date(json.datetime);
		}
	}).catch((e) => {
		console.log(e);
	}).finally(() => {
		updateWarDuration();
	});

function App() {
	const [state, setState] = useState({
		warDuration: warDuration,
		now,
		showUpdates: showUpdateBadge
	});

	stateUpdater = setState;
	currentState = state;

	const [startDate, setStartDate] = useState(warStarted);
	const [endDate, setEndDate] = useState(lastUpdate);
	const [autoFocusValue, setAutoFocusValue] = useState(false);
	const [isLinkDisabled, setIsLinkDisabled] = useState("whole");
	const [isGroupDisabled, setIsGroupDisabled] = useState("month");
	const [hintData, setHintData] = useState(showHintAnimation);

	const activeGroupButton = {
		textDecorationLine: "underline",
		textDecorationStyle: "double"
	}

	const inactiveGroupButton = {
		textDecorationLine: "none",
		textDecorationStyle: "none"
	}

	const handleWholePeriodClick = () => {
		setStartDate(warStarted);
		setEndDate(lastUpdate);
		setAutoFocusValue(true);
		setIsLinkDisabled("whole");
		setIsGroupDisabled("month");
		updateDataToDate(warStarted, lastUpdate, LOSS_GROUP.MONTHLY);
	}

	const handleLastMonthPeriodClick = () => {
		const firstDayOfMonth = startOfMonth(lastUpdate);

		setStartDate(firstDayOfMonth);
		setEndDate(lastUpdate);
		setAutoFocusValue(true);
		setIsLinkDisabled("month");
		setIsGroupDisabled("day");
		updateDataToDate(firstDayOfMonth, lastUpdate, LOSS_GROUP.DAILY);
	}

	const handleLastWeekPeriodClick = () => {
		const firstDayOfWeek = isSunday(lastUpdate)
			? subDays(lastUpdate, 7)
			: startOfWeek(lastUpdate);

		setStartDate(firstDayOfWeek);
		setEndDate(lastUpdate);
		setAutoFocusValue(true);
		setIsLinkDisabled("week");
		setIsGroupDisabled("day");
		updateDataToDate(firstDayOfWeek, lastUpdate, LOSS_GROUP.DAILY);
	}

	const handleDayGroupingClick = () => {
		console.log("handleDayGroupingClick");
	}

	const handleMonthGroupingClick = () => {
		console.log("handleMonthGroupingClick");
	}

	return (
		<div className="App">
			<h1>
				<Badge bg="primary">{state.warDuration}-й</Badge> день війни
			</h1>
			<h6>
            (повномасштабного російського вторгнення)
			</h6>
			<h4>Бойові втрати противника у період</h4>
			<h5>
				з
				<span style={{marginRight: "0.2em"}}></span>
				<DatePicker
					minDate = {warStarted}
					maxDate = {subDays(lastUpdate, 0)}
					clearIcon = {null}
					autoFocus = {autoFocusValue}
					onChange={(date) => {
						setStartDate(date);
						showBadge(false);

						const days = Math.abs(differenceInCalendarDays(date, endDate));
						const period = days > 31 ? LOSS_GROUP.MONTHLY : LOSS_GROUP.DAILY;

						setIsGroupDisabled(period);
						setIsLinkDisabled("none");

						updateDataToDate(date, endDate, period);
					}}
					onCalendarOpen={() => {
						setHintData(false);
					}}
					value={startDate}
				/>
				<span style={{marginRight: "0.4em"}}></span>
				по
				<span style={{marginRight: "0.2em"}}></span>
				<DatePicker
					minDate = {addDays(warStarted, 1)}
					maxDate = {lastUpdate}
					clearIcon = {null}
					autoFocus = {autoFocusValue}
					onChange={(date) => {
						setEndDate(date);
						showBadge(false);

						const days = Math.abs(differenceInCalendarDays(date, startDate));
						const period = days > 31 ? LOSS_GROUP.MONTHLY : LOSS_GROUP.DAILY;

						setIsGroupDisabled(period);
						setIsLinkDisabled("none");

						updateDataToDate(startDate, date, period);
					}}
					onCalendarOpen={() => {
						setHintData(false);
					}}
					value={endDate}
				/>
				{state.showUpdates ? <Badge style={{left: "4px", top: "-6px", height: "18px", fontSize: "10px", position: "relative"}} pill bg="danger">new</Badge> : null}
			</h5>
			<h6>Обрати період:
				<Button variant="link" disabled={isLinkDisabled==="whole"} style={{textDecorationStyle: "dotted"}} onClick={handleWholePeriodClick}>весь час</Button>
				<Button variant="link" disabled={isLinkDisabled==="month"} style={{textDecorationStyle: "dotted"}} onClick={handleLastMonthPeriodClick}>місяць</Button>
				<Button variant="link" disabled={isLinkDisabled==="week"} style={{textDecorationStyle: "dotted"}} onClick={handleLastWeekPeriodClick} >тиждень</Button>
			</h6>
			<h6 style={{marginTop: "-15px"}}>Групувати:
				<Button variant="link" disabled={true} style={isGroupDisabled==="month" ? activeGroupButton : inactiveGroupButton} onClick={handleMonthGroupingClick}>по місяцям</Button>
				<Button variant="link" disabled={true} style={isGroupDisabled==="day" ? activeGroupButton : inactiveGroupButton} onClick={handleDayGroupingClick}>по дням</Button>
			</h6>
		</div>
	);
}

export default App;
