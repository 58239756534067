import React from "react";
import {Card, Col, ListGroup, ListGroupItem, Row} from "react-bootstrap";

export function DestroyedUAVs() {
	return(
		<div>
			<h4 className="text-center">Моделі знищених в Україні російських безпілотників</h4>

			<Row xs={1} sm={1} md={2} lg={3} xl={4} className="g-3">
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/uav/orion-10.jpg" />
						<Card.Body>
							<Card.Title>Орион (БПЛА)</Card.Title>
							<Card.Text>
                                «Оріон» — російський безпілотний літальний апарат (БПЛА) середньовисотний великої тривалості польоту (MALE, Medium Altitude, Long Endurance).
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - $80 тис. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%9E%D1%80%D1%96%D0%BE%D0%BD_(%D0%91%D0%9F%D0%9B%D0%90)">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/uav/orlan-10.jpg" />
						<Card.Body>
							<Card.Title>Орлан-10</Card.Title>
							<Card.Text>
                                «Орлан-10» — багатоцільовий безпілотний авіаційний комплекс, призначений для ведення спостереження за протяжними і локальними об'єктами у важкодоступній місцевості.
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - $65 тис. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%9E%D1%80%D0%BB%D0%B0%D0%BD-10">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/uav/kub-bla.jpg" />
						<Card.Body>
							<Card.Title>Куб-БЛА</Card.Title>
							<Card.Text>
                                Куб-БЛА — російський високоточний безпілотний літальний апарат. Розробник — група компаній ZALA AERO, що входять до концерну «Калашников».
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - невідомо. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%9A%D1%83%D0%B1-%D0%91%D0%9B%D0%90">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/uav/forpost.jpg" />
						<Card.Body>
							<Card.Title>БПЛА "Форпост"</Card.Title>
							<Card.Text>
								З 2012 року росія почала випуск БПЛА «Форпост» — ліцензійної копії ізраїльського Searcher 2, на потужностях ВАТ «Уральський завод цивільної авіації» (УЗЦА)
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Варість - $7 млн. <Card.Link href="https://uk.wikipedia.org/wiki/IAI_Searcher">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
