import React from "react";
import {Row, Alert} from "react-bootstrap";
import {LineChartVertical} from "./LineChart";
import { NAMES } from "../data/constants";

const options = {
	plugins: {
		legend: {
			display: false
		},
	}
};

class LossTable extends React.Component{
	render(){
		return(
			<div>
				<Row xs={2} sm={2} md={3} lg={4} xl={4} className="g-3">
					<LineChartVertical data={this.props.data[NAMES.TROOPS]} options={options}/>
					<LineChartVertical data={this.props.data[NAMES.BMP_BTR]} options={options}/>
					<LineChartVertical data={this.props.data[NAMES.TANKS]} options={options}/>
					<LineChartVertical data={this.props.data[NAMES.PPO]} options={options}/>
					<LineChartVertical data={this.props.data[NAMES.JETS]} options={options}/>
					<LineChartVertical data={this.props.data[NAMES.HELI]} options={options}/>
					<LineChartVertical data={this.props.data[NAMES.UAV]} options={options}/>
					<LineChartVertical data={this.props.data[NAMES.ARTA]} options={options}/>
					<LineChartVertical data={this.props.data[NAMES.SHIPS]} options={options}/>
					<LineChartVertical data={this.props.data[NAMES.AUTO]} options={options}/>
				</Row>
				<p></p>
				<Row>
					<Alert key="secondary" variant="secondary">
						<a id="average_notice">
							* - середнє значення за останні 30 днів
						</a>
					</Alert>
				</Row>
			</div>
		);
	}
}

export default LossTable;
