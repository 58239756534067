import {badges, badges_url, colors, costs, costs_specific, icons, icons_height, NAMES, TITLES} from "./constants";
import {addDays, getMonth, getYear, isFirstDayOfMonth} from "date-fns";
import {warStarted} from "./data";

export const LOSS_GROUP = {
	DAILY: "day",
	MONTHLY: "month"
}

export const MONTHS = {
	0: "Jan",
	1: "Feb",
	2: "Mar",
	3: "Apr",
	4: "May",
	5: "Jun",
	6: "Jul",
	7: "Aug",
	8: "Sep",
	9: "Oct",
	10: "Nov",
	11: "Dec"
}

export const combineData = (data, dataNew, warStarted) => {
	const values = {};
	const monthDiffs = {};
	const monthValues = {};

	Object.entries(data).forEach((entry) => {
		values[entry[0]] = [];
		Object.entries(entry[1]).forEach((pair) => {
			values[entry[0]][pair[0] - 1] = pair[1];
		});
	});

	const oneDayMs = 1000*24*60*60;
	let maxDay = 0;

	Object.entries(dataNew).forEach(([key, value]) => {
		const day = Math.ceil((new Date(key).getTime() - warStarted.getTime())/oneDayMs);
		maxDay = Math.max(maxDay, day + 1);
		Object.entries(value).forEach((pair) => {
			values[pair[0]][day] = pair[1];
		});
	});

	const days = [];
	for (let i = 0; i < maxDay; i++) {
		days.push(i+1);
	}

	const diffs = {};
	Object.entries(values).forEach(([id, dataArr]) => {
		diffs[id] = [];
		monthDiffs[id] = [];
		monthValues[id] = [];

		fixArray(dataArr, diffs[id]);
		getMonthlyLoss(days, diffs[id], values[id], monthDiffs[id], monthValues[id]);
	});

	return {
		days,
		months: Object.keys(monthValues[NAMES.ARTA]),
		values,
		diffs,
		monthValues,
		monthDiffs
	};
};

const fixArray = (arr, diffs) => {
	for (let i = 0; i < arr.length; i++) {
		if(arr[i] === undefined) {
			arr[i] = arr[i - 1];
		}

		if(i === 0) {
			diffs[i] = arr[i];
		} else {
			diffs[i] = arr[i] - arr[i-1];
		}
	}
};

const getMonthlyLoss = (days, diffs, values, monthDiffs, monthValues) => {
	let accumulator = 0;
	let savedAccumulator = 0;
	let currentValue = 0;
	let currentMonth, currentDate, currentYear;
	let update = false;

	for (let i = 0; i < days.length; i++) {
		currentDate = addDays(warStarted, days[i] - 1);

		if(isFirstDayOfMonth(currentDate)) {
			update = true;
			savedAccumulator = accumulator;
			currentMonth = getMonth(currentDate) - 1;
			currentYear = getYear(currentDate);
			currentValue = Number(values[i]);

			if(currentMonth < 0) {
				currentMonth = 11;
				currentYear -= 1;
			}

			accumulator = Number(diffs[i]);
		} else {
			accumulator += Number(diffs[i]);

			if(i === days.length - 1) {
				savedAccumulator = accumulator;
				currentMonth = getMonth(currentDate);
				currentYear = getYear(currentDate);
				currentValue = Number(values[i]);
				update = true
			} else {
				update = false;
			}
		}

		if(update) {
			const title = `${MONTHS[currentMonth]}_${currentMonth}_${currentYear}`;

			monthValues.push(currentValue);
			monthDiffs.push(savedAccumulator);
		}
	}
};

const updateMonthLabels = (arr) => {
	let year = 22;
	return arr.map((month) => {
		const newMonth = (Number(month) + 1)%12;
		if(newMonth === 0) {
			year += 1;
		}

		return `${MONTHS[newMonth]} ${year}`
	});
}

export const getChartData = (combinedData, id, period) => {
	const totalLossData = {
		type: "line",
		label: "Загалом",
		data: combinedData.values[id],
		borderColor: colors[id],
		backgroundColor: "rgba(180,179,179,0)",
		yAxisID: "total"
	};

	const totalMonthsLossData = {
		type: "line",
		label: "Загалом",
		data: combinedData.monthValues[id],
		borderColor: colors[id],
		backgroundColor: "rgba(180,179,179,0)",
		yAxisID: "total"
	};

	const dayLossData = {
		type: "bar",
		label: "За день",
		data: combinedData.diffs[id],
		borderColor: "rgb(147,147,147)",
		backgroundColor: "rgba(2,56,137,0.1)",
		borderWidth: 1,
		yAxisID: "per_day"
	};

	const monthLossData = {
		type: "bar",
		label: "За місяць",
		data: combinedData.monthDiffs[id],
		borderColor: "rgb(147,147,147)",
		backgroundColor: "rgba(7,7,7,0.2)",
		borderWidth: 2,
		yAxisID: "per_day"
	};

	return period === LOSS_GROUP.DAILY
		? {
			labels: combinedData.days,
			datasets: [
				totalLossData,
				dayLossData
			]
		}
		: {
			labels: updateMonthLabels(combinedData.months),
			datasets: [
				totalMonthsLossData,
				monthLossData
			]
		};
};

const getCost = (amount, type) => {
	let specificAmount = 0, specificCost = 0;
	if(costs_specific[type]) {
		specificAmount = costs_specific[type].amount;
		specificCost = costs_specific[type].cost;
	}

	let main = (amount - specificAmount) * (costs[type] || 0);

	return main + specificCost;
};

const last = (arr) => {
	return arr[arr.length - 1];
};

const notchSizes = {
	1: 0.9,
	2: 0.9,
	3: 0.9
}

const notchConfig = {};
notchConfig[NAMES.TROOPS] = {
	step: 5000,
	icon: 3,
	icon_size: notchSizes[3]
};
notchConfig[NAMES.BMP_BTR] = {
	step: 1000,
	icon: 2,
	icon_size: notchSizes[2]
};
notchConfig[NAMES.JETS] = {
	step: 100,
	icon: 1,
	icon_size: notchSizes[1]
};
notchConfig[NAMES.HELI] = {
	step: 100,
	icon: 1,
	icon_size: notchSizes[1]
};
notchConfig[NAMES.UAV] = {
	step: 100,
	icon: 1,
	icon_size: notchSizes[1]
};
notchConfig[NAMES.ARTA] = {
	step: 100,
	icon: 1,
	icon_size: notchSizes[2]
};
notchConfig[NAMES.PPO] = {
	step: 100,
	icon: 3,
	icon_size: notchSizes[2]
};
notchConfig[NAMES.SHIPS] = {
	step: 10,
	icon: 1,
	icon_size: notchSizes[1]
};
notchConfig[NAMES.TANKS] = {
	step: 1000,
	icon: 2,
	icon_size: notchSizes[2]
};
notchConfig[NAMES.AUTO] = {
	step: 1000,
	icon: 2,
	icon_size: notchSizes[2]
};


const getNotch = (type, current) => {
	const config = notchConfig[type];

	if(!config) {
		return null;
	}

	const count = Math.floor(current / config.step) + 1;

	return {
		count,
		icon: `/img/badge/${config.icon}level.png`,
		icon_inactive: `/img/badge/${config.icon}level-bw.png`,
		size: config.icon_size
	};
};

export const prepareOneTypeData = (type, combinedData, prevValues, period = LOSS_GROUP.DAILY) => {
	const current = last(combinedData.values[type]);
	const first = prevValues[type];

	const chartData = getChartData(combinedData, type, period);

	let dayLoss;
	const values = combinedData.values[type];
	if(values.length > 1) {
		dayLoss = current - values[values.length - 2];
	} else {
		dayLoss = current - first;
	}

	const total = current - first;

	///// average
	let average;

	if(values.length > 1) {
		const daysToAverage = 30;
		const lastDaysData = values.slice(Math.max(values.length - daysToAverage - 1, 0));
		const last30sum = lastDaysData.reduce((acc, el, index, arr) => {
			if(index > 0) {
				const diff = arr[index] - arr[index-1]
				return acc + diff;
			}
			return acc}, 0);
		average = last30sum/(lastDaysData.length - 1);
		if(average > 1) {
			average = Math.round(average);
		} else {
			average = average.toFixed(1);
		}
	} else {
		average = null;
	}
	// end of average
	/////////////////

	const result = {
		type,
		period,
		name: TITLES[type],
		value: total,
		cost: getCost(total, type),
		day_loss: dayLoss,
		average: average,
		chartData,
		icon: "/img/" + icons[type],
		icon_height: icons_height[type] || "1.5em"
	};

	if(badges[type]) {
		result.badge = badges[type];
		result.badge_url = badges_url[type];
	}

	result.notch = getNotch(type, current);

	return result;
};
