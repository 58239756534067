import React from "react";
import {Nav, Navbar} from "react-bootstrap";

export function Navigation({path}) {
	return(
		<Navbar variant="light" bg="light">
			<Navbar.Brand href="#"></Navbar.Brand>
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav activeKey={path} className="m-auto">
					<Nav.Link href="/general">Загальні</Nav.Link>
					<Nav.Link href="/finance">Фінансові</Nav.Link>
					<Nav.Link href="/relative">Відносно накопичених на кордоні ресурсів</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}
