import {NAMES} from "./constants";
import {lossDataNewSet1} from "./dataSource1";
import {lossDataNewSet2} from "./dataSource2";

export const warStarted = new Date("February 24, 2022 05:00:00");

export const lossData = {};
export const borderNetWorth = {};
export const fullNetWorth = {};
export const destroyUpdate = {};

borderNetWorth[NAMES.TROOPS] = 140000;
borderNetWorth[NAMES.TANKS] = 1200;
borderNetWorth[NAMES.BMP_BTR] = 2900;
borderNetWorth[NAMES.ARTA] = 1600;
borderNetWorth[NAMES.JETS] = 330;
borderNetWorth[NAMES.HELI] = 240;
borderNetWorth[NAMES.SHIPS] = 75;


fullNetWorth[NAMES.TROOPS] = 900000;
fullNetWorth[NAMES.TANKS] = 3300;
fullNetWorth[NAMES.BMP_BTR] = 13758;
fullNetWorth[NAMES.ARTA] = 5689;
fullNetWorth[NAMES.JETS] = 1379;
fullNetWorth[NAMES.HELI] = 961;
fullNetWorth[NAMES.SHIPS] = 519;

destroyUpdate[NAMES.UAV] = 4;
destroyUpdate[NAMES.JETS] = 4;
destroyUpdate[NAMES.HELI] = 3;
destroyUpdate[NAMES.SHIPS] = 5;

lossData[NAMES.PPO] = {
	1: 0,
	3: 5,
	5: 10,
	7: 15,
	10: 20,
	13: 27,
	14: 28,
	15: 29,
	16: 31,
	17: 33,
	18: 34,
	19: 34,
	20: 36,
	21: 43,
	23: 43,
	25: 44,
	26: 45,
	28: 45,
	29: 47,
	30: 49,
	32: 52,
	33: 54,
	40: 54, //04.04
	47: 55,
	48: 63, //12.04
	50: 64,
	52: 66,
	54: 67 //18.04
}

lossData[NAMES.TROOPS] = {
	1: 1000,
	2: 2000,
	3: 3000,
	4: 4000,
	5: 5000,
	6: 6000,
	7: 7000,
	8: 8000,
	9: 9166,
	10: 10000,
	11: 11000,
	12: 12000,
	14: 12000,
	15: 12000,
	20: 13500,
	21: 13800,
	22: 14000,
	23: 14200,
	24: 14400,
	25: 14700,
	26: 15000,
	27: 15300,
	28: 15600,
	30: 16100,
	31: 16400,
	32: 16600,
	33: 17000,
	34: 17200,
	35: 17300,
	36: 17500,
	37: 17700,
	38: 17800,
	39: 18000,
	40: 18300,
	41: 18500,
	42: 18600,
	44: 18900,
	45: 19100,
	46: 19300,
	47: 19500,
	48: 19600,
	49: 19800,
	50: 19900,
	51: 20000,
	52: 20100,
	53: 20300,
	54: 20600
};

lossData[NAMES.JETS] = {
	1: 3,
	2: 13,
	3: 27,
	4: 29,
	9: 33,
	10: 44,
	12: 48,
	14: 49,
	15: 49,
	16: 57,
	18: 62,
	19: 77,
	20: 81,
	21: 84,
	22: 86,
	23: 93,
	24: 95,
	25: 96,
	26: 97,
	27: 99,
	28: 101,
	30: 115,
	31: 117,
	32: 121,
	33: 123,
	34: 127,
	35: 131,
	36: 135,
	37: 143,
	38: 143,
	39: 143,
	40: 147,
	41: 150,
	42: 150,
	44: 150,
	45: 151,
	46: 152,
	47: 154,
	48: 157,
	49: 158,
	50: 160,
	51: 163,
	52: 163,
	53: 165,
	54: 167
};

lossData[NAMES.HELI] = {
	1: 2,
	2: 8,
	3: 26,
	4: 29,
	9: 37,
	10: 48,
	12: 80,
	14: 81,
	15: 81,
	16: 83,
	17: 86,
	19: 90,
	20: 95,
	21: 108,
	22: 108,
	23: 112,
	24: 115,
	25: 118,
	26: 121,
	27: 123,
	28: 124,
	30: 125,
	31: 127,
	32: 127,
	33: 127,
	34: 129,
	35: 131,
	36: 131,
	37: 131,
	38: 134,
	39: 134,
	40: 134,
	41: 134,
	42: 135,
	44: 135,
	45: 136,
	46: 137,
	47: 137,
	48: 140,
	49: 143,
	50: 144,
	51: 144,
	52: 145,
	53: 146,
	54: 147
};

lossData[NAMES.UAV] = {
	1: 0,
	18: 7,
	20: 9,
	21: 11,
	22: 11,
	23: 12,
	24: 17,
	25: 21,
	26: 24,
	27: 35,
	28: 42,
	30: 53,
	32: 56,
	33: 66,
	34: 71,
	35: 81,
	36: 83,
	37: 85,
	38: 87,
	39: 89,
	40: 92,
	42: 96,
	43: 111,
	46: 112,
	47: 119,
	48: 124,
	49: 132,
	50: 134,
	51: 135,
	52: 138,
	53: 148,
	54: 155
};

lossData[NAMES.TANKS] = {
	1: 2,
	2: 102,
	3: 146,
	4: 191,
	9: 251,
	10: 285,
	11: 290,
	12: 303,
	14: 317,
	15: 335,
	16: 353,
	18: 374,
	19: 389,
	20: 404,
	21: 430,
	22: 444,
	23: 450,
	24: 466,
	25: 476,
	26: 498,
	27: 509,
	28: 517,
	30: 561,
	31: 575,
	32: 582,
	33: 586,
	34: 597,
	35: 605,
	36: 614,
	37: 625,
	39: 644,
	40: 647,
	41: 676,
	42: 684,
	44: 698,
	45: 705,
	46: 722,
	47: 725,
	48: 732,
	49: 739,
	50: 753,
	51: 756,
	52: 762,
	53: 773,
	54: 790
};

lossData[NAMES.BMP_BTR] = {
	1: 200,
	2: 536,
	3: 706,
	4: 816,
	9: 939,
	10: 985,
	11: 999,
	12: 1036,
	14: 1070,
	15: 1105,
	16: 1165,
	18: 1226,
	19: 1249,
	20: 1279,
	21: 1375,
	23: 1448,
	24: 1470,
	25: 1487,
	26: 1535,
	27: 1556,
	28: 1578,
	30: 1625,
	31: 1640,
	32: 1664,
	33: 1694,
	34: 1710,
	35: 1723,
	36: 1735,
	37: 1751,
	38: 1776,
	39: 1830,
	40: 1844,
	41: 1858,
	42: 1861,
	44: 1891,
	45: 1895,
	46: 1911,
	47: 1923,
	48: 1946,
	49: 1964,
	50: 1968,
	51: 1976,
	52: 1982,
	53: 2002,
	54: 2041
};

lossData[NAMES.ARTA] = {
	1: 0,
	2: 15,
	3: 49,
	4: 74,
	9: 105,
	10: 109,
	11: 117,
	12: 120,
	14: 120,
	15: 123,
	16: 125,
	18: 140,
	19: 150,
	20: 150,
	21: 190,
	22: 201,
	23: 205,
	24: 213,
	25: 230,
	26: 240,
	27: 252,
	28: 267,
	30: 291,
	31: 293,
	32: 294,
	33: 302,
	34: 303,
	35: 305,
	36: 311,
	37: 316,
	38: 317,
	39: 325,
	40: 330,
	41: 332,
	42: 332,
	44: 332,
	45: 335,
	46: 342,
	47: 347,
	48: 349,
	49: 358,
	50: 366,
	51: 366,
	52: 371,
	53: 376,
	54: 381
};

lossData[NAMES.SHIPS] = {
	1: 0,
	3: 2,
	10: 2,
	11: 3,
	12: 3,
	15: 3,
	28: 4,
	30: 5,
	31: 7,
	36: 7,
	51: 8
};

lossData[NAMES.AUTO] = {
	1: 0,
	3: 30,
	4: 291,
	9: 404,
	12: 474,
	14: 482,
	15: 526,
	16: 558,
	18: 600,
	19: 617,
	20: 640,
	21: 819,
	22: 864,
	23: 879,
	24: 914,
	25: 947,
	26: 969,
	27: 1000,
	28: 1008,
	30: 1089,
	31: 1131,
	32: 1144,
	33: 1150,
	34: 1178,
	35: 1184,
	36: 1201,
	37: 1220,
	38: 1236,
	39: 1249,
	40: 1273,
	41: 1322,
	42: 1324,
	44: 1358,
	45: 1363,
	46: 1384,
	47: 1387,
	48: 1406,
	49: 1429,
	50: 1437,
	51: 1443,
	52: 1458,
	53: 1471,
	54: 1487
};

export const lossDataNew = {
	...lossDataNewSet1,
	...lossDataNewSet2
};
