import React from "react";
import {Card, Col, ListGroup, ListGroupItem, Row} from "react-bootstrap";

export function DestroyedHeli() {
	return(
		<div>
			<h4 className="text-center">Моделі знищених в Україні російських гелікоптерів</h4>

			<Row xs={1} sm={1} md={2} lg={3} xl={4} className="g-3">
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/heli/ka-52.jpg" />
						<Card.Body>
							<Card.Title>КА-52</Card.Title>
							<Card.Text>
                                Ка-52 (Hokum B за класифікацією НАТО, також відомий як «Алігатор») — російський бойовий вертоліт, командирська машина армійської авіації, здійснює розвідку місцевості, цілевказівки і координацію дій групи бойових вертольотів.
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Вартість - $16.1 млн. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%9A%D0%B0-52">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/heli/mi-28.jpg" />
						<Card.Body>
							<Card.Title>Мі-28</Card.Title>
							<Card.Text>
                                Мі-28 (за кодифікацією НАТО: Havoc, буквально — «спустошувач») — російський ударний вертоліт.
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Вартість - $18 млн. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%9C%D1%96-28">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
				<Col>
					<Card border="secondary">
						<Card.Img variant="top" src="/img/heli/mi-8.jpg" />
						<Card.Body>
							<Card.Title>Мі-8</Card.Title>
							<Card.Text>
                                Мі-8 (В-8, виріб «80», по кодифікації НАТО: Hip) — радянський багатоцільовий вертоліт. Наймасовіший вертоліт з двома двигунами у світі, один з наймасовіших вертольотів в історії авіації.
							</Card.Text>
						</Card.Body>
						<ListGroup className="list-group-flush">
							<ListGroupItem>Вартість - $3 млн. <Card.Link href="https://uk.wikipedia.org/wiki/%D0%9C%D1%96-8">Wiki</Card.Link></ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</div>

	);
}
