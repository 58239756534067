import {NAMES} from "./constants";

export const lossDataNewSet1 = {
    "Tue Apr 19 2022": {
        [NAMES.TROOPS]: 20800,
        [NAMES.JETS]: 169,
        [NAMES.HELI]: 150,
        [NAMES.UAV]: 158,
        [NAMES.TANKS]: 802,
        [NAMES.BMP_BTR]: 2063,
        [NAMES.ARTA]: 386,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1495
    },
    "Wed Apr 20 2022": {
        [NAMES.TROOPS]: 20900,
        [NAMES.JETS]: 171,
        [NAMES.HELI]: 150,
        [NAMES.UAV]: 165,
        [NAMES.TANKS]: 815,
        [NAMES.BMP_BTR]: 2087,
        [NAMES.ARTA]: 391,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1504
    },
    "Thu Apr 21 2022": {
        [NAMES.TROOPS]: 21000,
        [NAMES.JETS]: 172,
        [NAMES.HELI]: 151,
        [NAMES.UAV]: 166,
        [NAMES.TANKS]: 829,
        [NAMES.BMP_BTR]: 2118,
        [NAMES.ARTA]: 393,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1508
    },
    "Fri Apr 22 2022": {
        [NAMES.TROOPS]: 21200,
        [NAMES.JETS]: 176,
        [NAMES.HELI]: 153,
        [NAMES.UAV]: 172,
        [NAMES.TANKS]: 838,
        [NAMES.BMP_BTR]: 2162,
        [NAMES.ARTA]: 397,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1523
    },
    "Sat Apr 23 2022": {
        [NAMES.TROOPS]: 21600,
        [NAMES.JETS]: 177,
        [NAMES.HELI]: 154,
        [NAMES.UAV]: 182,
        [NAMES.TANKS]: 854,
        [NAMES.BMP_BTR]: 2205,
        [NAMES.ARTA]: 403,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1543
    },
    "Sun Apr 24 2022": {
        [NAMES.TROOPS]: 21800,
        [NAMES.JETS]: 179,
        [NAMES.HELI]: 154,
        [NAMES.UAV]: 191,
        [NAMES.TANKS]: 873,
        [NAMES.BMP_BTR]: 2238,
        [NAMES.ARTA]: 408,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1557
    },
    "Mon Apr 25 2022": {
        [NAMES.TROOPS]: 21900,
        [NAMES.JETS]: 181,
        [NAMES.HELI]: 154,
        [NAMES.UAV]: 201,
        [NAMES.TANKS]: 884,
        [NAMES.BMP_BTR]: 2258,
        [NAMES.ARTA]: 411,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1566
    },
    "Tue Apr 26 2022": {
        [NAMES.TROOPS]: 22100,
        [NAMES.JETS]: 184,
        [NAMES.HELI]: 154,
        [NAMES.UAV]: 205,
        [NAMES.TANKS]: 918,
        [NAMES.BMP_BTR]: 2308,
        [NAMES.ARTA]: 416,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1643,
        [NAMES.PPO]: 69
    },
    "Wed Apr 27 2022": {
        [NAMES.TROOPS]: 22400,
        [NAMES.JETS]: 185,
        [NAMES.HELI]: 155,
        [NAMES.UAV]: 207,
        [NAMES.TANKS]: 939,
        [NAMES.BMP_BTR]: 2342,
        [NAMES.ARTA]: 421,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1666
    },
    "Thu Apr 28 2022": {
        [NAMES.TROOPS]: 22800,
        [NAMES.JETS]: 187,
        [NAMES.HELI]: 155,
        [NAMES.UAV]: 215,
        [NAMES.TANKS]: 970,
        [NAMES.BMP_BTR]: 2389,
        [NAMES.ARTA]: 431,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1688,
        [NAMES.PPO]: 72
    },
    "Fri Apr 29 2022": {
        [NAMES.TROOPS]: 23000,
        [NAMES.JETS]: 189,
        [NAMES.HELI]: 155,
        [NAMES.UAV]: 229,
        [NAMES.TANKS]: 986,
        [NAMES.BMP_BTR]: 2418,
        [NAMES.ARTA]: 435,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1695
    },
    "Sat Apr 30 2022": {
        [NAMES.TROOPS]: 23200,
        [NAMES.JETS]: 190,
        [NAMES.HELI]: 155,
        [NAMES.UAV]: 232,
        [NAMES.TANKS]: 1008,
        [NAMES.BMP_BTR]: 2445,
        [NAMES.ARTA]: 436,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1701,
        [NAMES.PPO]: 77
    },
    "Sun May 01 2022": {
        [NAMES.TROOPS]: 23500,
        [NAMES.JETS]: 192,
        [NAMES.HELI]: 155,
        [NAMES.UAV]: 245,
        [NAMES.TANKS]: 1026,
        [NAMES.BMP_BTR]: 2471,
        [NAMES.ARTA]: 451,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1796,
        [NAMES.PPO]: 80
    },
    "Mon May 02 2022": {
        [NAMES.TROOPS]: 23800,
        [NAMES.JETS]: 194,
        [NAMES.HELI]: 155,
        [NAMES.UAV]: 271,
        [NAMES.TANKS]: 1048,
        [NAMES.BMP_BTR]: 2519,
        [NAMES.ARTA]: 459,
        [NAMES.SHIPS]: 8,
        [NAMES.AUTO]: 1824
    },
    "Tue May 03 2022": {
        [NAMES.TROOPS]: 24200,
        [NAMES.JETS]: 194,
        [NAMES.HELI]: 155,
        [NAMES.UAV]: 291,
        [NAMES.TANKS]: 1062,
        [NAMES.BMP_BTR]: 2567,
        [NAMES.ARTA]: 475,
        [NAMES.SHIPS]: 10,
        [NAMES.AUTO]: 1843,
        [NAMES.PPO]: 80
    },
    "Wed May 04 2022": {
        [NAMES.TROOPS]: 24500,
        [NAMES.JETS]: 194,
        [NAMES.HELI]: 155,
        [NAMES.UAV]: 303,
        [NAMES.TANKS]: 1077,
        [NAMES.BMP_BTR]: 2610,
        [NAMES.ARTA]: 491,
        [NAMES.SHIPS]: 10,
        [NAMES.AUTO]: 1867,
        [NAMES.PPO]: 81
    },
    "Thu May 05 2022": {
        [NAMES.TROOPS]: 24700,
        [NAMES.JETS]: 196,
        [NAMES.HELI]: 155,
        [NAMES.UAV]: 312,
        [NAMES.TANKS]: 1092,
        [NAMES.BMP_BTR]: 2651,
        [NAMES.ARTA]: 499,
        [NAMES.SHIPS]: 10,
        [NAMES.AUTO]: 1907,
        [NAMES.PPO]: 83
    },
    "Fri May 06 2022": {
        [NAMES.TROOPS]: 24900,
        [NAMES.JETS]: 199,
        [NAMES.HELI]: 155,
        [NAMES.UAV]: 324,
        [NAMES.TANKS]: 1110,
        [NAMES.BMP_BTR]: 2686,
        [NAMES.ARTA]: 502,
        [NAMES.SHIPS]: 11,
        [NAMES.AUTO]: 1926,
        [NAMES.PPO]: 83
    },
    "Sat May 07 2022": {
        [NAMES.TROOPS]: 25100,
        [NAMES.JETS]: 199,
        [NAMES.HELI]: 155,
        [NAMES.UAV]: 341,
        [NAMES.TANKS]: 1122,
        [NAMES.BMP_BTR]: 2713,
        [NAMES.ARTA]: 509,
        [NAMES.SHIPS]: 11,
        [NAMES.AUTO]: 1934,
        [NAMES.PPO]: 84
    },
    "Sun May 08 2022": {
        [NAMES.TROOPS]: 25500,
        [NAMES.JETS]: 199,
        [NAMES.HELI]: 156,
        [NAMES.UAV]: 360,
        [NAMES.TANKS]: 1130,
        [NAMES.BMP_BTR]: 2741,
        [NAMES.ARTA]: 509,
        [NAMES.SHIPS]: 12,
        [NAMES.AUTO]: 1961,
        [NAMES.PPO]: 86
    },
    "Mon May 09 2022": {
        [NAMES.TROOPS]: 25650,
        [NAMES.JETS]: 199,
        [NAMES.HELI]: 158,
        [NAMES.UAV]: 377,
        [NAMES.TANKS]: 1145,
        [NAMES.BMP_BTR]: 2764,
        [NAMES.ARTA]: 513,
        [NAMES.SHIPS]: 12,
        [NAMES.AUTO]: 1970,
        [NAMES.PPO]: 87
    },
    "Tue May 10 2022": {
        [NAMES.TROOPS]: 26000,
        [NAMES.JETS]: 199,
        [NAMES.HELI]: 158,
        [NAMES.UAV]: 380,
        [NAMES.TANKS]: 1170,
        [NAMES.BMP_BTR]: 2808,
        [NAMES.ARTA]: 519,
        [NAMES.SHIPS]: 12,
        [NAMES.AUTO]: 1980
    },
    "Wed May 11 2022": {
        [NAMES.TROOPS]: 26350,
        [NAMES.JETS]: 199,
        [NAMES.HELI]: 160,
        [NAMES.UAV]: 390,
        [NAMES.TANKS]: 1187,
        [NAMES.BMP_BTR]: 2856,
        [NAMES.ARTA]: 528,
        [NAMES.SHIPS]: 12,
        [NAMES.AUTO]: 1997
    },
    "Thu May 12 2022": {
        [NAMES.TROOPS]: 26650,
        [NAMES.JETS]: 199,
        [NAMES.HELI]: 161,
        [NAMES.UAV]: 398,
        [NAMES.TANKS]: 1195,
        [NAMES.BMP_BTR]: 2873,
        [NAMES.ARTA]: 534,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2019,
        [NAMES.PPO]: 87
    },
    "Fri May 13 2022": {
        [NAMES.TROOPS]: 26900,
        [NAMES.JETS]: 200,
        [NAMES.HELI]: 162,
        [NAMES.UAV]: 405,
        [NAMES.TANKS]: 1205,
        [NAMES.BMP_BTR]: 2900,
        [NAMES.ARTA]: 542,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2042,
        [NAMES.PPO]: 88
    },
    "Sat May 14 2022": {
        [NAMES.TROOPS]: 27200,
        [NAMES.JETS]: 200,
        [NAMES.HELI]: 163,
        [NAMES.UAV]: 411,
        [NAMES.TANKS]: 1218,
        [NAMES.BMP_BTR]: 2934,
        [NAMES.ARTA]: 551,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2059,
        [NAMES.PPO]: 88
    },
    "Sun May 15 2022": {
        [NAMES.TROOPS]: 27400,
        [NAMES.JETS]: 200,
        [NAMES.HELI]: 164,
        [NAMES.UAV]: 416,
        [NAMES.TANKS]: 1220,
        [NAMES.BMP_BTR]: 2958,
        [NAMES.ARTA]: 555,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2087,
        [NAMES.PPO]: 89
    },
    "Mon May 16 2022": {
        [NAMES.TROOPS]: 27700,
        [NAMES.JETS]: 200,
        [NAMES.HELI]: 165,
        [NAMES.UAV]: 427,
        [NAMES.TANKS]: 1228,
        [NAMES.BMP_BTR]: 2974,
        [NAMES.ARTA]: 577,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2101,
        [NAMES.PPO]: 89
    },
    "Tue May 17 2022": {
        [NAMES.TROOPS]: 27900,
        [NAMES.JETS]: 201,
        [NAMES.HELI]: 167,
        [NAMES.UAV]: 436,
        [NAMES.TANKS]: 1235,
        [NAMES.BMP_BTR]: 3009,
        [NAMES.ARTA]: 578,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2109,
        [NAMES.PPO]: 90
    },
    "Wed May 18 2022": {
        [NAMES.TROOPS]: 28300,
        [NAMES.JETS]: 202,
        [NAMES.HELI]: 167,
        [NAMES.UAV]: 441,
        [NAMES.TANKS]: 1251,
        [NAMES.BMP_BTR]: 3043,
        [NAMES.ARTA]: 586,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2137,
        [NAMES.PPO]: 91
    },
    "Thu May 19 2022": {
        [NAMES.TROOPS]: 28500,
        [NAMES.JETS]: 203,
        [NAMES.HELI]: 167,
        [NAMES.UAV]: 455,
        [NAMES.TANKS]: 1254,
        [NAMES.BMP_BTR]: 3063,
        [NAMES.ARTA]: 595,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2157,
        [NAMES.PPO]: 93
    },
    "Fri May 20 2022": {
        [NAMES.TROOPS]: 28700,
        [NAMES.JETS]: 203,
        [NAMES.HELI]: 168,
        [NAMES.UAV]: 460,
        [NAMES.TANKS]: 1263,
        [NAMES.BMP_BTR]: 3090,
        [NAMES.ARTA]: 596,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2162
    },
    "Sat May 21 2022": {
        [NAMES.TROOPS]: 28850,
        [NAMES.JETS]: 204,
        [NAMES.HELI]: 169,
        [NAMES.UAV]: 462,
        [NAMES.TANKS]: 1278,
        [NAMES.BMP_BTR]: 3116,
        [NAMES.ARTA]: 596,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2178
    },
    "Sun May 22 2022": {
        [NAMES.TROOPS]: 29050,
        [NAMES.JETS]: 204,
        [NAMES.HELI]: 170,
        [NAMES.UAV]: 470,
        [NAMES.TANKS]: 1285,
        [NAMES.BMP_BTR]: 3141,
        [NAMES.ARTA]: 599,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2194
    },
    "Mon May 23 2022": {
        [NAMES.TROOPS]: 29200,
        [NAMES.JETS]: 204,
        [NAMES.HELI]: 170,
        [NAMES.UAV]: 476,
        [NAMES.TANKS]: 1293,
        [NAMES.BMP_BTR]: 3166,
        [NAMES.ARTA]: 604,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2206
    },
    "Tue May 24 2022": {
        [NAMES.TROOPS]: 29350,
        [NAMES.JETS]: 205,
        [NAMES.HELI]: 170,
        [NAMES.UAV]: 480,
        [NAMES.TANKS]: 1302,
        [NAMES.BMP_BTR]: 3194,
        [NAMES.ARTA]: 606,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2213
    },
    "Wed May 25 2022": {
        [NAMES.TROOPS]: 29450,
        [NAMES.JETS]: 206,
        [NAMES.HELI]: 170,
        [NAMES.UAV]: 491,
        [NAMES.TANKS]: 1305,
        [NAMES.BMP_BTR]: 3213,
        [NAMES.ARTA]: 606,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2218
    },
    "Thu May 26 2022": {
        [NAMES.TROOPS]: 29600,
        [NAMES.JETS]: 206,
        [NAMES.HELI]: 170,
        [NAMES.UAV]: 502,
        [NAMES.TANKS]: 1315,
        [NAMES.BMP_BTR]: 3235,
        [NAMES.ARTA]: 617,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2225
    },
    "Fri May 27 2022": {
        [NAMES.TROOPS]: 29750,
        [NAMES.JETS]: 206,
        [NAMES.HELI]: 170,
        [NAMES.UAV]: 503,
        [NAMES.TANKS]: 1322,
        [NAMES.BMP_BTR]: 3246,
        [NAMES.ARTA]: 623,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2226
    },
    "Sat May 28 2022": {
        [NAMES.TROOPS]: 30000,
        [NAMES.JETS]: 207,
        [NAMES.HELI]: 174,
        [NAMES.UAV]: 503,
        [NAMES.TANKS]: 1330,
        [NAMES.BMP_BTR]: 3258,
        [NAMES.ARTA]: 628,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2226
    },
    "Sun May 29 2022": {
        [NAMES.TROOPS]: 30150,
        [NAMES.JETS]: 207,
        [NAMES.HELI]: 174,
        [NAMES.UAV]: 503,
        [NAMES.TANKS]: 1338,
        [NAMES.BMP_BTR]: 3270,
        [NAMES.ARTA]: 631,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2240
    },
    "Mon May 30 2022": {
        [NAMES.TROOPS]: 30350,
        [NAMES.JETS]: 207,
        [NAMES.HELI]: 174,
        [NAMES.UAV]: 507,
        [NAMES.TANKS]: 1349,
        [NAMES.BMP_BTR]: 3282,
        [NAMES.ARTA]: 643,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2258
    },
    "Tue May 31 2022": {
        [NAMES.TROOPS]: 30500,
        [NAMES.JETS]: 208,
        [NAMES.HELI]: 174,
        [NAMES.UAV]: 515,
        [NAMES.TANKS]: 1358,
        [NAMES.BMP_BTR]: 3302,
        [NAMES.ARTA]: 649,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2275,
        [NAMES.PPO]: 93
    },
    "Wed Jun 01 2022": {
        [NAMES.TROOPS]: 30700,
        [NAMES.JETS]: 208,
        [NAMES.HELI]: 175,
        [NAMES.UAV]: 519,
        [NAMES.TANKS]: 1361,
        [NAMES.BMP_BTR]: 3343,
        [NAMES.ARTA]: 659,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2290,
        [NAMES.PPO]: 94
    },
    "Thu Jun 02 2022": {
        [NAMES.TROOPS]: 30850,
        [NAMES.JETS]: 210,
        [NAMES.HELI]: 175,
        [NAMES.UAV]: 521,
        [NAMES.TANKS]: 1363,
        [NAMES.BMP_BTR]: 3354,
        [NAMES.ARTA]: 661,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2325,
        [NAMES.PPO]: 95
    },
    "Fri Jun 03 2022": {
        [NAMES.TROOPS]: 30950,
        [NAMES.JETS]: 210,
        [NAMES.HELI]: 175,
        [NAMES.UAV]: 535,
        [NAMES.TANKS]: 1367,
        [NAMES.BMP_BTR]: 3366,
        [NAMES.ARTA]: 675,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2329
    },
    "Sat Jun 04 2022": {
        [NAMES.TROOPS]: 31050,
        [NAMES.JETS]: 210,
        [NAMES.HELI]: 175,
        [NAMES.UAV]: 540,
        [NAMES.TANKS]: 1376,
        [NAMES.BMP_BTR]: 3379,
        [NAMES.ARTA]: 680,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2337
    },
    "Sun Jun 05 2022": {
        [NAMES.TROOPS]: 31150,
        [NAMES.JETS]: 210,
        [NAMES.HELI]: 175,
        [NAMES.UAV]: 548,
        [NAMES.TANKS]: 1381,
        [NAMES.BMP_BTR]: 3392,
        [NAMES.ARTA]: 686,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2360,
        [NAMES.PPO]: 95
    },
    "Mon Jun 06 2022": {
        [NAMES.TROOPS]: 31250,
        [NAMES.JETS]: 211,
        [NAMES.HELI]: 176,
        [NAMES.UAV]: 551,
        [NAMES.TANKS]: 1386,
        [NAMES.BMP_BTR]: 3400,
        [NAMES.ARTA]: 690,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2395,
        [NAMES.PPO]: 96
    },
    "Tue Jun 07 2022": {
        [NAMES.TROOPS]: 31360,
        [NAMES.JETS]: 212,
        [NAMES.HELI]: 177,
        [NAMES.UAV]: 553,
        [NAMES.TANKS]: 1390,
        [NAMES.BMP_BTR]: 3416,
        [NAMES.ARTA]: 694,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2405
    },
    "Wed Jun 08 2022": {
        [NAMES.TROOPS]: 31500,
        [NAMES.JETS]: 212,
        [NAMES.HELI]: 178,
        [NAMES.UAV]: 559,
        [NAMES.TANKS]: 1393,
        [NAMES.BMP_BTR]: 3429,
        [NAMES.ARTA]: 703,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2406
    },
    "Thu Jun 09 2022": {
        [NAMES.TROOPS]: 31700,
        [NAMES.JETS]: 212,
        [NAMES.HELI]: 178,
        [NAMES.UAV]: 562,
        [NAMES.TANKS]: 1398,
        [NAMES.BMP_BTR]: 3438,
        [NAMES.ARTA]: 711,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2421,
        [NAMES.PPO]: 96
    },
    "Fri Jun 10 2022": {
        [NAMES.TROOPS]: 31900,
        [NAMES.JETS]: 212,
        [NAMES.HELI]: 178,
        [NAMES.UAV]: 572,
        [NAMES.TANKS]: 1409,
        [NAMES.BMP_BTR]: 3450,
        [NAMES.ARTA]: 712,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2438,
        [NAMES.PPO]: 97
    },
    "Sat Jun 11 2022": {
        [NAMES.TROOPS]: 32050,
        [NAMES.JETS]: 212,
        [NAMES.HELI]: 178,
        [NAMES.UAV]: 579,
        [NAMES.TANKS]: 1419,
        [NAMES.BMP_BTR]: 3466,
        [NAMES.ARTA]: 712,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2448
    },
    "Jun 12 2022": {
        [NAMES.TROOPS]: 32150,
        [NAMES.JETS]: 212,
        [NAMES.HELI]: 178,
        [NAMES.UAV]: 582,
        [NAMES.TANKS]: 1430,
        [NAMES.BMP_BTR]: 3484,
        [NAMES.ARTA]: 715,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2455
    },
    "Jun 13 2022": {
        [NAMES.TROOPS]: 32300,
        [NAMES.JETS]: 213,
        [NAMES.HELI]: 178,
        [NAMES.UAV]: 585,
        [NAMES.TANKS]: 1432,
        [NAMES.BMP_BTR]: 3492,
        [NAMES.ARTA]: 718,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2460
    },
    "Jun 14 2022": {
        [NAMES.TROOPS]: 32500,
        [NAMES.JETS]: 213,
        [NAMES.HELI]: 179,
        [NAMES.UAV]: 588,
        [NAMES.TANKS]: 1434,
        [NAMES.BMP_BTR]: 3503,
        [NAMES.ARTA]: 721,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2473
    },
    "Jun 15 2022": {
        [NAMES.TROOPS]: 32750,
        [NAMES.JETS]: 213,
        [NAMES.HELI]: 179,
        [NAMES.UAV]: 591,
        [NAMES.TANKS]: 1440,
        [NAMES.BMP_BTR]: 3528,
        [NAMES.ARTA]: 722,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2485
    },
    "Jun 16 2022": {
        [NAMES.TROOPS]: 32950,
        [NAMES.JETS]: 213,
        [NAMES.HELI]: 179,
        [NAMES.UAV]: 591,
        [NAMES.TANKS]: 1449,
        [NAMES.BMP_BTR]: 3545,
        [NAMES.ARTA]: 729,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2494
    },
    "Jun 17 2022": {
        [NAMES.TROOPS]: 33150,
        [NAMES.JETS]: 215,
        [NAMES.HELI]: 180,
        [NAMES.UAV]: 593,
        [NAMES.TANKS]: 1456,
        [NAMES.BMP_BTR]: 3563,
        [NAMES.ARTA]: 734,
        [NAMES.SHIPS]: 13,
        [NAMES.AUTO]: 2496,
        [NAMES.PPO]: 97
    },
    "Jun 18 2022": {
        [NAMES.TROOPS]: 33350,
        [NAMES.JETS]: 216,
        [NAMES.HELI]: 180,
        [NAMES.UAV]: 594,
        [NAMES.TANKS]: 1465,
        [NAMES.BMP_BTR]: 3573,
        [NAMES.ARTA]: 739,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2513,
        [NAMES.PPO]: 98
    },
    "Jun 19 2022": {
        [NAMES.TROOPS]: 33600,
        [NAMES.JETS]: 216,
        [NAMES.HELI]: 181,
        [NAMES.UAV]: 598,
        [NAMES.TANKS]: 1468,
        [NAMES.BMP_BTR]: 3577,
        [NAMES.ARTA]: 745,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2523,
        [NAMES.PPO]: 98
    },
    "Jun 20 2022": {
        [NAMES.TROOPS]: 33800,
        [NAMES.JETS]: 216,
        [NAMES.HELI]: 181,
        [NAMES.UAV]: 601,
        [NAMES.TANKS]: 1477,
        [NAMES.BMP_BTR]: 3588,
        [NAMES.ARTA]: 749,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2527
    },
    "Jun 21 2022": {
        [NAMES.TROOPS]: 34100,
        [NAMES.JETS]: 216,
        [NAMES.HELI]: 181,
        [NAMES.UAV]: 611,
        [NAMES.TANKS]: 1496,
        [NAMES.BMP_BTR]: 3606,
        [NAMES.ARTA]: 752,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2537
    },
    "Jun 22 2022": {
        [NAMES.TROOPS]: 34230,
        [NAMES.JETS]: 216,
        [NAMES.HELI]: 182,
        [NAMES.UAV]: 614,
        [NAMES.TANKS]: 1496,
        [NAMES.BMP_BTR]: 3614,
        [NAMES.ARTA]: 752,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2543
    },
    "Jun 23 2022": {
        [NAMES.TROOPS]: 34430,
        [NAMES.JETS]: 216,
        [NAMES.HELI]: 183,
        [NAMES.UAV]: 620,
        [NAMES.TANKS]: 1504,
        [NAMES.BMP_BTR]: 3632,
        [NAMES.ARTA]: 756,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2548
    },
    "Jun 24 2022": {
        [NAMES.TROOPS]: 34530,
        [NAMES.JETS]: 216,
        [NAMES.HELI]: 183,
        [NAMES.UAV]: 622,
        [NAMES.TANKS]: 1507,
        [NAMES.BMP_BTR]: 3637,
        [NAMES.ARTA]: 759,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2553
    },
    "Jun 25 2022": {
        [NAMES.TROOPS]: 34700,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 184,
        [NAMES.UAV]: 626,
        [NAMES.TANKS]: 1511,
        [NAMES.BMP_BTR]: 3645,
        [NAMES.ARTA]: 764,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2560
    },
    "Jun 26 2022": {
        [NAMES.TROOPS]: 34850,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 184,
        [NAMES.UAV]: 630,
        [NAMES.TANKS]: 1532,
        [NAMES.BMP_BTR]: 3659,
        [NAMES.ARTA]: 764,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2564,
        [NAMES.PPO]: 99
    },
    "Jun 27 2022": {
        [NAMES.TROOPS]: 35000,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 184,
        [NAMES.UAV]: 636,
        [NAMES.TANKS]: 1552,
        [NAMES.BMP_BTR]: 3687,
        [NAMES.ARTA]: 771,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2575,
        [NAMES.PPO]: 101
    },
    "Jun 28 2022": {
        [NAMES.TROOPS]: 35250,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 185,
        [NAMES.UAV]: 636,
        [NAMES.TANKS]: 1567,
        [NAMES.BMP_BTR]: 3704,
        [NAMES.ARTA]: 778,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2589,
        [NAMES.PPO]: 102
    },
    "Jun 29 2022": {
        [NAMES.TROOPS]: 35450,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 185,
        [NAMES.UAV]: 640,
        [NAMES.TANKS]: 1572,
        [NAMES.BMP_BTR]: 3720,
        [NAMES.ARTA]: 781,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2598,
        [NAMES.PPO]: 103
    },
    "Jun 30 2022": {
        [NAMES.TROOPS]: 35600,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 185,
        [NAMES.UAV]: 641,
        [NAMES.TANKS]: 1573,
        [NAMES.BMP_BTR]: 3726,
        [NAMES.ARTA]: 790,
        [NAMES.SHIPS]: 14,
        [NAMES.AUTO]: 2602,
        [NAMES.PPO]: 104
    },
    "Jul 1 2022": {
        [NAMES.TROOPS]: 35750,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 186,
        [NAMES.UAV]: 645,
        [NAMES.TANKS]: 1577,
        [NAMES.BMP_BTR]: 3736,
        [NAMES.ARTA]: 796,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2610,
        [NAMES.PPO]: 105
    },
    "Jul 2 2022": {
        [NAMES.TROOPS]: 35870,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 186,
        [NAMES.UAV]: 653,
        [NAMES.TANKS]: 1582,
        [NAMES.BMP_BTR]: 3737,
        [NAMES.ARTA]: 800,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2614,
        [NAMES.PPO]: 105
    },
    "Jul 3 2022": {
        [NAMES.TROOPS]: 35970,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 187,
        [NAMES.UAV]: 654,
        [NAMES.TANKS]: 1584,
        [NAMES.BMP_BTR]: 3744,
        [NAMES.ARTA]: 801,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2618
    },
    "Jul 4 2022": {
        [NAMES.TROOPS]: 36200,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 187,
        [NAMES.UAV]: 654,
        [NAMES.TANKS]: 1589,
        [NAMES.BMP_BTR]: 3754,
        [NAMES.ARTA]: 804,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2629
    },
    "Jul 5 2022": {
        [NAMES.TROOPS]: 36350,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 187,
        [NAMES.UAV]: 660,
        [NAMES.TANKS]: 1594,
        [NAMES.BMP_BTR]: 3772,
        [NAMES.ARTA]: 806,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2634,
        [NAMES.PPO]: 105
    },
    "Jul 6 2022": {
        [NAMES.TROOPS]: 36500,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 187,
        [NAMES.UAV]: 664,
        [NAMES.TANKS]: 1600,
        [NAMES.BMP_BTR]: 3789,
        [NAMES.ARTA]: 812,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2648,
        [NAMES.PPO]: 107
    },
    "Jul 7 2022": {
        [NAMES.TROOPS]: 36650,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 187,
        [NAMES.UAV]: 667,
        [NAMES.TANKS]: 1602,
        [NAMES.BMP_BTR]: 3797,
        [NAMES.ARTA]: 815,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2665,
        [NAMES.PPO]: 107
    },
    "Jul 8 2022": {
        [NAMES.TROOPS]: 36900,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 187,
        [NAMES.UAV]: 669,
        [NAMES.TANKS]: 1637,
        [NAMES.BMP_BTR]: 3811,
        [NAMES.ARTA]: 828,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2685,
        [NAMES.PPO]: 107
    },
    "Jul 9 2022": {
        [NAMES.TROOPS]: 37200,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 187,
        [NAMES.UAV]: 674,
        [NAMES.TANKS]: 1638,
        [NAMES.BMP_BTR]: 3815,
        [NAMES.ARTA]: 832,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2687,
        [NAMES.PPO]: 108
    },
    "Jul 10 2022": {
        [NAMES.TROOPS]: 37300,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 676,
        [NAMES.TANKS]: 1641,
        [NAMES.BMP_BTR]: 3823,
        [NAMES.ARTA]: 834,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2694,
        [NAMES.PPO]: 108
    },
    "Jul 11 2022": {
        [NAMES.TROOPS]: 37400,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 676,
        [NAMES.TANKS]: 1645,
        [NAMES.BMP_BTR]: 3828,
        [NAMES.ARTA]: 838,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2696,
        [NAMES.PPO]: 109
    },
    "Jul 12 2022": {
        [NAMES.TROOPS]: 37470,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 676,
        [NAMES.TANKS]: 1649,
        [NAMES.BMP_BTR]: 3829,
        [NAMES.ARTA]: 838,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2699,
        [NAMES.PPO]: 109
    },
    "Jul 13 2022": {
        [NAMES.TROOPS]: 37570,
        [NAMES.JETS]: 217,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 678,
        [NAMES.TANKS]: 1649,
        [NAMES.BMP_BTR]: 3832,
        [NAMES.ARTA]: 839,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2704,
        [NAMES.PPO]: 109
    },
    "Jul 14 2022": {
        [NAMES.TROOPS]: 37870,
        [NAMES.JETS]: 219,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 681,
        [NAMES.TANKS]: 1667,
        [NAMES.BMP_BTR]: 3852,
        [NAMES.ARTA]: 840,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2720
    },
    "Jul 15 2022": {
        [NAMES.TROOPS]: 38000,
        [NAMES.JETS]: 220,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 681,
        [NAMES.TANKS]: 1672,
        [NAMES.BMP_BTR]: 3866,
        [NAMES.ARTA]: 842,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2731
    },
    "Jul 16 2022": {
        [NAMES.TROOPS]: 38140,
        [NAMES.JETS]: 220,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 687,
        [NAMES.TANKS]: 1677,
        [NAMES.BMP_BTR]: 3874,
        [NAMES.ARTA]: 846,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2735,
        [NAMES.PPO]: 109
    },
    "Jul 17 2022": {
        [NAMES.TROOPS]: 38300,
        [NAMES.JETS]: 220,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 688,
        [NAMES.TANKS]: 1684,
        [NAMES.BMP_BTR]: 3879,
        [NAMES.ARTA]: 846,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2746,
        [NAMES.PPO]: 110
    },
    "Jul 18 2022": {
        [NAMES.TROOPS]: 38450,
        [NAMES.JETS]: 220,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 690,
        [NAMES.TANKS]: 1687,
        [NAMES.BMP_BTR]: 3886,
        [NAMES.ARTA]: 849,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2753,
        [NAMES.PPO]: 113
    },
    "Jul 19 2022": {
        [NAMES.TROOPS]: 38550,
        [NAMES.JETS]: 220,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 693,
        [NAMES.TANKS]: 1691,
        [NAMES.BMP_BTR]: 3892,
        [NAMES.ARTA]: 851,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2767,
        [NAMES.PPO]: 113
    },
    "Jul 20 2022": {
        [NAMES.TROOPS]: 38750,
        [NAMES.JETS]: 221,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 703,
        [NAMES.TANKS]: 1700,
        [NAMES.BMP_BTR]: 3905,
        [NAMES.ARTA]: 856,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2775,
        [NAMES.PPO]: 113
    },
    "Jul 21 2022": {
        [NAMES.TROOPS]: 38850,
        [NAMES.JETS]: 221,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 710,
        [NAMES.TANKS]: 1704,
        [NAMES.BMP_BTR]: 3912,
        [NAMES.ARTA]: 859,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2781,
        [NAMES.PPO]: 113
    },
    "Jul 22 2022": {
        [NAMES.TROOPS]: 39000,
        [NAMES.JETS]: 221,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 713,
        [NAMES.TANKS]: 1704,
        [NAMES.BMP_BTR]: 3920,
        [NAMES.ARTA]: 863,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2803,
        [NAMES.PPO]: 113
    },
    "Jul 23 2022": {
        [NAMES.TROOPS]: 39240,
        [NAMES.JETS]: 221,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 714,
        [NAMES.TANKS]: 1708,
        [NAMES.BMP_BTR]: 3929,
        [NAMES.ARTA]: 864,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2820,
        [NAMES.PPO]: 113
    },
    "Jul 24 2022": {
        [NAMES.TROOPS]: 39520,
        [NAMES.JETS]: 221,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 714,
        [NAMES.TANKS]: 1722,
        [NAMES.BMP_BTR]: 3942,
        [NAMES.ARTA]: 869,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2823,
        [NAMES.PPO]: 113
    },
    "Jul 25 2022": {
        [NAMES.TROOPS]: 39700,
        [NAMES.JETS]: 222,
        [NAMES.HELI]: 188,
        [NAMES.UAV]: 719,
        [NAMES.TANKS]: 1730,
        [NAMES.BMP_BTR]: 3950,
        [NAMES.ARTA]: 876,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2832,
        [NAMES.PPO]: 116
    },
    "Jul 26 2022": {
        [NAMES.TROOPS]: 39870,
        [NAMES.JETS]: 222,
        [NAMES.HELI]: 189,
        [NAMES.UAV]: 722,
        [NAMES.TANKS]: 1737,
        [NAMES.BMP_BTR]: 3959,
        [NAMES.ARTA]: 880,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2835,
        [NAMES.PPO]: 117
    },
    "Jul 27 2022": {
        [NAMES.TROOPS]: 40070,
        [NAMES.JETS]: 222,
        [NAMES.HELI]: 190,
        [NAMES.UAV]: 726,
        [NAMES.TANKS]: 1738,
        [NAMES.BMP_BTR]: 3971,
        [NAMES.ARTA]: 883,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2847,
        [NAMES.PPO]: 117
    },
    "Jul 28 2022": {
        [NAMES.TROOPS]: 40230,
        [NAMES.JETS]: 222,
        [NAMES.HELI]: 190,
        [NAMES.UAV]: 729,
        [NAMES.TANKS]: 1742,
        [NAMES.BMP_BTR]: 3979,
        [NAMES.ARTA]: 894,
        [NAMES.PPO]: 117,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2854
    },
    "Jul 29 2022": {
        [NAMES.TROOPS]: 40500,
        [NAMES.TANKS]: 1749,
        [NAMES.BMP_BTR]: 3987,
        [NAMES.ARTA]: 900,
        [NAMES.PPO]: 117,
        [NAMES.JETS]: 222,
        [NAMES.HELI]: 190,
        [NAMES.UAV]: 731,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2870
    },
    "Jul 30 2022": {
        [NAMES.TROOPS]: 40670,
        [NAMES.TANKS]: 1759,
        [NAMES.BMP_BTR]: 3995,
        [NAMES.ARTA]: 906,
        [NAMES.PPO]: 117,
        [NAMES.JETS]: 222,
        [NAMES.HELI]: 190,
        [NAMES.UAV]: 733,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2889
    },
    "Jul 31 2022": {
        [NAMES.TROOPS]: 40830,
        [NAMES.TANKS]: 1763,
        [NAMES.BMP_BTR]: 4004,
        [NAMES.ARTA]: 916,
        [NAMES.PPO]: 117,
        [NAMES.JETS]: 223,
        [NAMES.HELI]: 190,
        [NAMES.UAV]: 735,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2902
    },
    "Aug 1 2022": {
        [NAMES.TROOPS]: 41030,
        [NAMES.TANKS]: 1768,
        [NAMES.BMP_BTR]: 4011,
        [NAMES.ARTA]: 932,
        [NAMES.PPO]: 117,
        [NAMES.JETS]: 223,
        [NAMES.HELI]: 190,
        [NAMES.UAV]: 736,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2912
    },
    "Aug 2 2022": {
        [NAMES.TROOPS]: 41170,
        [NAMES.TANKS]: 1768,
        [NAMES.BMP_BTR]: 4014,
        [NAMES.ARTA]: 936,
        [NAMES.PPO]: 117,
        [NAMES.JETS]: 223,
        [NAMES.HELI]: 191,
        [NAMES.UAV]: 739,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2914
    },
    "Aug 3 2022": {
        [NAMES.TROOPS]: 41350,
        [NAMES.TANKS]: 1774,
        [NAMES.BMP_BTR]: 4022,
        [NAMES.ARTA]: 939,
        [NAMES.PPO]: 118,
        [NAMES.JETS]: 223,
        [NAMES.HELI]: 191,
        [NAMES.UAV]: 740,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2922
    },
    "Aug 4 2022": {
        [NAMES.TROOPS]: 41500,
        [NAMES.TANKS]: 1789,
        [NAMES.BMP_BTR]: 4026,
        [NAMES.ARTA]: 946,
        [NAMES.PPO]: 118,
        [NAMES.JETS]: 223,
        [NAMES.HELI]: 191,
        [NAMES.UAV]: 742,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2960
    },
    "Aug 5 2022": {
        [NAMES.TROOPS]: 41650,
        [NAMES.TANKS]: 1792,
        [NAMES.BMP_BTR]: 4032,
        [NAMES.ARTA]: 950,
        [NAMES.PPO]: 123,
        [NAMES.JETS]: 223,
        [NAMES.HELI]: 191,
        [NAMES.UAV]: 742,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2964
    },
    "Aug 6 2022": {
        [NAMES.TROOPS]: 41900,
        [NAMES.TANKS]: 1802,
        [NAMES.BMP_BTR]: 4051,
        [NAMES.ARTA]: 955,
        [NAMES.PPO]: 127,
        [NAMES.JETS]: 223,
        [NAMES.HELI]: 191,
        [NAMES.UAV]: 744,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2970
    },
    "Aug 7 2022": {
        [NAMES.TROOPS]: 42200,
        [NAMES.TANKS]: 1805,
        [NAMES.BMP_BTR]: 4055,
        [NAMES.ARTA]: 958,
        [NAMES.PPO]: 132,
        [NAMES.JETS]: 223,
        [NAMES.HELI]: 191,
        [NAMES.UAV]: 750,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2978
    },
    "Aug 8 2022": {
        [NAMES.TROOPS]: 42340,
        [NAMES.TANKS]: 1811,
        [NAMES.BMP_BTR]: 4070,
        [NAMES.ARTA]: 960,
        [NAMES.PPO]: 132,
        [NAMES.JETS]: 223,
        [NAMES.HELI]: 192,
        [NAMES.UAV]: 754,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2993
    },
    "Aug 9 2022": {
        [NAMES.TROOPS]: 42640,
        [NAMES.TANKS]: 1817,
        [NAMES.BMP_BTR]: 4076,
        [NAMES.ARTA]: 964,
        [NAMES.PPO]: 133,
        [NAMES.JETS]: 223,
        [NAMES.HELI]: 193,
        [NAMES.UAV]: 757,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 2998
    },
    "Aug 10 2022": {
        [NAMES.TROOPS]: 42800,
        [NAMES.TANKS]: 1832,
        [NAMES.BMP_BTR]: 4086,
        [NAMES.ARTA]: 971,
        [NAMES.PPO]: 133,
        [NAMES.JETS]: 232,
        [NAMES.HELI]: 193,
        [NAMES.UAV]: 766,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3005
    },
    "Aug 11 2022": {
        [NAMES.TROOPS]: 43000,
        [NAMES.TANKS]: 1846,
        [NAMES.BMP_BTR]: 4100,
        [NAMES.ARTA]: 974,
        [NAMES.PPO]: 134,
        [NAMES.JETS]: 232,
        [NAMES.HELI]: 193,
        [NAMES.UAV]: 772,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3018
    },
    "Aug 12 2022": {
        [NAMES.TROOPS]: 43200,
        [NAMES.TANKS]: 1849,
        [NAMES.BMP_BTR]: 4108,
        [NAMES.ARTA]: 975,
        [NAMES.PPO]: 136,
        [NAMES.JETS]: 233,
        [NAMES.HELI]: 193,
        [NAMES.UAV]: 778,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3021
    },
    "Aug 13 2022": {
        [NAMES.TROOPS]: 43400,
        [NAMES.TANKS]: 1856,
        [NAMES.BMP_BTR]: 4115,
        [NAMES.ARTA]: 978,
        [NAMES.PPO]: 136,
        [NAMES.JETS]: 233,
        [NAMES.HELI]: 193,
        [NAMES.UAV]: 779,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3036
    },
    "Aug 14 2022": {
        [NAMES.TROOPS]: 43550,
        [NAMES.TANKS]: 1864,
        [NAMES.BMP_BTR]: 4126,
        [NAMES.ARTA]: 980,
        [NAMES.PPO]: 136,
        [NAMES.JETS]: 233,
        [NAMES.HELI]: 194,
        [NAMES.UAV]: 784,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3039
    },
    "Aug 15 2022": {
        [NAMES.TROOPS]: 43750,
        [NAMES.TANKS]: 1876,
        [NAMES.BMP_BTR]: 4141,
        [NAMES.ARTA]: 985,
        [NAMES.PPO]: 136,
        [NAMES.JETS]: 233,
        [NAMES.HELI]: 195,
        [NAMES.UAV]: 787,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3044
    },
    "Aug 16 2022": {
        [NAMES.TROOPS]: 43900,
        [NAMES.TANKS]: 1880,
        [NAMES.BMP_BTR]: 4152,
        [NAMES.ARTA]: 989,
        [NAMES.PPO]: 136,
        [NAMES.JETS]: 233,
        [NAMES.HELI]: 196,
        [NAMES.UAV]: 790,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3049
    },
    "Aug 17 2022": {
        [NAMES.TROOPS]: 44100,
        [NAMES.TANKS]: 1886,
        [NAMES.BMP_BTR]: 4162,
        [NAMES.ARTA]: 993,
        [NAMES.PPO]: 136,
        [NAMES.JETS]: 233,
        [NAMES.HELI]: 196,
        [NAMES.UAV]: 792,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3054
    },
    "Aug 18 2022": {
        [NAMES.TROOPS]: 44300,
        [NAMES.TANKS]: 1889,
        [NAMES.BMP_BTR]: 4179,
        [NAMES.ARTA]: 1010,
        [NAMES.PPO]: 136,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 197,
        [NAMES.UAV]: 793,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3061
    },
    "Aug 19 2022": {
        [NAMES.TROOPS]: 44700,
        [NAMES.TANKS]: 1899,
        [NAMES.BMP_BTR]: 4195,
        [NAMES.ARTA]: 1016,
        [NAMES.PPO]: 141,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 197,
        [NAMES.UAV]: 795,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3130
    },
    "Aug 20 2022": {
        [NAMES.TROOPS]: 44900,
        [NAMES.TANKS]: 1907,
        [NAMES.BMP_BTR]: 4212,
        [NAMES.ARTA]: 1018,
        [NAMES.PPO]: 141,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 197,
        [NAMES.UAV]: 803,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3137
    },
    "Aug 21 2022": {
        [NAMES.TROOPS]: 45200,
        [NAMES.TANKS]: 1912,
        [NAMES.BMP_BTR]: 4224,
        [NAMES.ARTA]: 1028,
        [NAMES.PPO]: 141,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 197,
        [NAMES.UAV]: 806,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3143
    },
    "Aug 22 2022": {
        [NAMES.TROOPS]: 45400,
        [NAMES.TANKS]: 1919,
        [NAMES.BMP_BTR]: 4230,
        [NAMES.ARTA]: 1032,
        [NAMES.PPO]: 145,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 198,
        [NAMES.UAV]: 815,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3149
    },
    "Aug 23 2022": {
        [NAMES.TROOPS]: 45550,
        [NAMES.TANKS]: 1921,
        [NAMES.BMP_BTR]: 4238,
        [NAMES.ARTA]: 1033,
        [NAMES.PPO]: 146,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 198,
        [NAMES.UAV]: 817,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3150
    },
    "Aug 24 2022": {
        [NAMES.TROOPS]: 45700,
        [NAMES.TANKS]: 1924,
        [NAMES.BMP_BTR]: 4243,
        [NAMES.ARTA]: 1036,
        [NAMES.PPO]: 147,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 199,
        [NAMES.UAV]: 819,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3160
    },
    "Aug 25 2022": {
        [NAMES.TROOPS]: 45850,
        [NAMES.TANKS]: 1929,
        [NAMES.BMP_BTR]: 4245,
        [NAMES.ARTA]: 1037,
        [NAMES.PPO]: 148,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 202,
        [NAMES.UAV]: 828,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3160
    },
    "Aug 26 2022": {
        [NAMES.TROOPS]: 46250,
        [NAMES.TANKS]: 1936,
        [NAMES.BMP_BTR]: 4251,
        [NAMES.ARTA]: 1040,
        [NAMES.PPO]: 148,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 202,
        [NAMES.UAV]: 834,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3162
    },
    "Aug 27 2022": {
        [NAMES.TROOPS]: 46500,
        [NAMES.TANKS]: 1939,
        [NAMES.BMP_BTR]: 4254,
        [NAMES.ARTA]: 1045,
        [NAMES.PPO]: 148,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 202,
        [NAMES.UAV]: 836,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3165
    },
    "Aug 28 2022": {
        [NAMES.TROOPS]: 46750,
        [NAMES.TANKS]: 1942,
        [NAMES.BMP_BTR]: 4257,
        [NAMES.ARTA]: 1050,
        [NAMES.PPO]: 148,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 202,
        [NAMES.UAV]: 838,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3171
    },
    "Aug 29 2022": {
        [NAMES.TROOPS]: 47100,
        [NAMES.TANKS]: 1947,
        [NAMES.BMP_BTR]: 4269,
        [NAMES.ARTA]: 1060,
        [NAMES.PPO]: 149,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 203,
        [NAMES.UAV]: 844,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3188
    },
    "Aug 30 2022": {
        [NAMES.TROOPS]: 47550,
        [NAMES.TANKS]: 1954,
        [NAMES.BMP_BTR]: 4294,
        [NAMES.ARTA]: 1079,
        [NAMES.PPO]: 151,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 204,
        [NAMES.UAV]: 847,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3217
    },
    "Aug 31 2022": {
        [NAMES.TROOPS]: 47900,
        [NAMES.TANKS]: 1974,
        [NAMES.BMP_BTR]: 4312,
        [NAMES.ARTA]: 1091,
        [NAMES.PPO]: 152,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 204,
        [NAMES.UAV]: 849,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3236
    },
    "Sep 1 2022": {
        [NAMES.TROOPS]: 48350,
        [NAMES.TANKS]: 1997,
        [NAMES.BMP_BTR]: 4345,
        [NAMES.ARTA]: 1115,
        [NAMES.PPO]: 153,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 205,
        [NAMES.UAV]: 851,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3239
    },
    "Sep 2 2022": {
        [NAMES.TROOPS]: 48700,
        [NAMES.TANKS]: 2009,
        [NAMES.BMP_BTR]: 4366,
        [NAMES.ARTA]: 1126,
        [NAMES.PPO]: 153,
        [NAMES.JETS]: 234,
        [NAMES.HELI]: 205,
        [NAMES.UAV]: 853,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3247
    },
    "Sep 3 2022": {
        [NAMES.TROOPS]: 49050,
        [NAMES.TANKS]: 2034,
        [NAMES.BMP_BTR]: 4403,
        [NAMES.ARTA]: 1134,
        [NAMES.PPO]: 153,
        [NAMES.JETS]: 235,
        [NAMES.HELI]: 205,
        [NAMES.UAV]: 864,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3268
    },
    "Sep 4 2022": {
        [NAMES.TROOPS]: 49500,
        [NAMES.TANKS]: 2049,
        [NAMES.BMP_BTR]: 4430,
        [NAMES.ARTA]: 1147,
        [NAMES.PPO]: 156,
        [NAMES.JETS]: 236,
        [NAMES.HELI]: 206,
        [NAMES.UAV]: 864,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3276
    },
    "Sep 5 2022": {
        [NAMES.TROOPS]: 49800,
        [NAMES.TANKS]: 2068,
        [NAMES.BMP_BTR]: 4459,
        [NAMES.ARTA]: 1157,
        [NAMES.PPO]: 156,
        [NAMES.JETS]: 236,
        [NAMES.HELI]: 206,
        [NAMES.UAV]: 867,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3286
    },
    "Sep 6 2022": {
        [NAMES.TROOPS]: 50150,
        [NAMES.TANKS]: 2077,
        [NAMES.BMP_BTR]: 4484,
        [NAMES.ARTA]: 1179,
        [NAMES.PPO]: 156,
        [NAMES.JETS]: 236,
        [NAMES.HELI]: 207,
        [NAMES.UAV]: 876,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3305
    },
    "Sep 7 2022": {
        [NAMES.TROOPS]: 50610,
        [NAMES.TANKS]: 2097,
        [NAMES.BMP_BTR]: 4520,
        [NAMES.ARTA]: 1194,
        [NAMES.PPO]: 156,
        [NAMES.JETS]: 237,
        [NAMES.HELI]: 208,
        [NAMES.UAV]: 880,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3320
    },
    "Sep 8 2022": {
        [NAMES.TROOPS]: 51250,
        [NAMES.TANKS]: 2112,
        [NAMES.BMP_BTR]: 4557,
        [NAMES.ARTA]: 1226,
        [NAMES.PPO]: 159,
        [NAMES.JETS]: 239,
        [NAMES.HELI]: 210,
        [NAMES.UAV]: 884,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3344
    },
    "Sep 9 2022": {
        [NAMES.TROOPS]: 51900,
        [NAMES.TANKS]: 2122,
        [NAMES.BMP_BTR]: 4575,
        [NAMES.ARTA]: 1237,
        [NAMES.PPO]: 159,
        [NAMES.JETS]: 239,
        [NAMES.HELI]: 211,
        [NAMES.UAV]: 888,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3399
    },
    "Sep 10 2022": {
        [NAMES.TROOPS]: 52250,
        [NAMES.TANKS]: 2136,
        [NAMES.BMP_BTR]: 4584,
        [NAMES.ARTA]: 1259,
        [NAMES.PPO]: 162,
        [NAMES.JETS]: 239,
        [NAMES.HELI]: 212,
        [NAMES.UAV]: 898,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3426
    },
    "Sep 11 2022": {
        [NAMES.TROOPS]: 52650,
        [NAMES.TANKS]: 2154,
        [NAMES.BMP_BTR]: 4617,
        [NAMES.ARTA]: 1263,
        [NAMES.PPO]: 162,
        [NAMES.JETS]: 242,
        [NAMES.HELI]: 213,
        [NAMES.UAV]: 902,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3445
    },
    "Sep 12 2022": {
        [NAMES.TROOPS]: 52950,
        [NAMES.TANKS]: 2168,
        [NAMES.BMP_BTR]: 4640,
        [NAMES.ARTA]: 1269,
        [NAMES.PPO]: 162,
        [NAMES.JETS]: 243,
        [NAMES.HELI]: 213,
        [NAMES.UAV]: 903,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3463
    },
    "Sep 13 2022": {
        [NAMES.TROOPS]: 53300,
        [NAMES.TANKS]: 2175,
        [NAMES.BMP_BTR]: 4662,
        [NAMES.ARTA]: 1279,
        [NAMES.PPO]: 165,
        [NAMES.JETS]: 244,
        [NAMES.HELI]: 213,
        [NAMES.UAV]: 904,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3469
    },
    "Sep 14 2022": {
        [NAMES.TROOPS]: 53650,
        [NAMES.TANKS]: 2180,
        [NAMES.BMP_BTR]: 4665,
        [NAMES.ARTA]: 1290,
        [NAMES.PPO]: 167,
        [NAMES.JETS]: 246,
        [NAMES.HELI]: 215,
        [NAMES.UAV]: 908,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3501
    },
    "Sep 15 2022": {
        [NAMES.TROOPS]: 53850,
        [NAMES.TANKS]: 2193,
        [NAMES.BMP_BTR]: 4682,
        [NAMES.ARTA]: 1295,
        [NAMES.PPO]: 167,
        [NAMES.JETS]: 250,
        [NAMES.HELI]: 215,
        [NAMES.UAV]: 908,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3522
    },
    "Sep 16 2022": {
        [NAMES.TROOPS]: 54050,
        [NAMES.TANKS]: 2199,
        [NAMES.BMP_BTR]: 4690,
        [NAMES.ARTA]: 1302,
        [NAMES.PPO]: 168,
        [NAMES.JETS]: 250,
        [NAMES.HELI]: 216,
        [NAMES.UAV]: 908,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3550
    },
    "Sep 17 2022": {
        [NAMES.TROOPS]: 54250,
        [NAMES.TANKS]: 2202,
        [NAMES.BMP_BTR]: 4701,
        [NAMES.ARTA]: 1306,
        [NAMES.PPO]: 168,
        [NAMES.JETS]: 251,
        [NAMES.HELI]: 216,
        [NAMES.UAV]: 911,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3571
    },
    "Sep 18 2022": {
        [NAMES.TROOPS]: 54480,
        [NAMES.TANKS]: 2210,
        [NAMES.BMP_BTR]: 4718,
        [NAMES.ARTA]: 1309,
        [NAMES.PPO]: 168,
        [NAMES.JETS]: 251,
        [NAMES.HELI]: 217,
        [NAMES.UAV]: 918,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3578
    },
    "Sep 19 2022": {
        [NAMES.TROOPS]: 54650,
        [NAMES.TANKS]: 2212,
        [NAMES.BMP_BTR]: 4720,
        [NAMES.ARTA]: 1313,
        [NAMES.PPO]: 168,
        [NAMES.JETS]: 251,
        [NAMES.HELI]: 217,
        [NAMES.UAV]: 920,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3581
    },
    "Sep 20 2022": {
        [NAMES.TROOPS]: 54810,
        [NAMES.TANKS]: 2216,
        [NAMES.BMP_BTR]: 4724,
        [NAMES.ARTA]: 1323,
        [NAMES.PPO]: 168,
        [NAMES.JETS]: 252,
        [NAMES.HELI]: 217,
        [NAMES.UAV]: 925,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3587
    },
    "Sep 21 2022": {
        [NAMES.TROOPS]: 55110,
        [NAMES.TANKS]: 2227,
        [NAMES.BMP_BTR]: 4748,
        [NAMES.ARTA]: 1340,
        [NAMES.PPO]: 168,
        [NAMES.JETS]: 253,
        [NAMES.HELI]: 217,
        [NAMES.UAV]: 932,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3610
    },
    "Sep 22 2022": {
        [NAMES.TROOPS]: 55510,
        [NAMES.TANKS]: 2236,
        [NAMES.BMP_BTR]: 4776,
        [NAMES.ARTA]: 1341,
        [NAMES.PPO]: 169,
        [NAMES.JETS]: 253,
        [NAMES.HELI]: 217,
        [NAMES.UAV]: 941,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3630
    },
    "Sep 23 2022": {
        [NAMES.TROOPS]: 56060,
        [NAMES.TANKS]: 2254,
        [NAMES.BMP_BTR]: 4796,
        [NAMES.ARTA]: 1355,
        [NAMES.PPO]: 170,
        [NAMES.JETS]: 254,
        [NAMES.HELI]: 218,
        [NAMES.UAV]: 950,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3659
    },
    "Sep 24 2022": {
        [NAMES.TROOPS]: 56300,
        [NAMES.TANKS]: 2262,
        [NAMES.BMP_BTR]: 4807,
        [NAMES.ARTA]: 1361,
        [NAMES.PPO]: 170,
        [NAMES.JETS]: 255,
        [NAMES.HELI]: 219,
        [NAMES.UAV]: 956,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3681
    },
    "Sep 25 2022": {
        [NAMES.TROOPS]: 56700,
        [NAMES.TANKS]: 2275,
        [NAMES.BMP_BTR]: 4832,
        [NAMES.ARTA]: 1368,
        [NAMES.PPO]: 171,
        [NAMES.JETS]: 259,
        [NAMES.HELI]: 220,
        [NAMES.UAV]: 966,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3701
    },
    "Sep 26 2022": {
        [NAMES.TROOPS]: 57200,
        [NAMES.TANKS]: 2290,
        [NAMES.BMP_BTR]: 4857,
        [NAMES.ARTA]: 1369,
        [NAMES.PPO]: 172,
        [NAMES.JETS]: 260,
        [NAMES.HELI]: 224,
        [NAMES.UAV]: 970,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3711
    },
    "Sep 27 2022": {
        [NAMES.TROOPS]: 57750,
        [NAMES.TANKS]: 2306,
        [NAMES.BMP_BTR]: 4881,
        [NAMES.ARTA]: 1378,
        [NAMES.PPO]: 175,
        [NAMES.JETS]: 261,
        [NAMES.HELI]: 224,
        [NAMES.UAV]: 977,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3730
    },
    "Sep 28 2022": {
        [NAMES.TROOPS]: 58150,
        [NAMES.TANKS]: 2312,
        [NAMES.BMP_BTR]: 4889,
        [NAMES.ARTA]: 1381,
        [NAMES.PPO]: 175,
        [NAMES.JETS]: 262,
        [NAMES.HELI]: 224,
        [NAMES.UAV]: 989,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3742
    },
    "Sep 29 2022": {
        [NAMES.TROOPS]: 58580,
        [NAMES.TANKS]: 2325,
        [NAMES.BMP_BTR]: 4909,
        [NAMES.ARTA]: 1385,
        [NAMES.PPO]: 175,
        [NAMES.JETS]: 262,
        [NAMES.HELI]: 224,
        [NAMES.UAV]: 995,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3751
    },
    "Sep 30 2022": {
        [NAMES.TROOPS]: 59080,
        [NAMES.TANKS]: 2338,
        [NAMES.BMP_BTR]: 4932,
        [NAMES.ARTA]: 1391,
        [NAMES.PPO]: 176,
        [NAMES.JETS]: 264,
        [NAMES.HELI]: 225,
        [NAMES.UAV]: 1003,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3768
    },
    "Oct 1 2022": {
        [NAMES.TROOPS]: 59610,
        [NAMES.TANKS]: 2354,
        [NAMES.BMP_BTR]: 4949,
        [NAMES.ARTA]: 1397,
        [NAMES.PPO]: 176,
        [NAMES.JETS]: 264,
        [NAMES.HELI]: 226,
        [NAMES.UAV]: 1009,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3786
    },
    "Oct 2 2022": {
        [NAMES.TROOPS]: 60110,
        [NAMES.TANKS]: 2377,
        [NAMES.BMP_BTR]: 4975,
        [NAMES.ARTA]: 1405,
        [NAMES.PPO]: 176,
        [NAMES.JETS]: 264,
        [NAMES.HELI]: 227,
        [NAMES.UAV]: 1015,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3796
    },
    "Oct 3 2022": {
        [NAMES.TROOPS]: 60430,
        [NAMES.TANKS]: 2380,
        [NAMES.BMP_BTR]: 4991,
        [NAMES.ARTA]: 1405,
        [NAMES.PPO]: 176,
        [NAMES.JETS]: 265,
        [NAMES.HELI]: 228,
        [NAMES.UAV]: 1026,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3811
    },
    "Oct 4 2022": {
        [NAMES.TROOPS]: 60800,
        [NAMES.TANKS]: 2424,
        [NAMES.BMP_BTR]: 5018,
        [NAMES.ARTA]: 1407,
        [NAMES.PPO]: 177,
        [NAMES.JETS]: 266,
        [NAMES.HELI]: 228,
        [NAMES.UAV]: 1028,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3823
    },
    "Oct 5 2022": {
        [NAMES.TROOPS]: 61000,
        [NAMES.TANKS]: 2435,
        [NAMES.BMP_BTR]: 5038,
        [NAMES.ARTA]: 1414,
        [NAMES.PPO]: 177,
        [NAMES.JETS]: 266,
        [NAMES.HELI]: 232,
        [NAMES.UAV]: 1032,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3841
    },
    "Oct 6 2022": {
        [NAMES.TROOPS]: 61330,
        [NAMES.TANKS]: 2449,
        [NAMES.BMP_BTR]: 5064,
        [NAMES.ARTA]: 1424,
        [NAMES.PPO]: 177,
        [NAMES.JETS]: 266,
        [NAMES.HELI]: 232,
        [NAMES.UAV]: 1047,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3854
    },
    "Oct 7 2022": {
        [NAMES.TROOPS]: 61680,
        [NAMES.TANKS]: 2466,
        [NAMES.BMP_BTR]: 5093,
        [NAMES.ARTA]: 1455,
        [NAMES.PPO]: 177,
        [NAMES.JETS]: 266,
        [NAMES.HELI]: 233,
        [NAMES.UAV]: 1067,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3862
    },
    "Oct 8 2022": {
        [NAMES.TROOPS]: 62060,
        [NAMES.TANKS]: 2472,
        [NAMES.BMP_BTR]: 5111,
        [NAMES.ARTA]: 1459,
        [NAMES.PPO]: 180,
        [NAMES.JETS]: 266,
        [NAMES.HELI]: 234,
        [NAMES.UAV]: 1079,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3875
    },
    "Oct 9 2022": {
        [NAMES.TROOPS]: 62500,
        [NAMES.TANKS]: 2486,
        [NAMES.BMP_BTR]: 5133,
        [NAMES.ARTA]: 1477,
        [NAMES.PPO]: 180,
        [NAMES.JETS]: 266,
        [NAMES.HELI]: 235,
        [NAMES.UAV]: 1086,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3890
    },
    "Oct 10 2022": {
        [NAMES.TROOPS]: 62870,
        [NAMES.TANKS]: 2495,
        [NAMES.BMP_BTR]: 5149,
        [NAMES.ARTA]: 1486,
        [NAMES.PPO]: 181,
        [NAMES.JETS]: 267,
        [NAMES.HELI]: 235,
        [NAMES.UAV]: 1097,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3908
    },
    "Oct 11 2022": {
        [NAMES.TROOPS]: 63110,
        [NAMES.TANKS]: 2504,
        [NAMES.BMP_BTR]: 5160,
        [NAMES.ARTA]: 1496,
        [NAMES.PPO]: 181,
        [NAMES.JETS]: 268,
        [NAMES.HELI]: 235,
        [NAMES.UAV]: 1114,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3916
    },
    "Oct 12 2022": {
        [NAMES.TROOPS]: 63380,
        [NAMES.TANKS]: 2505,
        [NAMES.BMP_BTR]: 5161,
        [NAMES.ARTA]: 1507,
        [NAMES.PPO]: 182,
        [NAMES.JETS]: 268,
        [NAMES.HELI]: 235,
        [NAMES.UAV]: 1129,
        [NAMES.SHIPS]: 15,
        [NAMES.AUTO]: 3927
    },
    "Oct 13 2022": {
        [NAMES.TROOPS]: 63800,
        [NAMES.TANKS]: 2511,
        [NAMES.BMP_BTR]: 5167,
        [NAMES.ARTA]: 1556,
        [NAMES.PPO]: 182,
        [NAMES.JETS]: 268,
        [NAMES.HELI]: 240,
        [NAMES.UAV]: 1182,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 3935
    },
    "Oct 14 2022": {
        [NAMES.TROOPS]: 64300,
        [NAMES.TANKS]: 2521,
        [NAMES.BMP_BTR]: 5172,
        [NAMES.ARTA]: 1566,
        [NAMES.PPO]: 186,
        [NAMES.JETS]: 268,
        [NAMES.HELI]: 240,
        [NAMES.UAV]: 1199,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 3944
    },
    "Oct 15 2022": {
        [NAMES.TROOPS]: 64700,
        [NAMES.TANKS]: 2524,
        [NAMES.BMP_BTR]: 5179,
        [NAMES.ARTA]: 1582,
        [NAMES.PPO]: 186,
        [NAMES.JETS]: 268,
        [NAMES.HELI]: 242,
        [NAMES.UAV]: 1210,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 3951
    },
    "Oct 16 2022": {
        [NAMES.TROOPS]: 65000,
        [NAMES.TANKS]: 2529,
        [NAMES.BMP_BTR]: 5193,
        [NAMES.ARTA]: 1589,
        [NAMES.PPO]: 186,
        [NAMES.JETS]: 268,
        [NAMES.HELI]: 242,
        [NAMES.UAV]: 1224,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 3959
    },
    "Oct 17 2022": {
        [NAMES.TROOPS]: 65320,
        [NAMES.TANKS]: 2537,
        [NAMES.BMP_BTR]: 5205,
        [NAMES.ARTA]: 1599,
        [NAMES.PPO]: 187,
        [NAMES.JETS]: 268,
        [NAMES.HELI]: 242,
        [NAMES.UAV]: 1241,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 3969
    },
    "Oct 18 2022": {
        [NAMES.TROOPS]: 65850,
        [NAMES.TANKS]: 2548,
        [NAMES.BMP_BTR]: 5219,
        [NAMES.ARTA]: 1622,
        [NAMES.PPO]: 188,
        [NAMES.JETS]: 268,
        [NAMES.HELI]: 242,
        [NAMES.UAV]: 1276,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 3985
    },
    "Oct 19 2022": {
        [NAMES.TROOPS]: 66280,
        [NAMES.TANKS]: 2554,
        [NAMES.BMP_BTR]: 5235,
        [NAMES.ARTA]: 1637,
        [NAMES.PPO]: 189,
        [NAMES.JETS]: 269,
        [NAMES.HELI]: 242,
        [NAMES.UAV]: 1286,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 3999
    },
    "Oct 20 2022": {
        [NAMES.TROOPS]: 66650,
        [NAMES.TANKS]: 2567,
        [NAMES.BMP_BTR]: 5255,
        [NAMES.ARTA]: 1646,
        [NAMES.PPO]: 189,
        [NAMES.JETS]: 269,
        [NAMES.HELI]: 243,
        [NAMES.UAV]: 1311,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4005
    },
    "Oct 21 2022": {
        [NAMES.TROOPS]: 66750,
        [NAMES.TANKS]: 2573,
        [NAMES.BMP_BTR]: 5258,
        [NAMES.ARTA]: 1648,
        [NAMES.PPO]: 189,
        [NAMES.JETS]: 269,
        [NAMES.HELI]: 243,
        [NAMES.UAV]: 1325,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4006
    },
    "Oct 22 2022": {
        [NAMES.TROOPS]: 67070,
        [NAMES.TANKS]: 2579,
        [NAMES.BMP_BTR]: 5266,
        [NAMES.ARTA]: 1653,
        [NAMES.PPO]: 189,
        [NAMES.JETS]: 270,
        [NAMES.HELI]: 243,
        [NAMES.UAV]: 1341,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4021
    },
    "Oct 23 2022": {
        [NAMES.TROOPS]: 67470,
        [NAMES.TANKS]: 2584,
        [NAMES.BMP_BTR]: 5284,
        [NAMES.ARTA]: 1667,
        [NAMES.PPO]: 189,
        [NAMES.JETS]: 270,
        [NAMES.HELI]: 245,
        [NAMES.UAV]: 1361,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4039
    },
    "Oct 24 2022": {
        [NAMES.TROOPS]: 67940,
        [NAMES.TANKS]: 2590,
        [NAMES.BMP_BTR]: 5295,
        [NAMES.ARTA]: 1673,
        [NAMES.PPO]: 189,
        [NAMES.JETS]: 270,
        [NAMES.HELI]: 245,
        [NAMES.UAV]: 1370,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4044
    },
    "Oct 25 2022": {
        [NAMES.TROOPS]: 68420,
        [NAMES.TANKS]: 2611,
        [NAMES.BMP_BTR]: 5321,
        [NAMES.ARTA]: 1674,
        [NAMES.PPO]: 190,
        [NAMES.JETS]: 271,
        [NAMES.HELI]: 248,
        [NAMES.UAV]: 1372,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4054
    },
    "Oct 26 2022": {
        [NAMES.TROOPS]: 68900,
        [NAMES.TANKS]: 2628,
        [NAMES.BMP_BTR]: 5351,
        [NAMES.ARTA]: 1686,
        [NAMES.PPO]: 192,
        [NAMES.JETS]: 271,
        [NAMES.HELI]: 248,
        [NAMES.UAV]: 1379,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4076
    },
    "Oct 27 2022": {
        [NAMES.TROOPS]: 69220,
        [NAMES.TANKS]: 2631,
        [NAMES.BMP_BTR]: 5364,
        [NAMES.ARTA]: 1690,
        [NAMES.PPO]: 192,
        [NAMES.JETS]: 271,
        [NAMES.HELI]: 249,
        [NAMES.UAV]: 1398,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4078
    },
    "Oct 28 2022": {
        [NAMES.TROOPS]: 69700,
        [NAMES.TANKS]: 2640,
        [NAMES.BMP_BTR]: 5378,
        [NAMES.ARTA]: 1698,
        [NAMES.PPO]: 192,
        [NAMES.JETS]: 272,
        [NAMES.HELI]: 251,
        [NAMES.UAV]: 1401,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4080
    },
    "Oct 29 2022": {
        [NAMES.TROOPS]: 70250,
        [NAMES.TANKS]: 2659,
        [NAMES.BMP_BTR]: 5401,
        [NAMES.ARTA]: 1708,
        [NAMES.PPO]: 195,
        [NAMES.JETS]: 273,
        [NAMES.HELI]: 252,
        [NAMES.UAV]: 1406,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4107
    },
    "Oct 30 2022": {
        [NAMES.TROOPS]: 71200,
        [NAMES.TANKS]: 2672,
        [NAMES.BMP_BTR]: 5453,
        [NAMES.ARTA]: 1724,
        [NAMES.PPO]: 197,
        [NAMES.JETS]: 274,
        [NAMES.HELI]: 252,
        [NAMES.UAV]: 1412,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4120
    },
    "Oct 31 2022": {
        [NAMES.TROOPS]: 71820,
        [NAMES.TANKS]: 2686,
        [NAMES.BMP_BTR]: 5485,
        [NAMES.ARTA]: 1728,
        [NAMES.PPO]: 197,
        [NAMES.JETS]: 275,
        [NAMES.HELI]: 253,
        [NAMES.UAV]: 1413,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4128
    },
    "Nov 1 2022": {
        [NAMES.TROOPS]: 72470,
        [NAMES.TANKS]: 2698,
        [NAMES.BMP_BTR]: 5501,
        [NAMES.ARTA]: 1730,
        [NAMES.PPO]: 197,
        [NAMES.JETS]: 276,
        [NAMES.HELI]: 257,
        [NAMES.UAV]: 1415,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4143
    },
    "Nov 2 2022": {
        [NAMES.TROOPS]: 73270,
        [NAMES.TANKS]: 2714,
        [NAMES.BMP_BTR]: 5525,
        [NAMES.ARTA]: 1733,
        [NAMES.PPO]: 198,
        [NAMES.JETS]: 277,
        [NAMES.HELI]: 258,
        [NAMES.UAV]: 1438,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4153
    },
    "Nov 3 2022": {
        [NAMES.TROOPS]: 74000,
        [NAMES.TANKS]: 2734,
        [NAMES.BMP_BTR]: 5552,
        [NAMES.ARTA]: 1755,
        [NAMES.PPO]: 198,
        [NAMES.JETS]: 277,
        [NAMES.HELI]: 258,
        [NAMES.UAV]: 1442,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4162
    },
    "Nov 4 2022": {
        [NAMES.TROOPS]: 74840,
        [NAMES.TANKS]: 2750,
        [NAMES.BMP_BTR]: 5580,
        [NAMES.ARTA]: 1772,
        [NAMES.PPO]: 201,
        [NAMES.JETS]: 277,
        [NAMES.HELI]: 258,
        [NAMES.UAV]: 1450,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4174
    },
    "Nov 5 2022": {
        [NAMES.TROOPS]: 75440,
        [NAMES.TANKS]: 2758,
        [NAMES.BMP_BTR]: 5601,
        [NAMES.ARTA]: 1776,
        [NAMES.PPO]: 202,
        [NAMES.JETS]: 277,
        [NAMES.HELI]: 260,
        [NAMES.UAV]: 1462,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4184
    },
    "Nov 6 2022": {
        [NAMES.TROOPS]: 75930,
        [NAMES.TANKS]: 2765,
        [NAMES.BMP_BTR]: 5611,
        [NAMES.ARTA]: 1781,
        [NAMES.PPO]: 202,
        [NAMES.JETS]: 277,
        [NAMES.HELI]: 260,
        [NAMES.UAV]: 1465,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4191
    },
    "Nov 7 2022": {
        [NAMES.TROOPS]: 76460,
        [NAMES.TANKS]: 2771,
        [NAMES.BMP_BTR]: 5630,
        [NAMES.ARTA]: 1782,
        [NAMES.PPO]: 202,
        [NAMES.JETS]: 277,
        [NAMES.HELI]: 260,
        [NAMES.UAV]: 1472,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4199
    },
    "Nov 8 2022": {
        [NAMES.TROOPS]: 77170,
        [NAMES.TANKS]: 2786,
        [NAMES.BMP_BTR]: 5654,
        [NAMES.ARTA]: 1791,
        [NAMES.PPO]: 203,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 260,
        [NAMES.UAV]: 1476,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4216
    },
    "Nov 9 2022": {
        [NAMES.TROOPS]: 77950,
        [NAMES.TANKS]: 2801,
        [NAMES.BMP_BTR]: 5666,
        [NAMES.ARTA]: 1802,
        [NAMES.PPO]: 205,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 260,
        [NAMES.UAV]: 1483,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4227
    },
    "Nov 10 2022": {
        [NAMES.TROOPS]: 78690,
        [NAMES.TANKS]: 2804,
        [NAMES.BMP_BTR]: 5682,
        [NAMES.ARTA]: 1805,
        [NAMES.PPO]: 205,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 260,
        [NAMES.UAV]: 1499,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4242
    },
    "Nov 11 2022": {
        [NAMES.TROOPS]: 79400,
        [NAMES.TANKS]: 2814,
        [NAMES.BMP_BTR]: 5696,
        [NAMES.ARTA]: 1817,
        [NAMES.PPO]: 205,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1505,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4259
    },
    "Nov 12 2022": {
        [NAMES.TROOPS]: 80210,
        [NAMES.TANKS]: 2838,
        [NAMES.BMP_BTR]: 5730,
        [NAMES.ARTA]: 1829,
        [NAMES.PPO]: 205,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1506,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4279
    },
    "Nov 13 2022": {
        [NAMES.TROOPS]: 80860,
        [NAMES.TANKS]: 2840,
        [NAMES.BMP_BTR]: 5742,
        [NAMES.ARTA]: 1837,
        [NAMES.PPO]: 206,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1507,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4295
    },
    "Nov 14 2022": {
        [NAMES.TROOPS]: 81370,
        [NAMES.TANKS]: 2848,
        [NAMES.BMP_BTR]: 5748,
        [NAMES.ARTA]: 1839,
        [NAMES.PPO]: 206,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1509,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4316
    },
    "Nov 15 2022": {
        [NAMES.TROOPS]: 82080,
        [NAMES.TANKS]: 2861,
        [NAMES.BMP_BTR]: 5773,
        [NAMES.ARTA]: 1850,
        [NAMES.PPO]: 208,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1511,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4351
    },
    "Nov 16 2022": {
        [NAMES.TROOPS]: 82710,
        [NAMES.TANKS]: 2871,
        [NAMES.BMP_BTR]: 5797,
        [NAMES.ARTA]: 1860,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1525,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4360
    },
    "Nov 17 2022": {
        [NAMES.TROOPS]: 83110,
        [NAMES.TANKS]: 2878,
        [NAMES.BMP_BTR]: 5804,
        [NAMES.ARTA]: 1860,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1531,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4362
    },
    "Nov 18 2022": {
        [NAMES.TROOPS]: 83460,
        [NAMES.TANKS]: 2879,
        [NAMES.BMP_BTR]: 5808,
        [NAMES.ARTA]: 1865,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1536,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4366
    },
    "Nov 19 2022": {
        [NAMES.TROOPS]: 83880,
        [NAMES.TANKS]: 2885,
        [NAMES.BMP_BTR]: 5815,
        [NAMES.ARTA]: 1867,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1536,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4368
    },
    "Nov 20 2022": {
        [NAMES.TROOPS]: 84210,
        [NAMES.TANKS]: 2886,
        [NAMES.BMP_BTR]: 5817,
        [NAMES.ARTA]: 1868,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1537,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4371
    },
    "Nov 21 2022": {
        [NAMES.TROOPS]: 84600,
        [NAMES.TANKS]: 2892,
        [NAMES.BMP_BTR]: 5822,
        [NAMES.ARTA]: 1870,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1537,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4378
    },
    "Nov 22 2022": {
        [NAMES.TROOPS]: 85000,
        [NAMES.TANKS]: 2895,
        [NAMES.BMP_BTR]: 5827,
        [NAMES.ARTA]: 1882,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1537,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4393
    },
    "Nov 23 2022": {
        [NAMES.TROOPS]: 85410,
        [NAMES.TANKS]: 2897,
        [NAMES.BMP_BTR]: 5832,
        [NAMES.ARTA]: 1887,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1537,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4396
    },
    "Nov 24 2022": {
        [NAMES.TROOPS]: 85720,
        [NAMES.TANKS]: 2898,
        [NAMES.BMP_BTR]: 5839,
        [NAMES.ARTA]: 1889,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1547,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4400
    },
    "Nov 25 2022": {
        [NAMES.TROOPS]: 86150,
        [NAMES.TANKS]: 2899,
        [NAMES.BMP_BTR]: 5844,
        [NAMES.ARTA]: 1895,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1553,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4404
    },
    "Nov 26 2022": {
        [NAMES.TROOPS]: 86710,
        [NAMES.TANKS]: 2901,
        [NAMES.BMP_BTR]: 5848,
        [NAMES.ARTA]: 1896,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1554,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4406
    },
    "Nov 27 2022": {
        [NAMES.TROOPS]: 87310,
        [NAMES.TANKS]: 2905,
        [NAMES.BMP_BTR]: 5856,
        [NAMES.ARTA]: 1897,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1555,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4412
    },
    "Nov 28 2022": {
        [NAMES.TROOPS]: 87900,
        [NAMES.TANKS]: 2908,
        [NAMES.BMP_BTR]: 5861,
        [NAMES.ARTA]: 1899,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 278,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1555,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4416
    },
    "Nov 29 2022": {
        [NAMES.TROOPS]: 88380,
        [NAMES.TANKS]: 2911,
        [NAMES.BMP_BTR]: 5866,
        [NAMES.ARTA]: 1901,
        [NAMES.PPO]: 209,
        [NAMES.JETS]: 280,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1555,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4423
    },
    "Nov 30 2022": {
        [NAMES.TROOPS]: 88880,
        [NAMES.TANKS]: 2914,
        [NAMES.BMP_BTR]: 5872,
        [NAMES.ARTA]: 1902,
        [NAMES.PPO]: 210,
        [NAMES.JETS]: 280,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1562,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4429
    },
    "Dec 01 2022": {
        [NAMES.TROOPS]: 89440,
        [NAMES.TANKS]: 2915,
        [NAMES.BMP_BTR]: 5877,
        [NAMES.ARTA]: 1904,
        [NAMES.PPO]: 210,
        [NAMES.JETS]: 280,
        [NAMES.HELI]: 261,
        [NAMES.UAV]: 1562,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4441
    },
    "Dec 02 2022": {
        [NAMES.TROOPS]: 90090,
        [NAMES.TANKS]: 2916,
        [NAMES.BMP_BTR]: 5883,
        [NAMES.ARTA]: 1905,
        [NAMES.PPO]: 210,
        [NAMES.JETS]: 280,
        [NAMES.HELI]: 262,
        [NAMES.UAV]: 1564,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4464
    },
    "Dec 03 2022": {
        [NAMES.TROOPS]: 90600,
        [NAMES.TANKS]: 2917,
        [NAMES.BMP_BTR]: 5886,
        [NAMES.ARTA]: 1906,
        [NAMES.PPO]: 210,
        [NAMES.JETS]: 280,
        [NAMES.HELI]: 263,
        [NAMES.UAV]: 1572,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4472
    },
    "Dec 04 2022": {
        [NAMES.TROOPS]: 91150,
        [NAMES.TANKS]: 2922,
        [NAMES.BMP_BTR]: 5892,
        [NAMES.ARTA]: 1908,
        [NAMES.PPO]: 210,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 263,
        [NAMES.UAV]: 1573,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4479
    },
    "Dec 05 2022": {
        [NAMES.TROOPS]: 91690,
        [NAMES.TANKS]: 2924,
        [NAMES.BMP_BTR]: 5900,
        [NAMES.ARTA]: 1914,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1582,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4497
    },
    "Dec 06 2022": {
        [NAMES.TROOPS]: 92200,
        [NAMES.TANKS]: 2929,
        [NAMES.BMP_BTR]: 5905,
        [NAMES.ARTA]: 1915,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1587,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4505
    },
    "Dec 07 2022": {
        [NAMES.TROOPS]: 92740,
        [NAMES.TANKS]: 2935,
        [NAMES.BMP_BTR]: 5909,
        [NAMES.ARTA]: 1923,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1601,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4526
    },
    "Dec 08 2022": {
        [NAMES.TROOPS]: 93080,
        [NAMES.TANKS]: 2937,
        [NAMES.BMP_BTR]: 5911,
        [NAMES.ARTA]: 1925,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1603,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4528
    },
    "Dec 09 2022": {
        [NAMES.TROOPS]: 93390,
        [NAMES.TANKS]: 2937,
        [NAMES.BMP_BTR]: 5912,
        [NAMES.ARTA]: 1926,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1603,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4531
    },
    "Dec 10 2022": {
        [NAMES.TROOPS]: 93760,
        [NAMES.TANKS]: 2940,
        [NAMES.BMP_BTR]: 5917,
        [NAMES.ARTA]: 1927,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1603,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4535
    },
    "Dec 11 2022": {
        [NAMES.TROOPS]: 94140,
        [NAMES.TANKS]: 2942,
        [NAMES.BMP_BTR]: 5920,
        [NAMES.ARTA]: 1928,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1613,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4540
    },
    "Dec 12 2022": {
        [NAMES.TROOPS]: 94760,
        [NAMES.TANKS]: 2966,
        [NAMES.BMP_BTR]: 5928,
        [NAMES.ARTA]: 1921,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1617,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4544
    },
    "Dec 13 2022": {
        [NAMES.TROOPS]: 95260,
        [NAMES.TANKS]: 2966,
        [NAMES.BMP_BTR]: 5930,
        [NAMES.ARTA]: 1923,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1617,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4549
    },
    "Dec 14 2022": {
        [NAMES.TROOPS]: 96000,
        [NAMES.TANKS]: 2970,
        [NAMES.BMP_BTR]: 5937,
        [NAMES.ARTA]: 1923,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1617,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4562
    },
    "Dec 15 2022": {
        [NAMES.TROOPS]: 96590,
        [NAMES.TANKS]: 2975,
        [NAMES.BMP_BTR]: 5946,
        [NAMES.ARTA]: 1942,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1644,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4563
    },
    "Dec 16 2022": {
        [NAMES.TROOPS]: 97270,
        [NAMES.TANKS]: 2980,
        [NAMES.BMP_BTR]: 5952,
        [NAMES.ARTA]: 1946,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1648,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4563
    },
    "Dec 17 2022": {
        [NAMES.TROOPS]: 97690,
        [NAMES.TANKS]: 2985,
        [NAMES.BMP_BTR]: 5958,
        [NAMES.ARTA]: 1947,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1648,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4577
    },
    "Dec 18 2022": {
        [NAMES.TROOPS]: 98280,
        [NAMES.TANKS]: 2987,
        [NAMES.BMP_BTR]: 5963,
        [NAMES.ARTA]: 1948,
        [NAMES.PPO]: 211,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1649,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4579
    },
    "Dec 19 2022": {
        [NAMES.TROOPS]: 98800,
        [NAMES.TANKS]: 2988,
        [NAMES.BMP_BTR]: 5969,
        [NAMES.ARTA]: 1953,
        [NAMES.PPO]: 212,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 264,
        [NAMES.UAV]: 1657,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4592
    },
    "Dec 20 2022": {
        [NAMES.TROOPS]: 99230,
        [NAMES.TANKS]: 2995,
        [NAMES.BMP_BTR]: 5974,
        [NAMES.ARTA]: 1960,
        [NAMES.PPO]: 212,
        [NAMES.JETS]: 281,
        [NAMES.HELI]: 266,
        [NAMES.UAV]: 1680,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4599
    },
    "Dec 21 2022": {
        [NAMES.TROOPS]: 99740,
        [NAMES.TANKS]: 3002,
        [NAMES.BMP_BTR]: 5979,
        [NAMES.ARTA]: 1972,
        [NAMES.PPO]: 212,
        [NAMES.JETS]: 282,
        [NAMES.HELI]: 267,
        [NAMES.UAV]: 1688,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4608
    },
    "Dec 22 2022": {
        [NAMES.TROOPS]: 100400,
        [NAMES.TANKS]: 3003,
        [NAMES.BMP_BTR]: 5981,
        [NAMES.ARTA]: 1978,
        [NAMES.PPO]: 212,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 267,
        [NAMES.UAV]: 1693,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4615
    },
    "Dec 23 2022": {
        [NAMES.TROOPS]: 100950,
        [NAMES.TANKS]: 3005,
        [NAMES.BMP_BTR]: 5986,
        [NAMES.ARTA]: 1984,
        [NAMES.PPO]: 212,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 267,
        [NAMES.UAV]: 1698,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4622
    },
    "Dec 24 2022": {
        [NAMES.TROOPS]: 101430,
        [NAMES.TANKS]: 3006,
        [NAMES.BMP_BTR]: 5994,
        [NAMES.ARTA]: 1988,
        [NAMES.PPO]: 212,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 267,
        [NAMES.UAV]: 1706,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4627
    },
    "Dec 25 2022": {
        [NAMES.TROOPS]: 102050,
        [NAMES.TANKS]: 3011,
        [NAMES.BMP_BTR]: 6010,
        [NAMES.ARTA]: 1991,
        [NAMES.PPO]: 212,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 267,
        [NAMES.UAV]: 1706,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4635
    },
    "Dec 26 2022": {
        [NAMES.TROOPS]: 102600,
        [NAMES.TANKS]: 3016,
        [NAMES.BMP_BTR]: 6017,
        [NAMES.ARTA]: 1996,
        [NAMES.PPO]: 212,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 267,
        [NAMES.UAV]: 1707,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4647
    },
    "Dec 27 2022": {
        [NAMES.TROOPS]: 103220,
        [NAMES.TANKS]: 3016,
        [NAMES.BMP_BTR]: 6024,
        [NAMES.ARTA]: 1998,
        [NAMES.PPO]: 212,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 267,
        [NAMES.UAV]: 1707,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4652
    },
    "Dec 28 2022": {
        [NAMES.TROOPS]: 103770,
        [NAMES.TANKS]: 3017,
        [NAMES.BMP_BTR]: 6037,
        [NAMES.ARTA]: 1999,
        [NAMES.PPO]: 212,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 267,
        [NAMES.UAV]: 1707,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4660
    },
    "Dec 29 2022": {
        [NAMES.TROOPS]: 104560,
        [NAMES.TANKS]: 3018,
        [NAMES.BMP_BTR]: 6047,
        [NAMES.ARTA]: 2004,
        [NAMES.PPO]: 212,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 268,
        [NAMES.UAV]: 1707,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4675
    },
    "Dec 30 2022": {
        [NAMES.TROOPS]: 105250,
        [NAMES.TANKS]: 3026,
        [NAMES.BMP_BTR]: 6059,
        [NAMES.ARTA]: 2010,
        [NAMES.PPO]: 212,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 268,
        [NAMES.UAV]: 1740,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4683
    },
    "Dec 31 2022": {
        [NAMES.TROOPS]: 105960,
        [NAMES.TANKS]: 3029,
        [NAMES.BMP_BTR]: 6075,
        [NAMES.ARTA]: 2016,
        [NAMES.PPO]: 213,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 269,
        [NAMES.UAV]: 1746,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4707
    },
    "Jan 1 2023": {
        [NAMES.TROOPS]: 106720,
        [NAMES.TANKS]: 3031,
        [NAMES.BMP_BTR]: 6084,
        [NAMES.ARTA]: 2021,
        [NAMES.PPO]: 213,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 269,
        [NAMES.UAV]: 1792,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4720
    },
    "Jan 2 2023": {
        [NAMES.TROOPS]: 107440,
        [NAMES.TANKS]: 3031,
        [NAMES.BMP_BTR]: 6093,
        [NAMES.ARTA]: 2027,
        [NAMES.PPO]: 213,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 269,
        [NAMES.UAV]: 1836,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4725
    },
    "Jan 3 2023": {
        [NAMES.TROOPS]: 108190,
        [NAMES.TANKS]: 3036,
        [NAMES.BMP_BTR]: 6100,
        [NAMES.ARTA]: 2033,
        [NAMES.PPO]: 214,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 270,
        [NAMES.UAV]: 1839,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4735
    },
    "Jan 4 2023": {
        [NAMES.TROOPS]: 108910,
        [NAMES.TANKS]: 3038,
        [NAMES.BMP_BTR]: 6106,
        [NAMES.ARTA]: 2039,
        [NAMES.PPO]: 215,
        [NAMES.JETS]: 283,
        [NAMES.HELI]: 270,
        [NAMES.UAV]: 1842,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4745
    },
    "Jan 5 2023": {
        [NAMES.TROOPS]: 109720,
        [NAMES.TANKS]: 3041,
        [NAMES.BMP_BTR]: 6108,
        [NAMES.ARTA]: 2051,
        [NAMES.PPO]: 215,
        [NAMES.JETS]: 284,
        [NAMES.HELI]: 271,
        [NAMES.UAV]: 1844,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4759
    },
    "Jan 6 2023": {
        [NAMES.TROOPS]: 110250,
        [NAMES.TANKS]: 3064,
        [NAMES.BMP_BTR]: 6124,
        [NAMES.ARTA]: 2059,
        [NAMES.PPO]: 215,
        [NAMES.JETS]: 285,
        [NAMES.HELI]: 272,
        [NAMES.UAV]: 1844,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4797
    },
    "Jan 7 2023": {
        [NAMES.TROOPS]: 110740,
        [NAMES.TANKS]: 3066,
        [NAMES.BMP_BTR]: 6125,
        [NAMES.ARTA]: 2062,
        [NAMES.PPO]: 217,
        [NAMES.JETS]: 285,
        [NAMES.HELI]: 272,
        [NAMES.UAV]: 1844,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4798
    },
    "Jan 8 2023": {
        [NAMES.TROOPS]: 111170,
        [NAMES.TANKS]: 3069,
        [NAMES.BMP_BTR]: 6130,
        [NAMES.ARTA]: 2065,
        [NAMES.PPO]: 217,
        [NAMES.JETS]: 285,
        [NAMES.HELI]: 272,
        [NAMES.UAV]: 1849,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4801
    },
    "Jan 9 2023": {
        [NAMES.TROOPS]: 111760,
        [NAMES.TANKS]: 3080,
        [NAMES.BMP_BTR]: 6147,
        [NAMES.ARTA]: 2069,
        [NAMES.PPO]: 217,
        [NAMES.JETS]: 285,
        [NAMES.HELI]: 275,
        [NAMES.UAV]: 1856,
        [NAMES.SHIPS]: 16,
        [NAMES.AUTO]: 4809
    },
    "Jan 10 2023": {
        [NAMES.TROOPS]: 112470,
        [NAMES.TANKS]: 3084,
        [NAMES.BMP_BTR]: 6154,
        [NAMES.ARTA]: 2073,
        [NAMES.PPO]: 217,
        [NAMES.JETS]: 285,
        [NAMES.HELI]: 275,
        [NAMES.UAV]: 1860,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4817
    },
    "Jan 11 2023": {
        [NAMES.TROOPS]: 112960,
        [NAMES.TANKS]: 3094,
        [NAMES.BMP_BTR]: 6159,
        [NAMES.ARTA]: 2078,
        [NAMES.PPO]: 217,
        [NAMES.JETS]: 285,
        [NAMES.HELI]: 275,
        [NAMES.UAV]: 1862,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4826
    },
    "Jan 12 2023": {
        [NAMES.TROOPS]: 113390,
        [NAMES.TANKS]: 3094,
        [NAMES.BMP_BTR]: 6159,
        [NAMES.ARTA]: 2082,
        [NAMES.PPO]: 218,
        [NAMES.JETS]: 285,
        [NAMES.HELI]: 276,
        [NAMES.UAV]: 1865,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4826
    },
    "Jan 13 2023": {
        [NAMES.TROOPS]: 114130,
        [NAMES.TANKS]: 3098,
        [NAMES.BMP_BTR]: 6167,
        [NAMES.ARTA]: 2086,
        [NAMES.PPO]: 218,
        [NAMES.JETS]: 286,
        [NAMES.HELI]: 276,
        [NAMES.UAV]: 1865,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4833
    },
    "Jan 14 2023": {
        [NAMES.TROOPS]: 114660,
        [NAMES.TANKS]: 3104,
        [NAMES.BMP_BTR]: 6173,
        [NAMES.ARTA]: 2090,
        [NAMES.PPO]: 219,
        [NAMES.JETS]: 286,
        [NAMES.HELI]: 276,
        [NAMES.UAV]: 1867,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4846
    },
    "Jan 15 2023": {
        [NAMES.TROOPS]: 115290,
        [NAMES.TANKS]: 3106,
        [NAMES.BMP_BTR]: 6183,
        [NAMES.ARTA]: 2094,
        [NAMES.PPO]: 219,
        [NAMES.JETS]: 286,
        [NAMES.HELI]: 276,
        [NAMES.UAV]: 1872,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4846
    },
    "Jan 16 2023": {
        [NAMES.TROOPS]: 116080,
        [NAMES.TANKS]: 3118,
        [NAMES.BMP_BTR]: 6204,
        [NAMES.ARTA]: 2099,
        [NAMES.PPO]: 220,
        [NAMES.JETS]: 286,
        [NAMES.HELI]: 276,
        [NAMES.UAV]: 1872,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4870
    },
    "Jan 17 2023": {
        [NAMES.TROOPS]: 116950,
        [NAMES.TANKS]: 3121,
        [NAMES.BMP_BTR]: 6215,
        [NAMES.ARTA]: 2104,
        [NAMES.PPO]: 220,
        [NAMES.JETS]: 286,
        [NAMES.HELI]: 276,
        [NAMES.UAV]: 1872,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4877
    },
    "Jan 18 2023": {
        [NAMES.TROOPS]: 117770,
        [NAMES.TANKS]: 3130,
        [NAMES.BMP_BTR]: 6225,
        [NAMES.ARTA]: 2108,
        [NAMES.PPO]: 220,
        [NAMES.JETS]: 287,
        [NAMES.HELI]: 276,
        [NAMES.UAV]: 1872,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4889
    },
    "Jan 19 2023": {
        [NAMES.TROOPS]: 118530,
        [NAMES.TANKS]: 3136,
        [NAMES.BMP_BTR]: 6235,
        [NAMES.ARTA]: 2122,
        [NAMES.PPO]: 220,
        [NAMES.JETS]: 287,
        [NAMES.HELI]: 277,
        [NAMES.UAV]: 1882,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4896
    },
    "Jan 20 2023": {
        [NAMES.TROOPS]: 119300,
        [NAMES.TANKS]: 3139,
        [NAMES.BMP_BTR]: 6241,
        [NAMES.ARTA]: 2129,
        [NAMES.PPO]: 220,
        [NAMES.JETS]: 287,
        [NAMES.HELI]: 277,
        [NAMES.UAV]: 1886,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4903
    },
    "Jan 21 2023": {
        [NAMES.TROOPS]: 120160,
        [NAMES.TANKS]: 3140,
        [NAMES.BMP_BTR]: 6256,
        [NAMES.ARTA]: 2135,
        [NAMES.PPO]: 220,
        [NAMES.JETS]: 287,
        [NAMES.HELI]: 277,
        [NAMES.UAV]: 1891,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4918
    },
    "Jan 22 2023": {
        [NAMES.TROOPS]: 120760,
        [NAMES.TANKS]: 3145,
        [NAMES.BMP_BTR]: 6268,
        [NAMES.ARTA]: 2144,
        [NAMES.PPO]: 220,
        [NAMES.JETS]: 287,
        [NAMES.HELI]: 277,
        [NAMES.UAV]: 1892,
        [NAMES.SHIPS]: 17,
        [NAMES.AUTO]: 4932
    },
    "Jan 23 2023": {
        [NAMES.TROOPS]: 121480,
        [NAMES.TANKS]: 3150,
        [NAMES.BMP_BTR]: 6276,
        [NAMES.ARTA]: 2146,
        [NAMES.PPO]: 220,
        [NAMES.JETS]: 287,
        [NAMES.HELI]: 277,
        [NAMES.UAV]: 1894,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 4936
    },
    "Jan 24 2023": {
        [NAMES.TROOPS]: 122170,
        [NAMES.TANKS]: 3152,
        [NAMES.BMP_BTR]: 6284,
        [NAMES.ARTA]: 2148,
        [NAMES.PPO]: 220,
        [NAMES.JETS]: 289,
        [NAMES.HELI]: 281,
        [NAMES.UAV]: 1897,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 4944
    },
    "Jan 25 2023": {
        [NAMES.TROOPS]: 123080,
        [NAMES.TANKS]: 3161,
        [NAMES.BMP_BTR]: 6307,
        [NAMES.ARTA]: 2154,
        [NAMES.PPO]: 220,
        [NAMES.JETS]: 290,
        [NAMES.HELI]: 281,
        [NAMES.UAV]: 1902,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 4967
    },
    "Jan 26 2023": {
        [NAMES.TROOPS]: 123860,
        [NAMES.TANKS]: 3175,
        [NAMES.BMP_BTR]: 6334,
        [NAMES.ARTA]: 2169,
        [NAMES.PPO]: 220,
        [NAMES.JETS]: 292,
        [NAMES.HELI]: 282,
        [NAMES.UAV]: 1908,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 4986
    },
    "Jan 27 2023": {
        [NAMES.TROOPS]: 124710,
        [NAMES.TANKS]: 3182,
        [NAMES.BMP_BTR]: 6340,
        [NAMES.ARTA]: 2180,
        [NAMES.PPO]: 221,
        [NAMES.JETS]: 292,
        [NAMES.HELI]: 283,
        [NAMES.UAV]: 1941,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5001
    },
    "Jan 28 2023": {
        [NAMES.TROOPS]: 125510,
        [NAMES.TANKS]: 3189,
        [NAMES.BMP_BTR]: 6344,
        [NAMES.ARTA]: 2188,
        [NAMES.PPO]: 221,
        [NAMES.JETS]: 293,
        [NAMES.HELI]: 284,
        [NAMES.UAV]: 1947,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5027
    },
    "Jan 29 2023": {
        [NAMES.TROOPS]: 126160,
        [NAMES.TANKS]: 3197,
        [NAMES.BMP_BTR]: 6366,
        [NAMES.ARTA]: 2195,
        [NAMES.PPO]: 221,
        [NAMES.JETS]: 293,
        [NAMES.HELI]: 284,
        [NAMES.UAV]: 1947,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5037
    },
    "Jan 30 2023": {
        [NAMES.TROOPS]: 126650,
        [NAMES.TANKS]: 3201,
        [NAMES.BMP_BTR]: 6369,
        [NAMES.ARTA]: 2196,
        [NAMES.PPO]: 221,
        [NAMES.JETS]: 293,
        [NAMES.HELI]: 284,
        [NAMES.UAV]: 1947,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5041
    },
    "Jan 31 2023": {
        [NAMES.TROOPS]: 127500,
        [NAMES.TANKS]: 3201,
        [NAMES.BMP_BTR]: 6378,
        [NAMES.ARTA]: 2197,
        [NAMES.PPO]: 221,
        [NAMES.JETS]: 293,
        [NAMES.HELI]: 284,
        [NAMES.UAV]: 1951,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5048
    },
    "Feb 1 2023": {
        [NAMES.TROOPS]: 128420,
        [NAMES.TANKS]: 3209,
        [NAMES.BMP_BTR]: 6382,
        [NAMES.ARTA]: 2207,
        [NAMES.PPO]: 221,
        [NAMES.JETS]: 293,
        [NAMES.HELI]: 284,
        [NAMES.UAV]: 1951,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5061
    },
    "Feb 2 2023": {
        [NAMES.TROOPS]: 129030,
        [NAMES.TANKS]: 3211,
        [NAMES.BMP_BTR]: 6382,
        [NAMES.ARTA]: 2212,
        [NAMES.PPO]: 222,
        [NAMES.JETS]: 293,
        [NAMES.HELI]: 284,
        [NAMES.UAV]: 1951,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5064
    },
    "Feb 3 2023": {
        [NAMES.TROOPS]: 129870,
        [NAMES.TANKS]: 3215,
        [NAMES.BMP_BTR]: 6388,
        [NAMES.ARTA]: 2215,
        [NAMES.PPO]: 222,
        [NAMES.JETS]: 294,
        [NAMES.HELI]: 284,
        [NAMES.UAV]: 1952,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5068
    },
    "Feb 4 2023": {
        [NAMES.TROOPS]: 130590,
        [NAMES.TANKS]: 3218,
        [NAMES.BMP_BTR]: 6394,
        [NAMES.ARTA]: 2220,
        [NAMES.PPO]: 225,
        [NAMES.JETS]: 294,
        [NAMES.HELI]: 284,
        [NAMES.UAV]: 1956,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5081
    },
    "Feb 5 2023": {
        [NAMES.TROOPS]: 131290,
        [NAMES.TANKS]: 3220,
        [NAMES.BMP_BTR]: 6405,
        [NAMES.ARTA]: 2226,
        [NAMES.PPO]: 227,
        [NAMES.JETS]: 294,
        [NAMES.HELI]: 284,
        [NAMES.UAV]: 1958,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5091
    },
    "Feb 6 2023": {
        [NAMES.TROOPS]: 132160,
        [NAMES.TANKS]: 3231,
        [NAMES.BMP_BTR]: 6415,
        [NAMES.ARTA]: 2231,
        [NAMES.PPO]: 227,
        [NAMES.JETS]: 294,
        [NAMES.HELI]: 284,
        [NAMES.UAV]: 1958,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5104
    },
    "Feb 7 2023": {
        [NAMES.TROOPS]: 133190,
        [NAMES.TANKS]: 3245,
        [NAMES.BMP_BTR]: 6443,
        [NAMES.ARTA]: 2232,
        [NAMES.PPO]: 227,
        [NAMES.JETS]: 294,
        [NAMES.HELI]: 284,
        [NAMES.UAV]: 1958,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5107
    },
    "Feb 8 2023": {
        [NAMES.TROOPS]: 134100,
        [NAMES.TANKS]: 3253,
        [NAMES.BMP_BTR]: 6458,
        [NAMES.ARTA]: 2236,
        [NAMES.PPO]: 228,
        [NAMES.JETS]: 295,
        [NAMES.HELI]: 285,
        [NAMES.UAV]: 1958,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5112
    },
    "Feb 9 2023": {
        [NAMES.TROOPS]: 135010,
        [NAMES.TANKS]: 3255,
        [NAMES.BMP_BTR]: 6468,
        [NAMES.ARTA]: 2244,
        [NAMES.PPO]: 232,
        [NAMES.JETS]: 295,
        [NAMES.HELI]: 285,
        [NAMES.UAV]: 1967,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5121
    },
    "Feb 10 2023": {
        [NAMES.TROOPS]: 135740,
        [NAMES.TANKS]: 3258,
        [NAMES.BMP_BTR]: 6471,
        [NAMES.ARTA]: 2251,
        [NAMES.PPO]: 233,
        [NAMES.JETS]: 295,
        [NAMES.HELI]: 286,
        [NAMES.UAV]: 1970,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5126
    },
    "Feb 11 2023": {
        [NAMES.TROOPS]: 136880,
        [NAMES.TANKS]: 3267,
        [NAMES.BMP_BTR]: 6474,
        [NAMES.ARTA]: 2270,
        [NAMES.PPO]: 234,
        [NAMES.JETS]: 295,
        [NAMES.HELI]: 286,
        [NAMES.UAV]: 1997,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5134
    },
    "Feb 12 2023": {
        [NAMES.TROOPS]: 137780,
        [NAMES.TANKS]: 3280,
        [NAMES.BMP_BTR]: 6488,
        [NAMES.ARTA]: 2287,
        [NAMES.PPO]: 234,
        [NAMES.JETS]: 296,
        [NAMES.HELI]: 286,
        [NAMES.UAV]: 2007,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5148
    },
    "Feb 13 2023": {
        [NAMES.TROOPS]: 138340,
        [NAMES.TANKS]: 3283,
        [NAMES.BMP_BTR]: 6492,
        [NAMES.ARTA]: 2290,
        [NAMES.PPO]: 234,
        [NAMES.JETS]: 296,
        [NAMES.HELI]: 286,
        [NAMES.UAV]: 2007,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5150
    },
    "Feb 14 2023": {
        [NAMES.TROOPS]: 139080,
        [NAMES.TANKS]: 3286,
        [NAMES.BMP_BTR]: 6500,
        [NAMES.ARTA]: 2299,
        [NAMES.PPO]: 234,
        [NAMES.JETS]: 298,
        [NAMES.HELI]: 286,
        [NAMES.UAV]: 2011,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5155
    },
    "Feb 15 2023": {
        [NAMES.TROOPS]: 139770,
        [NAMES.TANKS]: 3290,
        [NAMES.BMP_BTR]: 6507,
        [NAMES.ARTA]: 2303,
        [NAMES.PPO]: 236,
        [NAMES.JETS]: 298,
        [NAMES.HELI]: 286,
        [NAMES.UAV]: 2011,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5161
    },
    "Feb 16 2023": {
        [NAMES.TROOPS]: 140460,
        [NAMES.TANKS]: 3296,
        [NAMES.BMP_BTR]: 6517,
        [NAMES.ARTA]: 2306,
        [NAMES.PPO]: 239,
        [NAMES.JETS]: 298,
        [NAMES.HELI]: 287,
        [NAMES.UAV]: 2012,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5167
    },
    "Feb 17 2023": {
        [NAMES.TROOPS]: 141260,
        [NAMES.TANKS]: 3298,
        [NAMES.BMP_BTR]: 6520,
        [NAMES.ARTA]: 2322,
        [NAMES.PPO]: 241,
        [NAMES.JETS]: 298,
        [NAMES.HELI]: 287,
        [NAMES.UAV]: 2013,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5187
    },
    "Feb 18 2023": {
        [NAMES.TROOPS]: 142270,
        [NAMES.TANKS]: 3303,
        [NAMES.BMP_BTR]: 6533,
        [NAMES.ARTA]: 2326,
        [NAMES.PPO]: 243,
        [NAMES.JETS]: 298,
        [NAMES.HELI]: 287,
        [NAMES.UAV]: 2016,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5196
    },
    "Feb 19 2023": {
        [NAMES.TROOPS]: 142860,
        [NAMES.TANKS]: 3310,
        [NAMES.BMP_BTR]: 6545,
        [NAMES.ARTA]: 2327,
        [NAMES.PPO]: 243,
        [NAMES.JETS]: 298,
        [NAMES.HELI]: 287,
        [NAMES.UAV]: 2016,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5196
    },
    "Feb 20 2023": {
        [NAMES.TROOPS]: 143680,
        [NAMES.TANKS]: 3316,
        [NAMES.BMP_BTR]: 6553,
        [NAMES.ARTA]: 2334,
        [NAMES.PPO]: 243,
        [NAMES.JETS]: 299,
        [NAMES.HELI]: 287,
        [NAMES.UAV]: 2018,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5209
    },
    "Feb 21 2023": {
        [NAMES.TROOPS]: 144440,
        [NAMES.TANKS]: 3326,
        [NAMES.BMP_BTR]: 6562,
        [NAMES.ARTA]: 2338,
        [NAMES.PPO]: 243,
        [NAMES.JETS]: 299,
        [NAMES.HELI]: 287,
        [NAMES.UAV]: 2023,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5210
    },
    "Feb 22 2023": {
        [NAMES.TROOPS]: 145060,
        [NAMES.TANKS]: 3334,
        [NAMES.BMP_BTR]: 6569,
        [NAMES.ARTA]: 2345,
        [NAMES.PPO]: 243,
        [NAMES.JETS]: 299,
        [NAMES.HELI]: 287,
        [NAMES.UAV]: 2026,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5212
    },
    "Feb 23 2023": {
        [NAMES.TROOPS]: 145850,
        [NAMES.TANKS]: 3350,
        [NAMES.BMP_BTR]: 6593,
        [NAMES.ARTA]: 2352,
        [NAMES.PPO]: 244,
        [NAMES.JETS]: 299,
        [NAMES.HELI]: 287,
        [NAMES.UAV]: 2029,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5215
    },
    "Feb 24 2023": {
        [NAMES.TROOPS]: 146820,
        [NAMES.TANKS]: 3363,
        [NAMES.BMP_BTR]: 6600,
        [NAMES.ARTA]: 2363,
        [NAMES.PPO]: 247,
        [NAMES.JETS]: 299,
        [NAMES.HELI]: 287,
        [NAMES.UAV]: 2033,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5224
    },
    "Feb 25 2023": {
        [NAMES.TROOPS]: 147470,
        [NAMES.TANKS]: 3375,
        [NAMES.BMP_BTR]: 6609,
        [NAMES.ARTA]: 2373,
        [NAMES.PPO]: 247,
        [NAMES.JETS]: 299,
        [NAMES.HELI]: 288,
        [NAMES.UAV]: 2035,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5235
    },
    "Feb 26 2023": {
        [NAMES.TROOPS]: 148130,
        [NAMES.TANKS]: 3381,
        [NAMES.BMP_BTR]: 6615,
        [NAMES.ARTA]: 2380,
        [NAMES.PPO]: 247,
        [NAMES.JETS]: 299,
        [NAMES.HELI]: 288,
        [NAMES.UAV]: 2037,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5242
    },
    "Feb 27 2023": {
        [NAMES.TROOPS]: 148690,
        [NAMES.TANKS]: 3385,
        [NAMES.BMP_BTR]: 6621,
        [NAMES.ARTA]: 2380,
        [NAMES.PPO]: 247,
        [NAMES.JETS]: 300,
        [NAMES.HELI]: 288,
        [NAMES.UAV]: 2048,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5248
    },
    "Feb 28 2023": {
        [NAMES.TROOPS]: 149240,
        [NAMES.TANKS]: 3388,
        [NAMES.BMP_BTR]: 6630,
        [NAMES.ARTA]: 2383,
        [NAMES.PPO]: 247,
        [NAMES.JETS]: 300,
        [NAMES.HELI]: 288,
        [NAMES.UAV]: 2051,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5252
    },
    "Mar 01 2023": {
        [NAMES.TROOPS]: 149890,
        [NAMES.TANKS]: 3395,
        [NAMES.BMP_BTR]: 6638,
        [NAMES.ARTA]: 2393,
        [NAMES.PPO]: 247,
        [NAMES.JETS]: 300,
        [NAMES.HELI]: 288,
        [NAMES.UAV]: 2055,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5257
    },
    "Mar 02 2023": {
        [NAMES.TROOPS]: 150605,
        [NAMES.TANKS]: 3397,
        [NAMES.BMP_BTR]: 6658,
        [NAMES.ARTA]: 2398,
        [NAMES.PPO]: 247,
        [NAMES.JETS]: 300,
        [NAMES.HELI]: 288,
        [NAMES.UAV]: 2058,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5264
    },
    "Mar 03 2023": {
        [NAMES.TROOPS]: 151370,
        [NAMES.TANKS]: 3405,
        [NAMES.BMP_BTR]: 6673,
        [NAMES.ARTA]: 2402,
        [NAMES.PPO]: 247,
        [NAMES.JETS]: 301,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2061,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5281
    },
    "Mar 04 2023": {
        [NAMES.TROOPS]: 152190,
        [NAMES.TANKS]: 3409,
        [NAMES.BMP_BTR]: 6683,
        [NAMES.ARTA]: 2414,
        [NAMES.PPO]: 247,
        [NAMES.JETS]: 302,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2066,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5289
    },
    "Mar 05 2023": {
        [NAMES.TROOPS]: 153120,
        [NAMES.TANKS]: 3414,
        [NAMES.BMP_BTR]: 6692,
        [NAMES.ARTA]: 2426,
        [NAMES.PPO]: 248,
        [NAMES.JETS]: 302,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2071,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5299
    },
    "Mar 06 2023": {
        [NAMES.TROOPS]: 153770,
        [NAMES.TANKS]: 3423,
        [NAMES.BMP_BTR]: 6703,
        [NAMES.ARTA]: 2433,
        [NAMES.PPO]: 250,
        [NAMES.JETS]: 302,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2086,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5307
    },
    "Mar 07 2023": {
        [NAMES.TROOPS]: 154830,
        [NAMES.TANKS]: 3432,
        [NAMES.BMP_BTR]: 6714,
        [NAMES.ARTA]: 2456,
        [NAMES.PPO]: 253,
        [NAMES.JETS]: 303,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2095,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5323
    },
    "Mar 08 2023": {
        [NAMES.TROOPS]: 155530,
        [NAMES.TANKS]: 3436,
        [NAMES.BMP_BTR]: 6723,
        [NAMES.ARTA]: 2463,
        [NAMES.PPO]: 253,
        [NAMES.JETS]: 303,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2098,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5330
    },
    "Mar 09 2023": {
        [NAMES.TROOPS]: 156120,
        [NAMES.TANKS]: 3441,
        [NAMES.BMP_BTR]: 6736,
        [NAMES.ARTA]: 2465,
        [NAMES.PPO]: 254,
        [NAMES.JETS]: 303,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2098,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5331
    },
    "Mar 10 2023": {
        [NAMES.TROOPS]: 156990,
        [NAMES.TANKS]: 3448,
        [NAMES.BMP_BTR]: 6742,
        [NAMES.ARTA]: 2475,
        [NAMES.PPO]: 256,
        [NAMES.JETS]: 304,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2107,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5337
    },
    "Mar 11 2023": {
        [NAMES.TROOPS]: 158000,
        [NAMES.TANKS]: 3458,
        [NAMES.BMP_BTR]: 6762,
        [NAMES.ARTA]: 2483,
        [NAMES.PPO]: 257,
        [NAMES.JETS]: 304,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2108,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5344
    },
    "Mar 12 2023": {
        [NAMES.TROOPS]: 159090,
        [NAMES.TANKS]: 3466,
        [NAMES.BMP_BTR]: 6769,
        [NAMES.ARTA]: 2487,
        [NAMES.PPO]: 259,
        [NAMES.JETS]: 304,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2108,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5348
    },
    "Mar 13 2023": {
        [NAMES.TROOPS]: 159800,
        [NAMES.TANKS]: 3474,
        [NAMES.BMP_BTR]: 6774,
        [NAMES.ARTA]: 2503,
        [NAMES.PPO]: 259,
        [NAMES.JETS]: 304,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2109,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5354
    },
    "Mar 14 2023": {
        [NAMES.TROOPS]: 160540,
        [NAMES.TANKS]: 3484,
        [NAMES.BMP_BTR]: 6789,
        [NAMES.ARTA]: 2519,
        [NAMES.PPO]: 260,
        [NAMES.JETS]: 304,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2120,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5367
    },
    "Mar 15 2023": {
        [NAMES.TROOPS]: 161520,
        [NAMES.TANKS]: 3492,
        [NAMES.BMP_BTR]: 6799,
        [NAMES.ARTA]: 2528,
        [NAMES.PPO]: 262,
        [NAMES.JETS]: 304,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2132,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5377
    },
    "Mar 16 2023": {
        [NAMES.TROOPS]: 162560,
        [NAMES.TANKS]: 3504,
        [NAMES.BMP_BTR]: 6810,
        [NAMES.ARTA]: 2539,
        [NAMES.PPO]: 265,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 289,
        [NAMES.UAV]: 2145,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5394
    },
    "Mar 17 2023": {
        [NAMES.TROOPS]: 163320,
        [NAMES.TANKS]: 3506,
        [NAMES.BMP_BTR]: 6823,
        [NAMES.ARTA]: 2552,
        [NAMES.PPO]: 265,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 290,
        [NAMES.UAV]: 2145,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5401
    },
    "Mar 18 2023": {
        [NAMES.TROOPS]: 164200,
        [NAMES.TANKS]: 3511,
        [NAMES.BMP_BTR]: 6830,
        [NAMES.ARTA]: 2560,
        [NAMES.PPO]: 265,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 290,
        [NAMES.UAV]: 2159,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5404
    },
    "Mar 19 2023": {
        [NAMES.TROOPS]: 164910,
        [NAMES.TANKS]: 3532,
        [NAMES.BMP_BTR]: 6853,
        [NAMES.ARTA]: 2568,
        [NAMES.PPO]: 268,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 290,
        [NAMES.UAV]: 2159,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5408
    },
    "Mar 20 2023": {
        [NAMES.TROOPS]: 165610,
        [NAMES.TANKS]: 3537,
        [NAMES.BMP_BTR]: 6869,
        [NAMES.ARTA]: 2577,
        [NAMES.PPO]: 270,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 290,
        [NAMES.UAV]: 2160,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5416
    },
    "Mar 21 2023": {
        [NAMES.TROOPS]: 166570,
        [NAMES.TANKS]: 3552,
        [NAMES.BMP_BTR]: 6879,
        [NAMES.ARTA]: 2586,
        [NAMES.PPO]: 270,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 290,
        [NAMES.UAV]: 2167,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5428
    },
    "Mar 22 2023": {
        [NAMES.TROOPS]: 167490,
        [NAMES.TANKS]: 3557,
        [NAMES.BMP_BTR]: 6887,
        [NAMES.ARTA]: 2589,
        [NAMES.PPO]: 272,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 290,
        [NAMES.UAV]: 2183,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5434
    },
    "Mar 23 2023": {
        [NAMES.TROOPS]: 168150,
        [NAMES.TANKS]: 3570,
        [NAMES.BMP_BTR]: 6898,
        [NAMES.ARTA]: 2608,
        [NAMES.PPO]: 273,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 290,
        [NAMES.UAV]: 2203,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5452
    },
    "Mar 24 2023": {
        [NAMES.TROOPS]: 169170,
        [NAMES.TANKS]: 3574,
        [NAMES.BMP_BTR]: 6921,
        [NAMES.ARTA]: 2616,
        [NAMES.PPO]: 276,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 290,
        [NAMES.UAV]: 2208,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5464
    },
    "Mar 25 2023": {
        [NAMES.TROOPS]: 169890,
        [NAMES.TANKS]: 3580,
        [NAMES.BMP_BTR]: 6932,
        [NAMES.ARTA]: 2623,
        [NAMES.PPO]: 276,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 291,
        [NAMES.UAV]: 2214,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5483
    },
    "Mar 26 2023": {
        [NAMES.TROOPS]: 170550,
        [NAMES.TANKS]: 3595,
        [NAMES.BMP_BTR]: 6947,
        [NAMES.ARTA]: 2631,
        [NAMES.PPO]: 277,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 291,
        [NAMES.UAV]: 2216,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5486
    },
    "Mar 27 2023": {
        [NAMES.TROOPS]: 171160,
        [NAMES.TANKS]: 3595,
        [NAMES.BMP_BTR]: 6953,
        [NAMES.ARTA]: 2638,
        [NAMES.PPO]: 277,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 291,
        [NAMES.UAV]: 2216,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5493
    },
    "Mar 28 2023": {
        [NAMES.TROOPS]: 171730,
        [NAMES.TANKS]: 3602,
        [NAMES.BMP_BTR]: 6966,
        [NAMES.ARTA]: 2653,
        [NAMES.PPO]: 277,
        [NAMES.JETS]: 305,
        [NAMES.HELI]: 291,
        [NAMES.UAV]: 2235,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5502
    },
    "Mar 29 2023": {
        [NAMES.TROOPS]: 172340,
        [NAMES.TANKS]: 3609,
        [NAMES.BMP_BTR]: 6966,
        [NAMES.ARTA]: 2659,
        [NAMES.PPO]: 277,
        [NAMES.JETS]: 306,
        [NAMES.HELI]: 291,
        [NAMES.UAV]: 2239,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5507
    },
    "Mar 30 2023": {
        [NAMES.TROOPS]: 172900,
        [NAMES.TANKS]: 3610,
        [NAMES.BMP_BTR]: 6974,
        [NAMES.ARTA]: 2671,
        [NAMES.PPO]: 278,
        [NAMES.JETS]: 306,
        [NAMES.HELI]: 291,
        [NAMES.UAV]: 2239,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5518
    },
    "Mar 31 2023": {
        [NAMES.TROOPS]: 173360,
        [NAMES.TANKS]: 3615,
        [NAMES.BMP_BTR]: 6977,
        [NAMES.ARTA]: 2675,
        [NAMES.PPO]: 278,
        [NAMES.JETS]: 306,
        [NAMES.HELI]: 291,
        [NAMES.UAV]: 2248,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5521
    },
    "Apr 01 2023": {
        [NAMES.TROOPS]: 173990,
        [NAMES.TANKS]: 3616,
        [NAMES.BMP_BTR]: 6981,
        [NAMES.ARTA]: 2683,
        [NAMES.PPO]: 279,
        [NAMES.JETS]: 306,
        [NAMES.HELI]: 291,
        [NAMES.UAV]: 2248,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5528
    },
    "Apr 02 2023": {
        [NAMES.TROOPS]: 174550,
        [NAMES.TANKS]: 3618,
        [NAMES.BMP_BTR]: 6986,
        [NAMES.ARTA]: 2687,
        [NAMES.PPO]: 279,
        [NAMES.JETS]: 306,
        [NAMES.HELI]: 291,
        [NAMES.UAV]: 2249,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5537
    },
    "Apr 03 2023": {
        [NAMES.TROOPS]: 175160,
        [NAMES.TANKS]: 3619,
        [NAMES.BMP_BTR]: 6993,
        [NAMES.ARTA]: 2694,
        [NAMES.PPO]: 280,
        [NAMES.JETS]: 306,
        [NAMES.HELI]: 291,
        [NAMES.UAV]: 2262,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5553
    },
    "Apr 04 2023": {
        [NAMES.TROOPS]: 175690,
        [NAMES.TANKS]: 3627,
        [NAMES.BMP_BTR]: 6999,
        [NAMES.ARTA]: 2697,
        [NAMES.PPO]: 280,
        [NAMES.JETS]: 306,
        [NAMES.HELI]: 291,
        [NAMES.UAV]: 2277,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5562
    },
    "Apr 05 2023": {
        [NAMES.TROOPS]: 176240,
        [NAMES.TANKS]: 3629,
        [NAMES.BMP_BTR]: 7005,
        [NAMES.ARTA]: 2707,
        [NAMES.PPO]: 280,
        [NAMES.JETS]: 306,
        [NAMES.HELI]: 291,
        [NAMES.UAV]: 2283,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5573
    },
    "Apr 06 2023": {
        [NAMES.TROOPS]: 176630,
        [NAMES.TANKS]: 3631,
        [NAMES.BMP_BTR]: 7013,
        [NAMES.ARTA]: 2714,
        [NAMES.PPO]: 281,
        [NAMES.JETS]: 306,
        [NAMES.HELI]: 292,
        [NAMES.UAV]: 2287,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5574
    },
    "Apr 07 2023": {
        [NAMES.TROOPS]: 177110,
        [NAMES.TANKS]: 3633,
        [NAMES.BMP_BTR]: 7016,
        [NAMES.ARTA]: 2722,
        [NAMES.PPO]: 281,
        [NAMES.JETS]: 306,
        [NAMES.HELI]: 292,
        [NAMES.UAV]: 2291,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5584
    },
    "Apr 08 2023": {
        [NAMES.TROOPS]: 177680,
        [NAMES.TANKS]: 3636,
        [NAMES.BMP_BTR]: 7020,
        [NAMES.ARTA]: 2727,
        [NAMES.PPO]: 282,
        [NAMES.JETS]: 307,
        [NAMES.HELI]: 292,
        [NAMES.UAV]: 2298,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5599
    },
    "Apr 09 2023": {
        [NAMES.TROOPS]: 178150,
        [NAMES.TANKS]: 3636,
        [NAMES.BMP_BTR]: 7024,
        [NAMES.ARTA]: 2740,
        [NAMES.PPO]: 282,
        [NAMES.JETS]: 307,
        [NAMES.HELI]: 292,
        [NAMES.UAV]: 2312,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5602
    },
    "Apr 10 2023": {
        [NAMES.TROOPS]: 178820,
        [NAMES.TANKS]: 3637,
        [NAMES.BMP_BTR]: 7028,
        [NAMES.ARTA]: 2750,
        [NAMES.PPO]: 282,
        [NAMES.JETS]: 307,
        [NAMES.HELI]: 292,
        [NAMES.UAV]: 2323,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5607
    },
    "Apr 11 2023": {
        [NAMES.TROOPS]: 179320,
        [NAMES.TANKS]: 3644,
        [NAMES.BMP_BTR]: 7038,
        [NAMES.ARTA]: 2765,
        [NAMES.PPO]: 282,
        [NAMES.JETS]: 307,
        [NAMES.HELI]: 293,
        [NAMES.UAV]: 2332,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5620
    },
    "Apr 12 2023": {
        [NAMES.TROOPS]: 180050,
        [NAMES.TANKS]: 3646,
        [NAMES.BMP_BTR]: 7043,
        [NAMES.ARTA]: 2770,
        [NAMES.PPO]: 282,
        [NAMES.JETS]: 307,
        [NAMES.HELI]: 293,
        [NAMES.UAV]: 2334,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5630
    },
    "Apr 13 2023": {
        [NAMES.TROOPS]: 180590,
        [NAMES.TANKS]: 3646,
        [NAMES.BMP_BTR]: 7053,
        [NAMES.ARTA]: 2777,
        [NAMES.PPO]: 283,
        [NAMES.JETS]: 307,
        [NAMES.HELI]: 293,
        [NAMES.UAV]: 2339,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5637
    },
    "Apr 14 2023": {
        [NAMES.TROOPS]: 181090,
        [NAMES.TANKS]: 3650,
        [NAMES.BMP_BTR]: 7069,
        [NAMES.ARTA]: 2784,
        [NAMES.PPO]: 283,
        [NAMES.JETS]: 307,
        [NAMES.HELI]: 293,
        [NAMES.UAV]: 2339,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5640
    },
    "Apr 15 2023": {
        [NAMES.TROOPS]: 181550,
        [NAMES.TANKS]: 3653,
        [NAMES.BMP_BTR]: 7073,
        [NAMES.ARTA]: 2785,
        [NAMES.PPO]: 283,
        [NAMES.JETS]: 307,
        [NAMES.HELI]: 293,
        [NAMES.UAV]: 2339,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5646
    },
    "Apr 16 2023": {
        [NAMES.TROOPS]: 182070,
        [NAMES.TANKS]: 3657,
        [NAMES.BMP_BTR]: 7083,
        [NAMES.ARTA]: 2795,
        [NAMES.PPO]: 284,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 293,
        [NAMES.UAV]: 2339,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5658
    },
    "Apr 17 2023": {
        [NAMES.TROOPS]: 182660,
        [NAMES.TANKS]: 3660,
        [NAMES.BMP_BTR]: 7087,
        [NAMES.ARTA]: 2804,
        [NAMES.PPO]: 285,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 293,
        [NAMES.UAV]: 2347,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5672
    },
    "Apr 18 2023": {
        [NAMES.TROOPS]: 183130,
        [NAMES.TANKS]: 3661,
        [NAMES.BMP_BTR]: 7098,
        [NAMES.ARTA]: 2810,
        [NAMES.PPO]: 285,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 293,
        [NAMES.UAV]: 2353,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5676
    },
    "Apr 19 2023": {
        [NAMES.TROOPS]: 183750,
        [NAMES.TANKS]: 3665,
        [NAMES.BMP_BTR]: 7110,
        [NAMES.ARTA]: 2819,
        [NAMES.PPO]: 285,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 293,
        [NAMES.UAV]: 2376,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5692
    },
    "Apr 20 2023": {
        [NAMES.TROOPS]: 184420,
        [NAMES.TANKS]: 3667,
        [NAMES.BMP_BTR]: 7120,
        [NAMES.ARTA]: 2825,
        [NAMES.PPO]: 285,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 293,
        [NAMES.UAV]: 2386,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5707
    },
    "Apr 21 2023": {
        [NAMES.TROOPS]: 185050,
        [NAMES.TANKS]: 3668,
        [NAMES.BMP_BTR]: 7126,
        [NAMES.ARTA]: 2827,
        [NAMES.PPO]: 285,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 293,
        [NAMES.UAV]: 2394,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5713
    },
    "Apr 22 2023": {
        [NAMES.TROOPS]: 185730,
        [NAMES.TANKS]: 3672,
        [NAMES.BMP_BTR]: 7130,
        [NAMES.ARTA]: 2832,
        [NAMES.PPO]: 289,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 293,
        [NAMES.UAV]: 2398,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5718
    },
    "Apr 23 2023": {
        [NAMES.TROOPS]: 186420,
        [NAMES.TANKS]: 3675,
        [NAMES.BMP_BTR]: 7131,
        [NAMES.ARTA]: 2837,
        [NAMES.PPO]: 289,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2402,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5730
    },
    "Apr 24 2023": {
        [NAMES.TROOPS]: 187080,
        [NAMES.TANKS]: 3683,
        [NAMES.BMP_BTR]: 7139,
        [NAMES.ARTA]: 2849,
        [NAMES.PPO]: 289,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2413,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5753
    },
    "Apr 25 2023": {
        [NAMES.TROOPS]: 187770,
        [NAMES.TANKS]: 3688,
        [NAMES.BMP_BTR]: 7151,
        [NAMES.ARTA]: 2863,
        [NAMES.PPO]: 291,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2437,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5784
    },
    "Apr 26 2023": {
        [NAMES.TROOPS]: 188410,
        [NAMES.TANKS]: 3692,
        [NAMES.BMP_BTR]: 7162,
        [NAMES.ARTA]: 2877,
        [NAMES.PPO]: 292,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2440,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5792
    },
    "Apr 27 2023": {
        [NAMES.TROOPS]: 188920,
        [NAMES.TANKS]: 3694,
        [NAMES.BMP_BTR]: 7178,
        [NAMES.ARTA]: 2887,
        [NAMES.PPO]: 293,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2461,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5805
    },
    "Apr 28 2023": {
        [NAMES.TROOPS]: 189460,
        [NAMES.TANKS]: 3694,
        [NAMES.BMP_BTR]: 7181,
        [NAMES.ARTA]: 2905,
        [NAMES.PPO]: 294,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2467,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5819
    },
    "Apr 29 2023": {
        [NAMES.TROOPS]: 190040,
        [NAMES.TANKS]: 3697,
        [NAMES.BMP_BTR]: 7184,
        [NAMES.ARTA]: 2908,
        [NAMES.PPO]: 294,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2471,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5836
    },
    "Apr 30 2023": {
        [NAMES.TROOPS]: 190510,
        [NAMES.TANKS]: 3699,
        [NAMES.BMP_BTR]: 7189,
        [NAMES.ARTA]: 2914,
        [NAMES.PPO]: 295,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2475,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5842
    },
    "May 01 2023": {
        [NAMES.TROOPS]: 190960,
        [NAMES.TANKS]: 3700,
        [NAMES.BMP_BTR]: 7192,
        [NAMES.ARTA]: 2921,
        [NAMES.PPO]: 295,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2476,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5845
    },
    "May 02 2023": {
        [NAMES.TROOPS]: 191420,
        [NAMES.TANKS]: 3701,
        [NAMES.BMP_BTR]: 7193,
        [NAMES.ARTA]: 2930,
        [NAMES.PPO]: 298,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2477,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5851
    },
    "May 03 2023": {
        [NAMES.TROOPS]: 191940,
        [NAMES.TANKS]: 3702,
        [NAMES.BMP_BTR]: 7199,
        [NAMES.ARTA]: 2946,
        [NAMES.PPO]: 300,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2505,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5865
    },
    "May 04 2023": {
        [NAMES.TROOPS]: 192590,
        [NAMES.TANKS]: 3707,
        [NAMES.BMP_BTR]: 7216,
        [NAMES.ARTA]: 2962,
        [NAMES.PPO]: 304,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2523,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5886
    },
    "May 05 2023": {
        [NAMES.TROOPS]: 193210,
        [NAMES.TANKS]: 3710,
        [NAMES.BMP_BTR]: 7224,
        [NAMES.ARTA]: 2978,
        [NAMES.PPO]: 304,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2540,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5916
    },
    "May 06 2023": {
        [NAMES.TROOPS]: 193770,
        [NAMES.TANKS]: 3717,
        [NAMES.BMP_BTR]: 7238,
        [NAMES.ARTA]: 2992,
        [NAMES.PPO]: 306,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2554,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5936
    },
    "May 07 2023": {
        [NAMES.TROOPS]: 194430,
        [NAMES.TANKS]: 3723,
        [NAMES.BMP_BTR]: 7248,
        [NAMES.ARTA]: 3010,
        [NAMES.PPO]: 306,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2572,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5952
    },
    "May 08 2023": {
        [NAMES.TROOPS]: 194970,
        [NAMES.TANKS]: 3730,
        [NAMES.BMP_BTR]: 7253,
        [NAMES.ARTA]: 3018,
        [NAMES.PPO]: 306,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2614,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5958
    },
    "May 09 2023": {
        [NAMES.TROOPS]: 195620,
        [NAMES.TANKS]: 3734,
        [NAMES.BMP_BTR]: 7257,
        [NAMES.ARTA]: 3031,
        [NAMES.PPO]: 307,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2618,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5969
    },
    "May 10 2023": {
        [NAMES.TROOPS]: 196310,
        [NAMES.TANKS]: 3736,
        [NAMES.BMP_BTR]: 7275,
        [NAMES.ARTA]: 3039,
        [NAMES.PPO]: 308,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2624,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5974
    },
    "May 11 2023": {
        [NAMES.TROOPS]: 196920,
        [NAMES.TANKS]: 3740,
        [NAMES.BMP_BTR]: 7287,
        [NAMES.ARTA]: 3053,
        [NAMES.PPO]: 310,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2627,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5984
    },
    "May 12 2023": {
        [NAMES.TROOPS]: 197670,
        [NAMES.TANKS]: 3745,
        [NAMES.BMP_BTR]: 7295,
        [NAMES.ARTA]: 3068,
        [NAMES.PPO]: 312,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2636,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 5996
    },
    "May 13 2023": {
        [NAMES.TROOPS]: 198260,
        [NAMES.TANKS]: 3751,
        [NAMES.BMP_BTR]: 7307,
        [NAMES.ARTA]: 3095,
        [NAMES.PPO]: 314,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2665,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6011
    },
    "May 14 2023": {
        [NAMES.TROOPS]: 198880,
        [NAMES.TANKS]: 3756,
        [NAMES.BMP_BTR]: 7325,
        [NAMES.ARTA]: 3116,
        [NAMES.PPO]: 314,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2696,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6018
    },
    "May 15 2023": {
        [NAMES.TROOPS]: 199460,
        [NAMES.TANKS]: 3759,
        [NAMES.BMP_BTR]: 7336,
        [NAMES.ARTA]: 3137,
        [NAMES.PPO]: 316,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2720,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6034
    },
    "May 16 2023": {
        [NAMES.TROOPS]: 199980,
        [NAMES.TANKS]: 3762,
        [NAMES.BMP_BTR]: 7348,
        [NAMES.ARTA]: 3150,
        [NAMES.PPO]: 316,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2732,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6048
    },
    "May 17 2023": {
        [NAMES.TROOPS]: 200590,
        [NAMES.TANKS]: 3771,
        [NAMES.BMP_BTR]: 7365,
        [NAMES.ARTA]: 3166,
        [NAMES.PPO]: 318,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2748,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6067
    },
    "May 18 2023": {
        [NAMES.TROOPS]: 201100,
        [NAMES.TANKS]: 3773,
        [NAMES.BMP_BTR]: 7373,
        [NAMES.ARTA]: 3198,
        [NAMES.PPO]: 318,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2759,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6073
    },
    "May 19 2023": {
        [NAMES.TROOPS]: 201760,
        [NAMES.TANKS]: 3777,
        [NAMES.BMP_BTR]: 7377,
        [NAMES.ARTA]: 3210,
        [NAMES.PPO]: 319,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2769,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6083
    },
    "May 20 2023": {
        [NAMES.TROOPS]: 202430,
        [NAMES.TANKS]: 3781,
        [NAMES.BMP_BTR]: 7382,
        [NAMES.ARTA]: 3229,
        [NAMES.PPO]: 325,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2801,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6103
    },
    "May 21 2023": {
        [NAMES.TROOPS]: 203160,
        [NAMES.TANKS]: 3783,
        [NAMES.BMP_BTR]: 7398,
        [NAMES.ARTA]: 3258,
        [NAMES.PPO]: 327,
        [NAMES.JETS]: 308,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2822,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6115
    },
    "May 22 2023": {
        [NAMES.TROOPS]: 203880,
        [NAMES.TANKS]: 3785,
        [NAMES.BMP_BTR]: 7407,
        [NAMES.ARTA]: 3278,
        [NAMES.PPO]: 327,
        [NAMES.JETS]: 309,
        [NAMES.HELI]: 294,
        [NAMES.UAV]: 2830,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6129
    },
    "May 23 2023": {
        [NAMES.TROOPS]: 204360,
        [NAMES.TANKS]: 3789,
        [NAMES.BMP_BTR]: 7419,
        [NAMES.ARTA]: 3318,
        [NAMES.PPO]: 327,
        [NAMES.JETS]: 309,
        [NAMES.HELI]: 295,
        [NAMES.UAV]: 2864,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6139
    },
    "May 24 2023": {
        [NAMES.TROOPS]: 204760,
        [NAMES.TANKS]: 3792,
        [NAMES.BMP_BTR]: 7424,
        [NAMES.ARTA]: 3339,
        [NAMES.PPO]: 327,
        [NAMES.JETS]: 309,
        [NAMES.HELI]: 296,
        [NAMES.UAV]: 2871,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6146
    },
    "May 25 2023": {
        [NAMES.TROOPS]: 205260,
        [NAMES.TANKS]: 3795,
        [NAMES.BMP_BTR]: 7432,
        [NAMES.ARTA]: 3359,
        [NAMES.PPO]: 327,
        [NAMES.JETS]: 309,
        [NAMES.HELI]: 296,
        [NAMES.UAV]: 2907,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6148
    },
    "May 26 2023": {
        [NAMES.TROOPS]: 205720,
        [NAMES.TANKS]: 3793,
        [NAMES.BMP_BTR]: 7435,
        [NAMES.ARTA]: 3384,
        [NAMES.PPO]: 328,
        [NAMES.JETS]: 310,
        [NAMES.HELI]: 296,
        [NAMES.UAV]: 2910,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6161
    },
    "May 27 2023": {
        [NAMES.TROOPS]: 206200,
        [NAMES.TANKS]: 3794,
        [NAMES.BMP_BTR]: 7449,
        [NAMES.ARTA]: 3414,
        [NAMES.PPO]: 328,
        [NAMES.JETS]: 313,
        [NAMES.HELI]: 298,
        [NAMES.UAV]: 2990,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6183
    },
    "May 28 2023": {
        [NAMES.TROOPS]: 206600,
        [NAMES.TANKS]: 3797,
        [NAMES.BMP_BTR]: 7456,
        [NAMES.ARTA]: 3425,
        [NAMES.PPO]: 328,
        [NAMES.JETS]: 313,
        [NAMES.HELI]: 298,
        [NAMES.UAV]: 2993,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6192
    },
    "May 29 2023": {
        [NAMES.TROOPS]: 207030,
        [NAMES.TANKS]: 3801,
        [NAMES.BMP_BTR]: 7467,
        [NAMES.ARTA]: 3435,
        [NAMES.PPO]: 331,
        [NAMES.JETS]: 313,
        [NAMES.HELI]: 298,
        [NAMES.UAV]: 3054,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6207
    },
    "May 30 2023": {
        [NAMES.TROOPS]: 207440,
        [NAMES.TANKS]: 3802,
        [NAMES.BMP_BTR]: 7469,
        [NAMES.ARTA]: 3445,
        [NAMES.PPO]: 332,
        [NAMES.JETS]: 313,
        [NAMES.HELI]: 298,
        [NAMES.UAV]: 3092,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6219
    },
    "May 31 2023": {
        [NAMES.TROOPS]: 207910,
        [NAMES.TANKS]: 3802,
        [NAMES.BMP_BTR]: 7472,
        [NAMES.ARTA]: 3460,
        [NAMES.PPO]: 333,
        [NAMES.JETS]: 313,
        [NAMES.HELI]: 298,
        [NAMES.UAV]: 3124,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6230
    },
    "Jun 01 2023": {
        [NAMES.TROOPS]: 208370,
        [NAMES.TANKS]: 3804,
        [NAMES.BMP_BTR]: 7478,
        [NAMES.ARTA]: 3474,
        [NAMES.PPO]: 333,
        [NAMES.JETS]: 313,
        [NAMES.HELI]: 298,
        [NAMES.UAV]: 3131,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6239
    },
    "Jun 02 2023": {
        [NAMES.TROOPS]: 208910,
        [NAMES.TANKS]: 3819,
        [NAMES.BMP_BTR]: 7490,
        [NAMES.ARTA]: 3501,
        [NAMES.PPO]: 335,
        [NAMES.JETS]: 313,
        [NAMES.HELI]: 298,
        [NAMES.UAV]: 3137,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6267
    },
    "Jun 03 2023": {
        [NAMES.TROOPS]: 209470,
        [NAMES.TANKS]: 3829,
        [NAMES.BMP_BTR]: 7502,
        [NAMES.ARTA]: 3533,
        [NAMES.PPO]: 342,
        [NAMES.JETS]: 313,
        [NAMES.HELI]: 298,
        [NAMES.UAV]: 3165,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6289
    },
    "Jun 04 2023": {
        [NAMES.TROOPS]: 209940,
        [NAMES.TANKS]: 3837,
        [NAMES.BMP_BTR]: 7512,
        [NAMES.ARTA]: 3555,
        [NAMES.PPO]: 344,
        [NAMES.JETS]: 313,
        [NAMES.HELI]: 298,
        [NAMES.UAV]: 3175,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6305
    },
    "Jun 05 2023": {
        [NAMES.TROOPS]: 210350,
        [NAMES.TANKS]: 3848,
        [NAMES.BMP_BTR]: 7523,
        [NAMES.ARTA]: 3567,
        [NAMES.PPO]: 349,
        [NAMES.JETS]: 313,
        [NAMES.HELI]: 298,
        [NAMES.UAV]: 3189,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6312
    },
    "Jun 06 2023": {
        [NAMES.TROOPS]: 211150,
        [NAMES.TANKS]: 3860,
        [NAMES.BMP_BTR]: 7543,
        [NAMES.ARTA]: 3603,
        [NAMES.PPO]: 351,
        [NAMES.JETS]: 313,
        [NAMES.HELI]: 299,
        [NAMES.UAV]: 3212,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6332
    },
    "Jun 07 2023": {
        [NAMES.TROOPS]: 212030,
        [NAMES.TANKS]: 3873,
        [NAMES.BMP_BTR]: 7560,
        [NAMES.ARTA]: 3640,
        [NAMES.PPO]: 352,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 299,
        [NAMES.UAV]: 3219,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6349
    },
    "Jun 08 2023": {
        [NAMES.TROOPS]: 212760,
        [NAMES.TANKS]: 3891,
        [NAMES.BMP_BTR]: 7576,
        [NAMES.ARTA]: 3668,
        [NAMES.PPO]: 355,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 299,
        [NAMES.UAV]: 3234,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6384
    },
    "Jun 09 2023": {
        [NAMES.TROOPS]: 213770,
        [NAMES.TANKS]: 3901,
        [NAMES.BMP_BTR]: 7600,
        [NAMES.ARTA]: 3702,
        [NAMES.PPO]: 359,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 299,
        [NAMES.UAV]: 3247,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6410
    },
    "Jun 10 2023": {
        [NAMES.TROOPS]: 214660,
        [NAMES.TANKS]: 3909,
        [NAMES.BMP_BTR]: 7607,
        [NAMES.ARTA]: 3717,
        [NAMES.PPO]: 362,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 299,
        [NAMES.UAV]: 3263,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6412
    },
    "Jun 11 2023": {
        [NAMES.TROOPS]: 215640,
        [NAMES.TANKS]: 3926,
        [NAMES.BMP_BTR]: 7631,
        [NAMES.ARTA]: 3736,
        [NAMES.PPO]: 362,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 299,
        [NAMES.UAV]: 3300,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6461
    },
    "Jun 12 2023": {
        [NAMES.TROOPS]: 216180,
        [NAMES.TANKS]: 3931,
        [NAMES.BMP_BTR]: 7636,
        [NAMES.ARTA]: 3746,
        [NAMES.PPO]: 362,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 299,
        [NAMES.UAV]: 3307,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6471
    },
    "Jun 13 2023": {
        [NAMES.TROOPS]: 216650,
        [NAMES.TANKS]: 3935,
        [NAMES.BMP_BTR]: 7642,
        [NAMES.ARTA]: 3766,
        [NAMES.PPO]: 363,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 300,
        [NAMES.UAV]: 3309,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6473
    },
    "Jun 14 2023": {
        [NAMES.TROOPS]: 217330,
        [NAMES.TANKS]: 3943,
        [NAMES.BMP_BTR]: 7653,
        [NAMES.ARTA]: 3783,
        [NAMES.PPO]: 364,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 300,
        [NAMES.UAV]: 3324,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6502
    },
    "Jun 15 2023": {
        [NAMES.TROOPS]: 217910,
        [NAMES.TANKS]: 3955,
        [NAMES.BMP_BTR]: 7667,
        [NAMES.ARTA]: 3793,
        [NAMES.PPO]: 364,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 301,
        [NAMES.UAV]: 3333,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6506
    },
    "Jun 16 2023": {
        [NAMES.TROOPS]: 218500,
        [NAMES.TANKS]: 3965,
        [NAMES.BMP_BTR]: 7683,
        [NAMES.ARTA]: 3812,
        [NAMES.PPO]: 364,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 301,
        [NAMES.UAV]: 3357,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6519
    },
    "Jun 17 2023": {
        [NAMES.TROOPS]: 219170,
        [NAMES.TANKS]: 3977,
        [NAMES.BMP_BTR]: 7706,
        [NAMES.ARTA]: 3834,
        [NAMES.PPO]: 364,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 302,
        [NAMES.UAV]: 3364,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6557
    },
    "Jun 18 2023": {
        [NAMES.TROOPS]: 219820,
        [NAMES.TANKS]: 3984,
        [NAMES.BMP_BTR]: 7729,
        [NAMES.ARTA]: 3847,
        [NAMES.PPO]: 364,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 304,
        [NAMES.UAV]: 3371,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6571
    },
    "Jun 19 2023": {
        [NAMES.TROOPS]: 220450,
        [NAMES.TANKS]: 3989,
        [NAMES.BMP_BTR]: 7735,
        [NAMES.ARTA]: 3865,
        [NAMES.PPO]: 370,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 305,
        [NAMES.UAV]: 3383,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6613
    },
    "Jun 20 2023": {
        [NAMES.TROOPS]: 221460,
        [NAMES.TANKS]: 3997,
        [NAMES.BMP_BTR]: 7750,
        [NAMES.ARTA]: 3888,
        [NAMES.PPO]: 372,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 306,
        [NAMES.UAV]: 3393,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6645
    },
    "Jun 21 2023": {
        [NAMES.TROOPS]: 222000,
        [NAMES.TANKS]: 4006,
        [NAMES.BMP_BTR]: 7771,
        [NAMES.ARTA]: 3920,
        [NAMES.PPO]: 375,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 306,
        [NAMES.UAV]: 3428,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6667
    },
    "Jun 22 2023": {
        [NAMES.TROOPS]: 222650,
        [NAMES.TANKS]: 4013,
        [NAMES.BMP_BTR]: 7783,
        [NAMES.ARTA]: 3941,
        [NAMES.PPO]: 376,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 307,
        [NAMES.UAV]: 3438,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6678
    },
    "Jun 23 2023": {
        [NAMES.TROOPS]: 223330,
        [NAMES.TANKS]: 4017,
        [NAMES.BMP_BTR]: 7798,
        [NAMES.ARTA]: 3985,
        [NAMES.PPO]: 379,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 307,
        [NAMES.UAV]: 3447,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6708
    },
    "Jun 24 2023": {
        [NAMES.TROOPS]: 223910,
        [NAMES.TANKS]: 4024,
        [NAMES.BMP_BTR]: 7804,
        [NAMES.ARTA]: 4015,
        [NAMES.PPO]: 383,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 308,
        [NAMES.UAV]: 3460,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6731
    },
    "Jun 25 2023": {
        [NAMES.TROOPS]: 224630,
        [NAMES.TANKS]: 4030,
        [NAMES.BMP_BTR]: 7806,
        [NAMES.ARTA]: 4034,
        [NAMES.PPO]: 385,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 308,
        [NAMES.UAV]: 3472,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6735
    },
    "Jun 26 2023": {
        [NAMES.TROOPS]: 225580,
        [NAMES.TANKS]: 4031,
        [NAMES.BMP_BTR]: 7820,
        [NAMES.ARTA]: 4055,
        [NAMES.PPO]: 385,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 308,
        [NAMES.UAV]: 3482,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6751
    },
    "Jun 27 2023": {
        [NAMES.TROOPS]: 226170,
        [NAMES.TANKS]: 4036,
        [NAMES.BMP_BTR]: 7834,
        [NAMES.ARTA]: 4083,
        [NAMES.PPO]: 386,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 308,
        [NAMES.UAV]: 3492,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6772
    },
    "Jun 28 2023": {
        [NAMES.TROOPS]: 227100,
        [NAMES.TANKS]: 4036,
        [NAMES.BMP_BTR]: 7847,
        [NAMES.ARTA]: 4089,
        [NAMES.PPO]: 387,
        [NAMES.JETS]: 314,
        [NAMES.HELI]: 308,
        [NAMES.UAV]: 3499,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6774
    },
    "Jun 29 2023": {
        [NAMES.TROOPS]: 227780,
        [NAMES.TANKS]: 4038,
        [NAMES.BMP_BTR]: 7857,
        [NAMES.ARTA]: 4116,
        [NAMES.PPO]: 388,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 308,
        [NAMES.UAV]: 3513,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6780
    },
    "Jun 30 2023": {
        [NAMES.TROOPS]: 228340,
        [NAMES.TANKS]: 4041,
        [NAMES.BMP_BTR]: 7863,
        [NAMES.ARTA]: 4127,
        [NAMES.PPO]: 389,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 308,
        [NAMES.UAV]: 3519,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6785
    },
    "Jul 01 2023": {
        [NAMES.TROOPS]: 228870,
        [NAMES.TANKS]: 4042,
        [NAMES.BMP_BTR]: 7868,
        [NAMES.ARTA]: 4162,
        [NAMES.PPO]: 389,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 308,
        [NAMES.UAV]: 3545,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6794
    },
    "Jul 02 2023": {
        [NAMES.TROOPS]: 229660,
        [NAMES.TANKS]: 4052,
        [NAMES.BMP_BTR]: 7888,
        [NAMES.ARTA]: 4188,
        [NAMES.PPO]: 390,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 308,
        [NAMES.UAV]: 3557,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6816
    },
    "Jul 03 2023": {
        [NAMES.TROOPS]: 230260,
        [NAMES.TANKS]: 4057,
        [NAMES.BMP_BTR]: 7899,
        [NAMES.ARTA]: 4220,
        [NAMES.PPO]: 391,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 309,
        [NAMES.UAV]: 3573,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6834
    },
    "Jul 04 2023": {
        [NAMES.TROOPS]: 231030,
        [NAMES.TANKS]: 4059,
        [NAMES.BMP_BTR]: 7908,
        [NAMES.ARTA]: 4252,
        [NAMES.PPO]: 394,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 309,
        [NAMES.UAV]: 3602,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6843
    },
    "Jul 05 2023": {
        [NAMES.TROOPS]: 231700,
        [NAMES.TANKS]: 4062,
        [NAMES.BMP_BTR]: 7917,
        [NAMES.ARTA]: 4288,
        [NAMES.PPO]: 395,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 309,
        [NAMES.UAV]: 3614,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6865
    },
    "Jul 06 2023": {
        [NAMES.TROOPS]: 232300,
        [NAMES.TANKS]: 4068,
        [NAMES.BMP_BTR]: 7932,
        [NAMES.ARTA]: 4310,
        [NAMES.PPO]: 404,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 309,
        [NAMES.UAV]: 3635,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6888
    },
    "Jul 07 2023": {
        [NAMES.TROOPS]: 232810,
        [NAMES.TANKS]: 4070,
        [NAMES.BMP_BTR]: 7944,
        [NAMES.ARTA]: 4330,
        [NAMES.PPO]: 408,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 309,
        [NAMES.UAV]: 3652,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6898
    },
    "Jul 08 2023": {
        [NAMES.TROOPS]: 233440,
        [NAMES.TANKS]: 4074,
        [NAMES.BMP_BTR]: 7953,
        [NAMES.ARTA]: 4346,
        [NAMES.PPO]: 410,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 309,
        [NAMES.UAV]: 3666,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6914
    },
    "Jul 09 2023": {
        [NAMES.TROOPS]: 234040,
        [NAMES.TANKS]: 4078,
        [NAMES.BMP_BTR]: 7964,
        [NAMES.ARTA]: 4366,
        [NAMES.PPO]: 413,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 309,
        [NAMES.UAV]: 3685,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6929
    },
    "Jul 10 2023": {
        [NAMES.TROOPS]: 234480,
        [NAMES.TANKS]: 4085,
        [NAMES.BMP_BTR]: 7966,
        [NAMES.ARTA]: 4371,
        [NAMES.PPO]: 414,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 309,
        [NAMES.UAV]: 3686,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6937
    },
    "Jul 11 2023": {
        [NAMES.TROOPS]: 235020,
        [NAMES.TANKS]: 4089,
        [NAMES.BMP_BTR]: 7983,
        [NAMES.ARTA]: 4385,
        [NAMES.PPO]: 414,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3693,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6966
    },
    "Jul 12 2023": {
        [NAMES.TROOPS]: 235530,
        [NAMES.TANKS]: 4090,
        [NAMES.BMP_BTR]: 7990,
        [NAMES.ARTA]: 4402,
        [NAMES.PPO]: 415,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3726,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6978
    },
    "Jul 13 2023": {
        [NAMES.TROOPS]: 236040,
        [NAMES.TANKS]: 4092,
        [NAMES.BMP_BTR]: 7999,
        [NAMES.ARTA]: 4425,
        [NAMES.PPO]: 421,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3752,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 6995
    },
    "Jul 14 2023": {
        [NAMES.TROOPS]: 236590,
        [NAMES.TANKS]: 4097,
        [NAMES.BMP_BTR]: 8008,
        [NAMES.ARTA]: 4449,
        [NAMES.PPO]: 423,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3783,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7019
    },
    "Jul 15 2023": {
        [NAMES.TROOPS]: 237180,
        [NAMES.TANKS]: 4102,
        [NAMES.BMP_BTR]: 8019,
        [NAMES.ARTA]: 4463,
        [NAMES.PPO]: 425,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3807,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7036
    },
    "Jul 16 2023": {
        [NAMES.TROOPS]: 237680,
        [NAMES.TANKS]: 4107,
        [NAMES.BMP_BTR]: 8026,
        [NAMES.ARTA]: 4481,
        [NAMES.PPO]: 425,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3820,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7044
    },
    "Jul 17 2023": {
        [NAMES.TROOPS]: 238300,
        [NAMES.TANKS]: 4115,
        [NAMES.BMP_BTR]: 8034,
        [NAMES.ARTA]: 4504,
        [NAMES.PPO]: 428,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3828,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7059
    },
    "Jul 18 2023": {
        [NAMES.TROOPS]: 239010,
        [NAMES.TANKS]: 4119,
        [NAMES.BMP_BTR]: 8051,
        [NAMES.ARTA]: 4542,
        [NAMES.PPO]: 428,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3839,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7086
    },
    "Jul 19 2023": {
        [NAMES.TROOPS]: 239480,
        [NAMES.TANKS]: 4123,
        [NAMES.BMP_BTR]: 8059,
        [NAMES.ARTA]: 4573,
        [NAMES.PPO]: 430,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3885,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7105
    },
    "Jul 20 2023": {
        [NAMES.TROOPS]: 240010,
        [NAMES.TANKS]: 4129,
        [NAMES.BMP_BTR]: 8065,
        [NAMES.ARTA]: 4592,
        [NAMES.PPO]: 433,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3918,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7134
    },
    "Jul 21 2023": {
        [NAMES.TROOPS]: 240690,
        [NAMES.TANKS]: 4133,
        [NAMES.BMP_BTR]: 8080,
        [NAMES.ARTA]: 4610,
        [NAMES.PPO]: 440,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3933,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7145
    },
    "Jul 22 2023": {
        [NAMES.TROOPS]: 241330,
        [NAMES.TANKS]: 4140,
        [NAMES.BMP_BTR]: 8096,
        [NAMES.ARTA]: 4629,
        [NAMES.PPO]: 448,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3944,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7159
    },
    "Jul 23 2023": {
        [NAMES.TROOPS]: 241960,
        [NAMES.TANKS]: 4151,
        [NAMES.BMP_BTR]: 8105,
        [NAMES.ARTA]: 4658,
        [NAMES.PPO]: 451,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3958,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7172
    },
    "Jul 24 2023": {
        [NAMES.TROOPS]: 242620,
        [NAMES.TANKS]: 4162,
        [NAMES.BMP_BTR]: 8118,
        [NAMES.ARTA]: 4675,
        [NAMES.PPO]: 452,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3963,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7182
    },
    "Jul 25 2023": {
        [NAMES.TROOPS]: 243220,
        [NAMES.TANKS]: 4174,
        [NAMES.BMP_BTR]: 8131,
        [NAMES.ARTA]: 4705,
        [NAMES.PPO]: 454,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 310,
        [NAMES.UAV]: 3977,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7194
    },
    "Jul 26 2023": {
        [NAMES.TROOPS]: 243680,
        [NAMES.TANKS]: 4177,
        [NAMES.BMP_BTR]: 8136,
        [NAMES.ARTA]: 4727,
        [NAMES.PPO]: 457,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 3993,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7211
    },
    "Jul 27 2023": {
        [NAMES.TROOPS]: 244270,
        [NAMES.TANKS]: 4186,
        [NAMES.BMP_BTR]: 8147,
        [NAMES.ARTA]: 4745,
        [NAMES.PPO]: 457,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 3996,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7229
    },
    "Jul 28 2023": {
        [NAMES.TROOPS]: 244830,
        [NAMES.TANKS]: 4190,
        [NAMES.BMP_BTR]: 8161,
        [NAMES.ARTA]: 4775,
        [NAMES.PPO]: 458,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 4007,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7240
    },
    "Jul 29 2023": {
        [NAMES.TROOPS]: 245220,
        [NAMES.TANKS]: 4191,
        [NAMES.BMP_BTR]: 8167,
        [NAMES.ARTA]: 4786,
        [NAMES.PPO]: 458,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 4007,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7247
    },
    "Jul 30 2023": {
        [NAMES.TROOPS]: 245700,
        [NAMES.TANKS]: 4205,
        [NAMES.BMP_BTR]: 8178,
        [NAMES.ARTA]: 4795,
        [NAMES.PPO]: 459,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 4011,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7275
    },
    "Jul 31 2023": {
        [NAMES.TROOPS]: 246190,
        [NAMES.TANKS]: 4211,
        [NAMES.BMP_BTR]: 8188,
        [NAMES.ARTA]: 4816,
        [NAMES.PPO]: 460,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 4017,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7292
    },
    "Aug 01 2023": {
        [NAMES.TROOPS]: 246690,
        [NAMES.TANKS]: 4216,
        [NAMES.BMP_BTR]: 8205,
        [NAMES.ARTA]: 4839,
        [NAMES.PPO]: 462,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 4027,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7324
    },
    "Aug 02 2023": {
        [NAMES.TROOPS]: 247230,
        [NAMES.TANKS]: 4217,
        [NAMES.BMP_BTR]: 8213,
        [NAMES.ARTA]: 4866,
        [NAMES.PPO]: 463,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 4042,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7349
    },
    "Aug 03 2023": {
        [NAMES.TROOPS]: 247850,
        [NAMES.TANKS]: 4224,
        [NAMES.BMP_BTR]: 8234,
        [NAMES.ARTA]: 4892,
        [NAMES.PPO]: 465,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 4077,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7372
    },
    "Aug 04 2023": {
        [NAMES.TROOPS]: 248490,
        [NAMES.TANKS]: 4228,
        [NAMES.BMP_BTR]: 8249,
        [NAMES.ARTA]: 4911,
        [NAMES.PPO]: 466,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 4104,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7392
    },
    "Aug 05 2023": {
        [NAMES.TROOPS]: 249110,
        [NAMES.TANKS]: 4232,
        [NAMES.BMP_BTR]: 8251,
        [NAMES.ARTA]: 4943,
        [NAMES.PPO]: 466,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 4116,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7422
    },
    "Aug 06 2023": {
        [NAMES.TROOPS]: 249700,
        [NAMES.TANKS]: 4237,
        [NAMES.BMP_BTR]: 8262,
        [NAMES.ARTA]: 4965,
        [NAMES.PPO]: 466,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 4126,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7441
    },
    "Aug 07 2023": {
        [NAMES.TROOPS]: 250240,
        [NAMES.TANKS]: 4244,
        [NAMES.BMP_BTR]: 8270,
        [NAMES.ARTA]: 4977,
        [NAMES.PPO]: 467,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 311,
        [NAMES.UAV]: 4154,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7451
    },
    "Aug 08 2023": {
        [NAMES.TROOPS]: 250800,
        [NAMES.TANKS]: 4254,
        [NAMES.BMP_BTR]: 8278,
        [NAMES.ARTA]: 4996,
        [NAMES.PPO]: 468,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 312,
        [NAMES.UAV]: 4158,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7460
    },
    "Aug 09 2023": {
        [NAMES.TROOPS]: 251620,
        [NAMES.TANKS]: 4262,
        [NAMES.BMP_BTR]: 8290,
        [NAMES.ARTA]: 5013,
        [NAMES.PPO]: 469,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 312,
        [NAMES.UAV]: 4175,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7479
    },
    "Aug 10 2023": {
        [NAMES.TROOPS]: 252200,
        [NAMES.TANKS]: 4278,
        [NAMES.BMP_BTR]: 8303,
        [NAMES.ARTA]: 5028,
        [NAMES.PPO]: 469,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 313,
        [NAMES.UAV]: 4179,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7495
    },
    "Aug 11 2023": {
        [NAMES.TROOPS]: 252780,
        [NAMES.TANKS]: 4290,
        [NAMES.BMP_BTR]: 8318,
        [NAMES.ARTA]: 5043,
        [NAMES.PPO]: 471,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 313,
        [NAMES.UAV]: 4197,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7511
    },
    "Aug 12 2023": {
        [NAMES.TROOPS]: 253290,
        [NAMES.TANKS]: 4295,
        [NAMES.BMP_BTR]: 8324,
        [NAMES.ARTA]: 5053,
        [NAMES.PPO]: 472,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 313,
        [NAMES.UAV]: 4201,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7523
    },
    "Aug 13 2023": {
        [NAMES.TROOPS]: 253850,
        [NAMES.TANKS]: 4298,
        [NAMES.BMP_BTR]: 8335,
        [NAMES.ARTA]: 5072,
        [NAMES.PPO]: 477,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 313,
        [NAMES.UAV]: 4204,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7543
    },
    "Aug 14 2023": {
        [NAMES.TROOPS]: 254380,
        [NAMES.TANKS]: 4306,
        [NAMES.BMP_BTR]: 8354,
        [NAMES.ARTA]: 5099,
        [NAMES.PPO]: 479,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 313,
        [NAMES.UAV]: 4213,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7562
    },
    "Aug 15 2023": {
        [NAMES.TROOPS]: 254920,
        [NAMES.TANKS]: 4313,
        [NAMES.BMP_BTR]: 8370,
        [NAMES.ARTA]: 5128,
        [NAMES.PPO]: 482,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 314,
        [NAMES.UAV]: 4242,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7584
    },
    "Aug 16 2023": {
        [NAMES.TROOPS]: 255570,
        [NAMES.TANKS]: 4324,
        [NAMES.BMP_BTR]: 8380,
        [NAMES.ARTA]: 5152,
        [NAMES.PPO]: 485,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 314,
        [NAMES.UAV]: 4248,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7614
    },
    "Aug 17 2023": {
        [NAMES.TROOPS]: 256050,
        [NAMES.TANKS]: 4329,
        [NAMES.BMP_BTR]: 8398,
        [NAMES.ARTA]: 5169,
        [NAMES.PPO]: 486,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 314,
        [NAMES.UAV]: 4272,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7641
    },
    "Aug 18 2023": {
        [NAMES.TROOPS]: 256510,
        [NAMES.TANKS]: 4332,
        [NAMES.BMP_BTR]: 8410,
        [NAMES.ARTA]: 5193,
        [NAMES.PPO]: 486,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4276,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7658
    },
    "Aug 19 2023": {
        [NAMES.TROOPS]: 257010,
        [NAMES.TANKS]: 4340,
        [NAMES.BMP_BTR]: 8424,
        [NAMES.ARTA]: 5212,
        [NAMES.PPO]: 486,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4282,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7665
    },
    "Aug 20 2023": {
        [NAMES.TROOPS]: 257470,
        [NAMES.TANKS]: 4346,
        [NAMES.BMP_BTR]: 8435,
        [NAMES.ARTA]: 5245,
        [NAMES.PPO]: 489,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4304,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7680
    },
    "Aug 21 2023": {
        [NAMES.TROOPS]: 257930,
        [NAMES.TANKS]: 4358,
        [NAMES.BMP_BTR]: 8449,
        [NAMES.ARTA]: 5264,
        [NAMES.PPO]: 489,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4309,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7692
    },
    "Aug 22 2023": {
        [NAMES.TROOPS]: 258340,
        [NAMES.TANKS]: 4362,
        [NAMES.BMP_BTR]: 8476,
        [NAMES.ARTA]: 5295,
        [NAMES.PPO]: 491,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4312,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7722
    },
    "Aug 23 2023": {
        [NAMES.TROOPS]: 258820,
        [NAMES.TANKS]: 4373,
        [NAMES.BMP_BTR]: 8488,
        [NAMES.ARTA]: 5318,
        [NAMES.PPO]: 491,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4324,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7745
    },
    "Aug 24 2023": {
        [NAMES.TROOPS]: 259160,
        [NAMES.TANKS]: 4375,
        [NAMES.BMP_BTR]: 8511,
        [NAMES.ARTA]: 5333,
        [NAMES.PPO]: 494,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4344,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7773
    },
    "Aug 25 2023": {
        [NAMES.TROOPS]: 259630,
        [NAMES.TANKS]: 4378,
        [NAMES.BMP_BTR]: 8521,
        [NAMES.ARTA]: 5361,
        [NAMES.PPO]: 495,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4356,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7790
    },
    "Aug 26 2023": {
        [NAMES.TROOPS]: 260270,
        [NAMES.TANKS]: 4390,
        [NAMES.BMP_BTR]: 8539,
        [NAMES.ARTA]: 5379,
        [NAMES.PPO]: 497,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4367,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7823
    },
    "Aug 27 2023": {
        [NAMES.TROOPS]: 260820,
        [NAMES.TANKS]: 4396,
        [NAMES.BMP_BTR]: 8554,
        [NAMES.ARTA]: 5403,
        [NAMES.PPO]: 498,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4378,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7854
    },
    "Aug 28 2023": {
        [NAMES.TROOPS]: 261310,
        [NAMES.TANKS]: 4400,
        [NAMES.BMP_BTR]: 8562,
        [NAMES.ARTA]: 5425,
        [NAMES.PPO]: 499,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4383,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7866
    },
    "Aug 29 2023": {
        [NAMES.TROOPS]: 261840,
        [NAMES.TANKS]: 4414,
        [NAMES.BMP_BTR]: 8579,
        [NAMES.ARTA]: 5450,
        [NAMES.PPO]: 500,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4387,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7909
    },
    "Aug 30 2023": {
        [NAMES.TROOPS]: 262410,
        [NAMES.TANKS]: 4423,
        [NAMES.BMP_BTR]: 8596,
        [NAMES.ARTA]: 5476,
        [NAMES.PPO]: 500,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4395,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7953
    },
    "Aug 31 2023": {
        [NAMES.TROOPS]: 263020,
        [NAMES.TANKS]: 4436,
        [NAMES.BMP_BTR]: 8604,
        [NAMES.ARTA]: 5507,
        [NAMES.PPO]: 500,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4417,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 7976
    },
    "Sep 01 2023": {
        [NAMES.TROOPS]: 263490,
        [NAMES.TANKS]: 4459,
        [NAMES.BMP_BTR]: 8613,
        [NAMES.ARTA]: 5530,
        [NAMES.PPO]: 500,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4421,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 8009
    },
    "Sep 02 2023": {
        [NAMES.TROOPS]: 264060,
        [NAMES.TANKS]: 4471,
        [NAMES.BMP_BTR]: 8637,
        [NAMES.ARTA]: 5560,
        [NAMES.PPO]: 501,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4433,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 8043
    },
    "Sep 03 2023": {
        [NAMES.TROOPS]: 264660,
        [NAMES.TANKS]: 4476,
        [NAMES.BMP_BTR]: 8649,
        [NAMES.ARTA]: 5582,
        [NAMES.PPO]: 502,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4444,
        [NAMES.SHIPS]: 18,
        [NAMES.AUTO]: 8102
    },
    "Sep 04 2023": {
        [NAMES.TROOPS]: 265120,
        [NAMES.TANKS]: 4480,
        [NAMES.BMP_BTR]: 8663,
        [NAMES.ARTA]: 5611,
        [NAMES.PPO]: 503,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4481,
        [NAMES.SHIPS]: 19,
        [NAMES.AUTO]: 8149
    },
    "Sep 05 2023": {
        [NAMES.TROOPS]: 265680,
        [NAMES.TANKS]: 4489,
        [NAMES.BMP_BTR]: 8670,
        [NAMES.ARTA]: 5649,
        [NAMES.PPO]: 504,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4512,
        [NAMES.SHIPS]: 19,
        [NAMES.AUTO]: 8175
    },
    "Sep 06 2023": {
        [NAMES.TROOPS]: 266290,
        [NAMES.TANKS]: 4497,
        [NAMES.BMP_BTR]: 8682,
        [NAMES.ARTA]: 5685,
        [NAMES.PPO]: 505,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4519,
        [NAMES.SHIPS]: 19,
        [NAMES.AUTO]: 8190
    },
    "Sep 07 2023": {
        [NAMES.TROOPS]: 266900,
        [NAMES.TANKS]: 4506,
        [NAMES.BMP_BTR]: 8703,
        [NAMES.ARTA]: 5722,
        [NAMES.PPO]: 506,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4541,
        [NAMES.SHIPS]: 19,
        [NAMES.AUTO]: 8217
    },
    "Sep 08 2023": {
        [NAMES.TROOPS]: 267540,
        [NAMES.TANKS]: 4529,
        [NAMES.BMP_BTR]: 8726,
        [NAMES.ARTA]: 5753,
        [NAMES.PPO]: 507,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4570,
        [NAMES.SHIPS]: 19,
        [NAMES.AUTO]: 8264
    },
    "Sep 09 2023": {
        [NAMES.TROOPS]: 268140,
        [NAMES.TANKS]: 4544,
        [NAMES.BMP_BTR]: 8739,
        [NAMES.ARTA]: 5789,
        [NAMES.PPO]: 508,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4588,
        [NAMES.SHIPS]: 19,
        [NAMES.AUTO]: 8298
    },
    "Sep 10 2023": {
        [NAMES.TROOPS]: 268630,
        [NAMES.TANKS]: 4554,
        [NAMES.BMP_BTR]: 8755,
        [NAMES.ARTA]: 5811,
        [NAMES.PPO]: 509,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4593,
        [NAMES.SHIPS]: 19,
        [NAMES.AUTO]: 8338
    },
    "Sep 11 2023": {
        [NAMES.TROOPS]: 269210,
        [NAMES.TANKS]: 4560,
        [NAMES.BMP_BTR]: 8767,
        [NAMES.ARTA]: 5839,
        [NAMES.PPO]: 512,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4628,
        [NAMES.SHIPS]: 19,
        [NAMES.AUTO]: 8370
    },
    "Sep 12 2023": {
        [NAMES.TROOPS]: 269760,
        [NAMES.TANKS]: 4568,
        [NAMES.BMP_BTR]: 8778,
        [NAMES.ARTA]: 5872,
        [NAMES.PPO]: 515,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4645,
        [NAMES.SHIPS]: 19,
        [NAMES.AUTO]: 8413
    },
    "Sep 13 2023": {
        [NAMES.TROOPS]: 270350,
        [NAMES.TANKS]: 4584,
        [NAMES.BMP_BTR]: 8792,
        [NAMES.ARTA]: 5902,
        [NAMES.PPO]: 517,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4650,
        [NAMES.SHIPS]: 19,
        [NAMES.AUTO]: 8444
    },
    "Sep 14 2023": {
        [NAMES.TROOPS]: 270970,
        [NAMES.TANKS]: 4599,
        [NAMES.BMP_BTR]: 8810,
        [NAMES.ARTA]: 5944,
        [NAMES.PPO]: 517,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4697,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8458
    },
    "Sep 15 2023": {
        [NAMES.TROOPS]: 271440,
        [NAMES.TANKS]: 4612,
        [NAMES.BMP_BTR]: 8814,
        [NAMES.ARTA]: 5972,
        [NAMES.PPO]: 521,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4714,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8492
    },
    "Sep 16 2023": {
        [NAMES.TROOPS]: 271790,
        [NAMES.TANKS]: 4616,
        [NAMES.BMP_BTR]: 8824,
        [NAMES.ARTA]: 5988,
        [NAMES.PPO]: 521,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4738,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8525
    },
    "Sep 17 2023": {
        [NAMES.TROOPS]: 272320,
        [NAMES.TANKS]: 4620,
        [NAMES.BMP_BTR]: 8828,
        [NAMES.ARTA]: 6003,
        [NAMES.PPO]: 523,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4724,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8537
    },
    "Sep 18 2023": {
        [NAMES.TROOPS]: 272940,
        [NAMES.TANKS]: 4623,
        [NAMES.BMP_BTR]: 8834,
        [NAMES.ARTA]: 6027,
        [NAMES.PPO]: 525,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4769,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8571
    },
    "Sep 19 2023": {
        [NAMES.TROOPS]: 273460,
        [NAMES.TANKS]: 4628,
        [NAMES.BMP_BTR]: 8851,
        [NAMES.ARTA]: 6062,
        [NAMES.PPO]: 526,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4784,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8601
    },
    "Sep 20 2023": {
        [NAMES.TROOPS]: 273980,
        [NAMES.TANKS]: 4635,
        [NAMES.BMP_BTR]: 8868,
        [NAMES.ARTA]: 6096,
        [NAMES.PPO]: 526,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4821,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8633
    },
    "Sep 21 2023": {
        [NAMES.TROOPS]: 274470,
        [NAMES.TANKS]: 4638,
        [NAMES.BMP_BTR]: 8883,
        [NAMES.ARTA]: 6137,
        [NAMES.PPO]: 528,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4850,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8670
    },
    "Sep 22 2023": {
        [NAMES.TROOPS]: 274950,
        [NAMES.TANKS]: 4644,
        [NAMES.BMP_BTR]: 8891,
        [NAMES.ARTA]: 6177,
        [NAMES.PPO]: 528,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4858,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8690
    },
    "Sep 23 2023": {
        [NAMES.TROOPS]: 275460,
        [NAMES.TANKS]: 4655,
        [NAMES.BMP_BTR]: 8912,
        [NAMES.ARTA]: 6210,
        [NAMES.PPO]: 530,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4867,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8716
    },
    "Sep 24 2023": {
        [NAMES.TROOPS]: 275850,
        [NAMES.TANKS]: 4662,
        [NAMES.BMP_BTR]: 8914,
        [NAMES.ARTA]: 6233,
        [NAMES.PPO]: 531,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4888,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8734
    },
    "Sep 25 2023": {
        [NAMES.TROOPS]: 276270,
        [NAMES.TANKS]: 4667,
        [NAMES.BMP_BTR]: 8927,
        [NAMES.ARTA]: 6260,
        [NAMES.PPO]: 533,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4895,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8746
    },
    "Sep 26 2023": {
        [NAMES.TROOPS]: 276670,
        [NAMES.TANKS]: 4672,
        [NAMES.BMP_BTR]: 8946,
        [NAMES.ARTA]: 6299,
        [NAMES.PPO]: 533,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4924,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8763
    },
    "Sep 27 2023": {
        [NAMES.TROOPS]: 276990,
        [NAMES.TANKS]: 4675,
        [NAMES.BMP_BTR]: 8950,
        [NAMES.ARTA]: 6337,
        [NAMES.PPO]: 534,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4948,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8792
    },
    "Sep 28 2023": {
        [NAMES.TROOPS]: 277320,
        [NAMES.TANKS]: 4679,
        [NAMES.BMP_BTR]: 8962,
        [NAMES.ARTA]: 6375,
        [NAMES.PPO]: 535,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4957,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8815
    },
    "Sep 29 2023": {
        [NAMES.TROOPS]: 277660,
        [NAMES.TANKS]: 4687,
        [NAMES.BMP_BTR]: 8972,
        [NAMES.ARTA]: 6409,
        [NAMES.PPO]: 536,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 4991,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8836
    },
    "Sep 30 2023": {
        [NAMES.TROOPS]: 278130,
        [NAMES.TANKS]: 4691,
        [NAMES.BMP_BTR]: 8984,
        [NAMES.ARTA]: 6447,
        [NAMES.PPO]: 537,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5006,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8854
    },
    "Oct 01 2023": {
        [NAMES.TROOPS]: 278570,
        [NAMES.TANKS]: 4702,
        [NAMES.BMP_BTR]: 8991,
        [NAMES.ARTA]: 6477,
        [NAMES.PPO]: 537,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5043,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8883
    },
    "Oct 02 2023": {
        [NAMES.TROOPS]: 279080,
        [NAMES.TANKS]: 4717,
        [NAMES.BMP_BTR]: 9000,
        [NAMES.ARTA]: 6525,
        [NAMES.PPO]: 540,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5061,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8909
    },
    "Oct 03 2023": {
        [NAMES.TROOPS]: 279440,
        [NAMES.TANKS]: 4732,
        [NAMES.BMP_BTR]: 9008,
        [NAMES.ARTA]: 6565,
        [NAMES.PPO]: 540,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5080,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8932
    },
    "Oct 04 2023": {
        [NAMES.TROOPS]: 279890,
        [NAMES.TANKS]: 4745,
        [NAMES.BMP_BTR]: 9026,
        [NAMES.ARTA]: 6612,
        [NAMES.PPO]: 540,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5121,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8962
    },
    "Oct 05 2023": {
        [NAMES.TROOPS]: 280470,
        [NAMES.TANKS]: 4757,
        [NAMES.BMP_BTR]: 9052,
        [NAMES.ARTA]: 6643,
        [NAMES.PPO]: 540,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5129,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 8980
    },
    "Oct 06 2023": {
        [NAMES.TROOPS]: 281090,
        [NAMES.TANKS]: 4777,
        [NAMES.BMP_BTR]: 9082,
        [NAMES.ARTA]: 6666,
        [NAMES.PPO]: 540,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5158,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9015
    },
    "Oct 07 2023": {
        [NAMES.TROOPS]: 281700,
        [NAMES.TANKS]: 4800,
        [NAMES.BMP_BTR]: 9102,
        [NAMES.ARTA]: 6688,
        [NAMES.PPO]: 541,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5185,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9073
    },
    "Oct 08 2023": {
        [NAMES.TROOPS]: 282280,
        [NAMES.TANKS]: 4821,
        [NAMES.BMP_BTR]: 9123,
        [NAMES.ARTA]: 6705,
        [NAMES.PPO]: 542,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5190,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9111
    },
    "Oct 09 2023": {
        [NAMES.TROOPS]: 282630,
        [NAMES.TANKS]: 4823,
        [NAMES.BMP_BTR]: 9126,
        [NAMES.ARTA]: 6706,
        [NAMES.PPO]: 543,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5190,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9113
    },
    "Oct 10 2023": {
        [NAMES.TROOPS]: 283080,
        [NAMES.TANKS]: 4829,
        [NAMES.BMP_BTR]: 9129,
        [NAMES.ARTA]: 6713,
        [NAMES.PPO]: 544,
        [NAMES.JETS]: 315,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5207,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9125
    },
    "Oct 11 2023": {
        [NAMES.TROOPS]: 283900,
        [NAMES.TANKS]: 4863,
        [NAMES.BMP_BTR]: 9220,
        [NAMES.ARTA]: 6731,
        [NAMES.PPO]: 545,
        [NAMES.JETS]: 316,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5226,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9145
    },
    "Oct 12 2023": {
        [NAMES.TROOPS]: 284890,
        [NAMES.TANKS]: 4905,
        [NAMES.BMP_BTR]: 9264,
        [NAMES.ARTA]: 6763,
        [NAMES.PPO]: 545,
        [NAMES.JETS]: 316,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5247,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9170
    },
    "Oct 13 2023": {
        [NAMES.TROOPS]: 285920,
        [NAMES.TANKS]: 4931,
        [NAMES.BMP_BTR]: 9313,
        [NAMES.ARTA]: 6807,
        [NAMES.PPO]: 546,
        [NAMES.JETS]: 316,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5264,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9203
    },
    "Oct 14 2023": {
        [NAMES.TROOPS]: 286890,
        [NAMES.TANKS]: 4940,
        [NAMES.BMP_BTR]: 9337,
        [NAMES.ARTA]: 6833,
        [NAMES.PPO]: 547,
        [NAMES.JETS]: 317,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5265,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9225
    },
    "Oct 15 2023": {
        [NAMES.TROOPS]: 287770,
        [NAMES.TANKS]: 4948,
        [NAMES.BMP_BTR]: 9362,
        [NAMES.ARTA]: 6866,
        [NAMES.PPO]: 547,
        [NAMES.JETS]: 317,
        [NAMES.HELI]: 316,
        [NAMES.UAV]: 5269,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9249
    },
    "Oct 16 2023": {
        [NAMES.TROOPS]: 288630,
        [NAMES.TANKS]: 4965,
        [NAMES.BMP_BTR]: 9385,
        [NAMES.ARTA]: 6910,
        [NAMES.PPO]: 547,
        [NAMES.JETS]: 318,
        [NAMES.HELI]: 317,
        [NAMES.UAV]: 5280,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9271
    },
    "Oct 17 2023": {
        [NAMES.TROOPS]: 289430,
        [NAMES.TANKS]: 4979,
        [NAMES.BMP_BTR]: 9405,
        [NAMES.ARTA]: 6936,
        [NAMES.PPO]: 547,
        [NAMES.JETS]: 318,
        [NAMES.HELI]: 317,
        [NAMES.UAV]: 5291,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9293
    },
    "Oct 18 2023": {
        [NAMES.TROOPS]: 290050,
        [NAMES.TANKS]: 4981,
        [NAMES.BMP_BTR]: 9420,
        [NAMES.ARTA]: 6944,
        [NAMES.PPO]: 547,
        [NAMES.JETS]: 319,
        [NAMES.HELI]: 323,
        [NAMES.UAV]: 5301,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9303
    },
    "Oct 19 2023": {
        [NAMES.TROOPS]: 290680,
        [NAMES.TANKS]: 4992,
        [NAMES.BMP_BTR]: 9434,
        [NAMES.ARTA]: 6983,
        [NAMES.PPO]: 548,
        [NAMES.JETS]: 320,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5318,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9337
    },
    "Oct 20 2023": {
        [NAMES.TROOPS]: 292060,
        [NAMES.TANKS]: 5047,
        [NAMES.BMP_BTR]: 9557,
        [NAMES.ARTA]: 7012,
        [NAMES.PPO]: 548,
        [NAMES.JETS]: 320,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5326,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9370
    },
    "Oct 21 2023": {
        [NAMES.TROOPS]: 292850,
        [NAMES.TANKS]: 5063,
        [NAMES.BMP_BTR]: 9612,
        [NAMES.ARTA]: 7017,
        [NAMES.PPO]: 548,
        [NAMES.JETS]: 320,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5330,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9391
    },
    "Oct 22 2023": {
        [NAMES.TROOPS]: 293830,
        [NAMES.TANKS]: 5081,
        [NAMES.BMP_BTR]: 9631,
        [NAMES.ARTA]: 7032,
        [NAMES.PPO]: 549,
        [NAMES.JETS]: 320,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5339,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9404
    },
    "Oct 23 2023": {
        [NAMES.TROOPS]: 294700,
        [NAMES.TANKS]: 5093,
        [NAMES.BMP_BTR]: 9653,
        [NAMES.ARTA]: 7057,
        [NAMES.PPO]: 551,
        [NAMES.JETS]: 320,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5345,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9419
    },
    "Oct 24 2023": {
        [NAMES.TROOPS]: 295510,
        [NAMES.TANKS]: 5105,
        [NAMES.BMP_BTR]: 9669,
        [NAMES.ARTA]: 7081,
        [NAMES.PPO]: 552,
        [NAMES.JETS]: 320,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5363,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9447
    },
    "Oct 25 2023": {
        [NAMES.TROOPS]: 296310,
        [NAMES.TANKS]: 5112,
        [NAMES.BMP_BTR]: 9697,
        [NAMES.ARTA]: 7125,
        [NAMES.PPO]: 554,
        [NAMES.JETS]: 320,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5372,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9474
    },
    "Oct 26 2023": {
        [NAMES.TROOPS]: 297120,
        [NAMES.TANKS]: 5141,
        [NAMES.BMP_BTR]: 9715,
        [NAMES.ARTA]: 7155,
        [NAMES.PPO]: 556,
        [NAMES.JETS]: 320,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5389,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9507
    },
    "Oct 27 2023": {
        [NAMES.TROOPS]: 297680,
        [NAMES.TANKS]: 5145,
        [NAMES.BMP_BTR]: 9726,
        [NAMES.ARTA]: 7162,
        [NAMES.PPO]: 556,
        [NAMES.JETS]: 320,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5390,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9513
    },
    "Oct 28 2023": {
        [NAMES.TROOPS]: 298420,
        [NAMES.TANKS]: 5167,
        [NAMES.BMP_BTR]: 9749,
        [NAMES.ARTA]: 7180,
        [NAMES.PPO]: 558,
        [NAMES.JETS]: 320,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5399,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9524
    },
    "Oct 29 2023": {
        [NAMES.TROOPS]: 299080,
        [NAMES.TANKS]: 5175,
        [NAMES.BMP_BTR]: 9758,
        [NAMES.ARTA]: 7188,
        [NAMES.PPO]: 558,
        [NAMES.JETS]: 320,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5399,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9532
    },
    "Oct 30 2023": {
        [NAMES.TROOPS]: 299940,
        [NAMES.TANKS]: 5190,
        [NAMES.BMP_BTR]: 9775,
        [NAMES.ARTA]: 7202,
        [NAMES.PPO]: 559,
        [NAMES.JETS]: 321,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5419,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9555
    },
    "Oct 31 2023": {
        [NAMES.TROOPS]: 300810,
        [NAMES.TANKS]: 5211,
        [NAMES.BMP_BTR]: 9804,
        [NAMES.ARTA]: 7227,
        [NAMES.PPO]: 562,
        [NAMES.JETS]: 321,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5440,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9590
    },
    "Nov 01 2023": {
        [NAMES.TROOPS]: 301490,
        [NAMES.TANKS]: 5223,
        [NAMES.BMP_BTR]: 9834,
        [NAMES.ARTA]: 7250,
        [NAMES.PPO]: 563,
        [NAMES.JETS]: 321,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5468,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9624
    },
    "Nov 02 2023": {
        [NAMES.TROOPS]: 302420,
        [NAMES.TANKS]: 5241,
        [NAMES.BMP_BTR]: 9877,
        [NAMES.ARTA]: 7292,
        [NAMES.PPO]: 566,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5488,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9658
    },
    "Nov 03 2023": {
        [NAMES.TROOPS]: 303270,
        [NAMES.TANKS]: 5265,
        [NAMES.BMP_BTR]: 9901,
        [NAMES.ARTA]: 7328,
        [NAMES.PPO]: 566,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5495,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9691
    },
    "Nov 04 2023": {
        [NAMES.TROOPS]: 304100,
        [NAMES.TANKS]: 5276,
        [NAMES.BMP_BTR]: 9939,
        [NAMES.ARTA]: 7363,
        [NAMES.PPO]: 568,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5534,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9716
    },
    "Nov 05 2023": {
        [NAMES.TROOPS]: 305090,
        [NAMES.TANKS]: 5284,
        [NAMES.BMP_BTR]: 9953,
        [NAMES.ARTA]: 7375,
        [NAMES.PPO]: 570,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5534,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9730
    },
    "Nov 06 2023": {
        [NAMES.TROOPS]: 305970,
        [NAMES.TANKS]: 5288,
        [NAMES.BMP_BTR]: 9958,
        [NAMES.ARTA]: 7389,
        [NAMES.PPO]: 570,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5554,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9744
    },
    "Nov 07 2023": {
        [NAMES.TROOPS]: 306860,
        [NAMES.TANKS]: 5293,
        [NAMES.BMP_BTR]: 9974,
        [NAMES.ARTA]: 7410,
        [NAMES.PPO]: 570,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5557,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9767
    },
    "Nov 08 2023": {
        [NAMES.TROOPS]: 307640,
        [NAMES.TANKS]: 5301,
        [NAMES.BMP_BTR]: 9996,
        [NAMES.ARTA]: 7439,
        [NAMES.PPO]: 572,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5567,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9814
    },
    "Nov 09 2023": {
        [NAMES.TROOPS]: 308720,
        [NAMES.TANKS]: 5316,
        [NAMES.BMP_BTR]: 10014,
        [NAMES.ARTA]: 7475,
        [NAMES.PPO]: 577,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5593,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9853
    },
    "Nov 10 2023": {
        [NAMES.TROOPS]: 309520,
        [NAMES.TANKS]: 5317,
        [NAMES.BMP_BTR]: 10017,
        [NAMES.ARTA]: 7489,
        [NAMES.PPO]: 578,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5595,
        [NAMES.SHIPS]: 21,
        [NAMES.AUTO]: 9876
    },
    "Nov 11 2023": {
        [NAMES.TROOPS]: 310650,
        [NAMES.TANKS]: 5342,
        [NAMES.BMP_BTR]: 10041,
        [NAMES.ARTA]: 7527,
        [NAMES.PPO]: 579,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5620,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 9925
    },
    "Nov 12 2023": {
        [NAMES.TROOPS]: 311750,
        [NAMES.TANKS]: 5349,
        [NAMES.BMP_BTR]: 10073,
        [NAMES.ARTA]: 7559,
        [NAMES.PPO]: 580,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5632,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 9944
    },
    "Nov 13 2023": {
        [NAMES.TROOPS]: 312550,
        [NAMES.TANKS]: 5354,
        [NAMES.BMP_BTR]: 10079,
        [NAMES.ARTA]: 7569,
        [NAMES.PPO]: 580,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5634,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 9949
    },
    "Nov 14 2023": {
        [NAMES.TROOPS]: 313470,
        [NAMES.TANKS]: 5362,
        [NAMES.BMP_BTR]: 10086,
        [NAMES.ARTA]: 7589,
        [NAMES.PPO]: 580,
        [NAMES.JETS]: 322,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5649,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 9973
    },
    "Nov 15 2023": {
        [NAMES.TROOPS]: 314290,
        [NAMES.TANKS]: 5377,
        [NAMES.BMP_BTR]: 10104,
        [NAMES.ARTA]: 7647,
        [NAMES.PPO]: 582,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5675,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10020
    },
    "Nov 16 2023": {
        [NAMES.TROOPS]: 315620,
        [NAMES.TANKS]: 5388,
        [NAMES.BMP_BTR]: 10121,
        [NAMES.ARTA]: 7683,
        [NAMES.PPO]: 585,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5689,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10060
    },
    "Nov 17 2023": {
        [NAMES.TROOPS]: 316760,
        [NAMES.TANKS]: 5415,
        [NAMES.BMP_BTR]: 10132,
        [NAMES.ARTA]: 7712,
        [NAMES.PPO]: 585,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5709,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10077
    },
    "Nov 18 2023": {
        [NAMES.TROOPS]: 317380,
        [NAMES.TANKS]: 5422,
        [NAMES.BMP_BTR]: 10141,
        [NAMES.ARTA]: 7726,
        [NAMES.PPO]: 586,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5726,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10091
    },
    "Nov 19 2023": {
        [NAMES.TROOPS]: 318570,
        [NAMES.TANKS]: 5435,
        [NAMES.BMP_BTR]: 10166,
        [NAMES.ARTA]: 7744,
        [NAMES.PPO]: 588,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5755,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10120
    },
    "Nov 20 2023": {
        [NAMES.TROOPS]: 319210,
        [NAMES.TANKS]: 5439,
        [NAMES.BMP_BTR]: 10168,
        [NAMES.ARTA]: 7748,
        [NAMES.PPO]: 588,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5764,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10127
    },
    "Nov 21 2023": {
        [NAMES.TROOPS]: 319820,
        [NAMES.TANKS]: 5439,
        [NAMES.BMP_BTR]: 10174,
        [NAMES.ARTA]: 7752,
        [NAMES.PPO]: 588,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5771,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10134
    },
    "Nov 22 2023": {
        [NAMES.TROOPS]: 320670,
        [NAMES.TANKS]: 5446,
        [NAMES.BMP_BTR]: 10188,
        [NAMES.ARTA]: 7769,
        [NAMES.PPO]: 589,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5784,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10159
    },
    "Nov 23 2023": {
        [NAMES.TROOPS]: 321800,
        [NAMES.TANKS]: 5466,
        [NAMES.BMP_BTR]: 10224,
        [NAMES.ARTA]: 7802,
        [NAMES.PPO]: 591,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5799,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10198
    },
    "Nov 24 2023": {
        [NAMES.TROOPS]: 322900,
        [NAMES.TANKS]: 5496,
        [NAMES.BMP_BTR]: 10256,
        [NAMES.ARTA]: 7833,
        [NAMES.PPO]: 595,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5800,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10230
    },
    "Nov 25 2023": {
        [NAMES.TROOPS]: 323760,
        [NAMES.TANKS]: 5502,
        [NAMES.BMP_BTR]: 10263,
        [NAMES.ARTA]: 7851,
        [NAMES.PPO]: 596,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5808,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10259
    },
    "Nov 26 2023": {
        [NAMES.TROOPS]: 324830,
        [NAMES.TANKS]: 5513,
        [NAMES.BMP_BTR]: 10279,
        [NAMES.ARTA]: 7874,
        [NAMES.PPO]: 597,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5901,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10288
    },
    "Nov 27 2023": {
        [NAMES.TROOPS]: 325580,
        [NAMES.TANKS]: 5520,
        [NAMES.BMP_BTR]: 10282,
        [NAMES.ARTA]: 7875,
        [NAMES.PPO]: 597,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5905,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10299
    },
    "Nov 28 2023": {
        [NAMES.TROOPS]: 326440,
        [NAMES.TANKS]: 5523,
        [NAMES.BMP_BTR]: 10285,
        [NAMES.ARTA]: 7876,
        [NAMES.PPO]: 597,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5905,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10302
    },
    "Nov 29 2023": {
        [NAMES.TROOPS]: 327580,
        [NAMES.TANKS]: 5538,
        [NAMES.BMP_BTR]: 10312,
        [NAMES.ARTA]: 7908,
        [NAMES.PPO]: 600,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5944,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10348
    },
    "Nov 30 2023": {
        [NAMES.TROOPS]: 328760,
        [NAMES.TANKS]: 5551,
        [NAMES.BMP_BTR]: 10340,
        [NAMES.ARTA]: 7909,
        [NAMES.PPO]: 600,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5954,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10361
    },
    "Dec 01 2023": {
        [NAMES.TROOPS]: 330040,
        [NAMES.TANKS]: 5564,
        [NAMES.BMP_BTR]: 10372,
        [NAMES.ARTA]: 7931,
        [NAMES.PPO]: 602,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5976,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10399
    },
    "Dec 02 2023": {
        [NAMES.TROOPS]: 331110,
        [NAMES.TANKS]: 5571,
        [NAMES.BMP_BTR]: 10385,
        [NAMES.ARTA]: 7941,
        [NAMES.PPO]: 602,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5994,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10410
    },
    "Dec 03 2023": {
        [NAMES.TROOPS]: 332040,
        [NAMES.TANKS]: 5575,
        [NAMES.BMP_BTR]: 10396,
        [NAMES.ARTA]: 7949,
        [NAMES.PPO]: 602,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 5995,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10432
    },
    "Dec 04 2023": {
        [NAMES.TROOPS]: 332810,
        [NAMES.TANKS]: 5580,
        [NAMES.BMP_BTR]: 10401,
        [NAMES.ARTA]: 7961,
        [NAMES.PPO]: 602,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6017,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10451
    },
    "Dec 05 2023": {
        [NAMES.TROOPS]: 333840,
        [NAMES.TANKS]: 5587,
        [NAMES.BMP_BTR]: 10416,
        [NAMES.ARTA]: 7987,
        [NAMES.PPO]: 605,
        [NAMES.JETS]: 323,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6032,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10483
    },
    "Dec 06 2023": {
        [NAMES.TROOPS]: 335110,
        [NAMES.TANKS]: 5600,
        [NAMES.BMP_BTR]: 10456,
        [NAMES.ARTA]: 8024,
        [NAMES.PPO]: 605,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6083,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10532
    },
    "Dec 07 2023": {
        [NAMES.TROOPS]: 336230,
        [NAMES.TANKS]: 5618,
        [NAMES.BMP_BTR]: 10482,
        [NAMES.ARTA]: 8045,
        [NAMES.PPO]: 605,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6116,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10561
    },
    "Dec 08 2023": {
        [NAMES.TROOPS]: 337220,
        [NAMES.TANKS]: 5626,
        [NAMES.BMP_BTR]: 10508,
        [NAMES.ARTA]: 8057,
        [NAMES.PPO]: 605,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6136,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10591
    },
    "Dec 09 2023": {
        [NAMES.TROOPS]: 338120,
        [NAMES.TANKS]: 5632,
        [NAMES.BMP_BTR]: 10519,
        [NAMES.ARTA]: 8058,
        [NAMES.PPO]: 605,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6136,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10598
    },
    "Dec 10 2023": {
        [NAMES.TROOPS]: 338820,
        [NAMES.TANKS]: 5636,
        [NAMES.BMP_BTR]: 10529,
        [NAMES.ARTA]: 8064,
        [NAMES.PPO]: 605,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6136,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10623
    },
    "Dec 11 2023": {
        [NAMES.TROOPS]: 339850,
        [NAMES.TANKS]: 5655,
        [NAMES.BMP_BTR]: 10560,
        [NAMES.ARTA]: 8070,
        [NAMES.PPO]: 605,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6154,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10636
    },
    "Dec 12 2023": {
        [NAMES.TROOPS]: 340650,
        [NAMES.TANKS]: 5664,
        [NAMES.BMP_BTR]: 10579,
        [NAMES.ARTA]: 8073,
        [NAMES.PPO]: 605,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6163,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10650
    },
    "Dec 13 2023": {
        [NAMES.TROOPS]: 341500,
        [NAMES.TANKS]: 5682,
        [NAMES.BMP_BTR]: 10594,
        [NAMES.ARTA]: 8076,
        [NAMES.PPO]: 605,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6173,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10662
    },
    "Dec 14 2023": {
        [NAMES.TROOPS]: 342800,
        [NAMES.TANKS]: 5692,
        [NAMES.BMP_BTR]: 10616,
        [NAMES.ARTA]: 8088,
        [NAMES.PPO]: 605,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6206,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10675
    },
    "Dec 15 2023": {
        [NAMES.TROOPS]: 343890,
        [NAMES.TANKS]: 5706,
        [NAMES.BMP_BTR]: 10654,
        [NAMES.ARTA]: 8099,
        [NAMES.PPO]: 605,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6226,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10699
    },
    "Dec 16 2023": {
        [NAMES.TROOPS]: 344820,
        [NAMES.TANKS]: 5720,
        [NAMES.BMP_BTR]: 10667,
        [NAMES.ARTA]: 8100,
        [NAMES.PPO]: 605,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6238,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10710
    },
    "Dec 17 2023": {
        [NAMES.TROOPS]: 346070,
        [NAMES.TANKS]: 5739,
        [NAMES.BMP_BTR]: 10692,
        [NAMES.ARTA]: 8137,
        [NAMES.PPO]: 609,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6278,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10766
    },
    "Dec 18 2023": {
        [NAMES.TROOPS]: 347160,
        [NAMES.TANKS]: 5783,
        [NAMES.BMP_BTR]: 10752,
        [NAMES.ARTA]: 8175,
        [NAMES.PPO]: 610,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6290,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10822
    },
    "Dec 19 2023": {
        [NAMES.TROOPS]: 348300,
        [NAMES.TANKS]: 5798,
        [NAMES.BMP_BTR]: 10771,
        [NAMES.ARTA]: 8190,
        [NAMES.PPO]: 611,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6299,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10842
    },
    "Dec 20 2023": {
        [NAMES.TROOPS]: 349190,
        [NAMES.TANKS]: 5814,
        [NAMES.BMP_BTR]: 10794,
        [NAMES.ARTA]: 8206,
        [NAMES.PPO]: 611,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6316,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10871
    },
    "Dec 21 2023": {
        [NAMES.TROOPS]: 350270,
        [NAMES.TANKS]: 5828,
        [NAMES.BMP_BTR]: 10825,
        [NAMES.ARTA]: 8226,
        [NAMES.PPO]: 611,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6342,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10919
    },
    "Dec 22 2023": {
        [NAMES.TROOPS]: 351350,
        [NAMES.TANKS]: 5837,
        [NAMES.BMP_BTR]: 10843,
        [NAMES.ARTA]: 8250,
        [NAMES.PPO]: 611,
        [NAMES.JETS]: 324,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6365,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10944
    },
    "Dec 23 2023": {
        [NAMES.TROOPS]: 352390,
        [NAMES.TANKS]: 5854,
        [NAMES.BMP_BTR]: 10871,
        [NAMES.ARTA]: 8286,
        [NAMES.PPO]: 611,
        [NAMES.JETS]: 327,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6384,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 10995
    },
    "Dec 24 2023": {
        [NAMES.TROOPS]: 353190,
        [NAMES.TANKS]: 5858,
        [NAMES.BMP_BTR]: 10888,
        [NAMES.ARTA]: 8314,
        [NAMES.PPO]: 611,
        [NAMES.JETS]: 327,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6404,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 11022
    },
    "Dec 25 2023": {
        [NAMES.TROOPS]: 353950,
        [NAMES.TANKS]: 5877,
        [NAMES.BMP_BTR]: 10919,
        [NAMES.ARTA]: 8347,
        [NAMES.PPO]: 613,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6436,
        [NAMES.SHIPS]: 23,
        [NAMES.AUTO]: 11075
    },
    "Dec 26 2023": {
        [NAMES.TROOPS]: 354960,
        [NAMES.TANKS]: 5899,
        [NAMES.BMP_BTR]: 10956,
        [NAMES.ARTA]: 8366,
        [NAMES.PPO]: 614,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6458,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11109
    },
    "Dec 27 2023": {
        [NAMES.TROOPS]: 355750,
        [NAMES.TANKS]: 5913,
        [NAMES.BMP_BTR]: 10973,
        [NAMES.ARTA]: 8376,
        [NAMES.PPO]: 616,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6471,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11140
    },
    "Dec 28 2023": {
        [NAMES.TROOPS]: 356670,
        [NAMES.TANKS]: 5940,
        [NAMES.BMP_BTR]: 11015,
        [NAMES.ARTA]: 8391,
        [NAMES.PPO]: 617,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6503,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11180
    },
    "Dec 29 2023": {
        [NAMES.TROOPS]: 357520,
        [NAMES.TANKS]: 5953,
        [NAMES.BMP_BTR]: 11033,
        [NAMES.ARTA]: 8417,
        [NAMES.PPO]: 620,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6539,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11215
    },
    "Dec 30 2023": {
        [NAMES.TROOPS]: 358270,
        [NAMES.TANKS]: 5969,
        [NAMES.BMP_BTR]: 11053,
        [NAMES.ARTA]: 8434,
        [NAMES.PPO]: 621,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6554,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11244
    },
    "Dec 31 2023": {
        [NAMES.TROOPS]: 359230,
        [NAMES.TANKS]: 5977,
        [NAMES.BMP_BTR]: 11070,
        [NAMES.ARTA]: 8464,
        [NAMES.PPO]: 623,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6591,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11292
    },
    "Jan 01 2024": {
        [NAMES.TROOPS]: 360010,
        [NAMES.TANKS]: 5983,
        [NAMES.BMP_BTR]: 11087,
        [NAMES.ARTA]: 8482,
        [NAMES.PPO]: 625,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6657,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11330
    },
    "Jan 02 2024": {
        [NAMES.TROOPS]: 360820,
        [NAMES.TANKS]: 5990,
        [NAMES.BMP_BTR]: 11100,
        [NAMES.ARTA]: 8531,
        [NAMES.PPO]: 626,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6710,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11365
    },
    "Jan 03 2024": {
        [NAMES.TROOPS]: 361500,
        [NAMES.TANKS]: 5990,
        [NAMES.BMP_BTR]: 11107,
        [NAMES.ARTA]: 8546,
        [NAMES.PPO]: 629,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6748,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11391
    },
    "Jan 04 2024": {
        [NAMES.TROOPS]: 362280,
        [NAMES.TANKS]: 6002,
        [NAMES.BMP_BTR]: 11128,
        [NAMES.ARTA]: 8574,
        [NAMES.PPO]: 630,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6753,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11423
    },
    "Jan 05 2024": {
        [NAMES.TROOPS]: 363070,
        [NAMES.TANKS]: 6011,
        [NAMES.BMP_BTR]: 11142,
        [NAMES.ARTA]: 8604,
        [NAMES.PPO]: 631,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6771,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11463
    },
    "Jan 06 2024": {
        [NAMES.TROOPS]: 363870,
        [NAMES.TANKS]: 6018,
        [NAMES.BMP_BTR]: 11158,
        [NAMES.ARTA]: 8617,
        [NAMES.PPO]: 633,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6787,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11478
    },
    "Jan 07 2024": {
        [NAMES.TROOPS]: 364730,
        [NAMES.TANKS]: 6022,
        [NAMES.BMP_BTR]: 11180,
        [NAMES.ARTA]: 8653,
        [NAMES.PPO]: 637,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6811,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11523
    },
    "Jan 08 2024": {
        [NAMES.TROOPS]: 365170,
        [NAMES.TANKS]: 6031,
        [NAMES.BMP_BTR]: 11194,
        [NAMES.ARTA]: 8666,
        [NAMES.PPO]: 638,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6822,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11542
    },
    "Jan 09 2024": {
        [NAMES.TROOPS]: 365990,
        [NAMES.TANKS]: 6036,
        [NAMES.BMP_BTR]: 11203,
        [NAMES.ARTA]: 8672,
        [NAMES.PPO]: 638,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6823,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11552
    },
    "Jan 10 2024": {
        [NAMES.TROOPS]: 366790,
        [NAMES.TANKS]: 6038,
        [NAMES.BMP_BTR]: 11216,
        [NAMES.ARTA]: 8684,
        [NAMES.PPO]: 639,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6834,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11575
    },
    "Jan 11 2024": {
        [NAMES.TROOPS]: 367620,
        [NAMES.TANKS]: 6050,
        [NAMES.BMP_BTR]: 11238,
        [NAMES.ARTA]: 8699,
        [NAMES.PPO]: 641,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6834,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11596
    },
    "Jan 12 2024": {
        [NAMES.TROOPS]: 368460,
        [NAMES.TANKS]: 6060,
        [NAMES.BMP_BTR]: 11254,
        [NAMES.ARTA]: 8710,
        [NAMES.PPO]: 642,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6836,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11612
    },
    "Jan 13 2024": {
        [NAMES.TROOPS]: 369160,
        [NAMES.TANKS]: 6065,
        [NAMES.BMP_BTR]: 11269,
        [NAMES.ARTA]: 8728,
        [NAMES.PPO]: 646,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6848,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11632
    },
    "Jan 14 2024": {
        [NAMES.TROOPS]: 370000,
        [NAMES.TANKS]: 6075,
        [NAMES.BMP_BTR]: 11302,
        [NAMES.ARTA]: 8747,
        [NAMES.PPO]: 648,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6861,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11667
    },
    "Jan 15 2024": {
        [NAMES.TROOPS]: 370980,
        [NAMES.TANKS]: 6089,
        [NAMES.BMP_BTR]: 11322,
        [NAMES.ARTA]: 8771,
        [NAMES.PPO]: 650,
        [NAMES.JETS]: 329,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6865,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11698
    },
    "Jan 16 2024": {
        [NAMES.TROOPS]: 372090,
        [NAMES.TANKS]: 6113,
        [NAMES.BMP_BTR]: 11358,
        [NAMES.ARTA]: 8801,
        [NAMES.PPO]: 653,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6877,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11731
    },
    "Jan 17 2024": {
        [NAMES.TROOPS]: 372820,
        [NAMES.TANKS]: 6126,
        [NAMES.BMP_BTR]: 11372,
        [NAMES.ARTA]: 8811,
        [NAMES.PPO]: 653,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6884,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11755
    },
    "Jan 18 2024": {
        [NAMES.TROOPS]: 373600,
        [NAMES.TANKS]: 6147,
        [NAMES.BMP_BTR]: 11410,
        [NAMES.ARTA]: 8835,
        [NAMES.PPO]: 653,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6925,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11794
    },
    "Jan 19 2024": {
        [NAMES.TROOPS]: 374520,
        [NAMES.TANKS]: 6167,
        [NAMES.BMP_BTR]: 11445,
        [NAMES.ARTA]: 8854,
        [NAMES.PPO]: 654,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6929,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11831
    },
    "Jan 20 2024": {
        [NAMES.TROOPS]: 375270,
        [NAMES.TANKS]: 6171,
        [NAMES.BMP_BTR]: 11455,
        [NAMES.ARTA]: 8868,
        [NAMES.PPO]: 654,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6934,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11848
    },
    "Jan 21 2024": {
        [NAMES.TROOPS]: 376030,
        [NAMES.TANKS]: 6181,
        [NAMES.BMP_BTR]: 11466,
        [NAMES.ARTA]: 8875,
        [NAMES.PPO]: 655,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6936,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11862
    },
    "Jan 22 2024": {
        [NAMES.TROOPS]: 376860,
        [NAMES.TANKS]: 6192,
        [NAMES.BMP_BTR]: 11489,
        [NAMES.ARTA]: 8896,
        [NAMES.PPO]: 657,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6961,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11891
    },
    "Jan 23 2024": {
        [NAMES.TROOPS]: 377820,
        [NAMES.TANKS]: 6214,
        [NAMES.BMP_BTR]: 11548,
        [NAMES.ARTA]: 8947,
        [NAMES.PPO]: 657,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6961,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 11956
    },
    "Jan 24 2024": {
        [NAMES.TROOPS]: 378660,
        [NAMES.TANKS]: 6227,
        [NAMES.BMP_BTR]: 11579,
        [NAMES.ARTA]: 9008,
        [NAMES.PPO]: 659,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 6998,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 12005
    },
    "Jan 25 2024": {
        [NAMES.TROOPS]: 379610,
        [NAMES.TANKS]: 6257,
        [NAMES.BMP_BTR]: 11621,
        [NAMES.ARTA]: 9067,
        [NAMES.PPO]: 660,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7033,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 12044
    },
    "Jan 26 2024": {
        [NAMES.TROOPS]: 380600,
        [NAMES.TANKS]: 6265,
        [NAMES.BMP_BTR]: 11637,
        [NAMES.ARTA]: 9082,
        [NAMES.PPO]: 660,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7033,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 12064
    },
    "Jan 27 2024": {
        [NAMES.TROOPS]: 381370,
        [NAMES.TANKS]: 6271,
        [NAMES.BMP_BTR]: 11652,
        [NAMES.ARTA]: 9085,
        [NAMES.PPO]: 660,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7037,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 12072
    },
    "Jan 28 2024": {
        [NAMES.TROOPS]: 382110,
        [NAMES.TANKS]: 6280,
        [NAMES.BMP_BTR]: 11671,
        [NAMES.ARTA]: 9097,
        [NAMES.PPO]: 660,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7041,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 12103
    },
    "Jan 29 2024": {
        [NAMES.TROOPS]: 383180,
        [NAMES.TANKS]: 6290,
        [NAMES.BMP_BTR]: 11696,
        [NAMES.ARTA]: 9113,
        [NAMES.PPO]: 660,
        [NAMES.JETS]: 331,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7049,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 12149
    },
    "Jan 30 2024": {
        [NAMES.TROOPS]: 384140,
        [NAMES.TANKS]: 6300,
        [NAMES.BMP_BTR]: 11725,
        [NAMES.ARTA]: 9144,
        [NAMES.PPO]: 663,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7084,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 12191
    },
    "Jan 31 2024": {
        [NAMES.TROOPS]: 385230,
        [NAMES.TANKS]: 6310,
        [NAMES.BMP_BTR]: 11757,
        [NAMES.ARTA]: 9195,
        [NAMES.PPO]: 663,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7100,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 12231
    },
    "Feb 01 2024": {
        [NAMES.TROOPS]: 386230,
        [NAMES.TANKS]: 6322,
        [NAMES.BMP_BTR]: 11773,
        [NAMES.ARTA]: 9228,
        [NAMES.PPO]: 663,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7136,
        [NAMES.SHIPS]: 24,
        [NAMES.AUTO]: 12267
    },
    "Feb 02 2024": {
        [NAMES.TROOPS]: 387060,
        [NAMES.TANKS]: 6331,
        [NAMES.BMP_BTR]: 11792,
        [NAMES.ARTA]: 9274,
        [NAMES.PPO]: 663,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7146,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12316
    },
    "Feb 03 2024": {
        [NAMES.TROOPS]: 387940,
        [NAMES.TANKS]: 6341,
        [NAMES.BMP_BTR]: 11805,
        [NAMES.ARTA]: 9300,
        [NAMES.PPO]: 663,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7161,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12352
    },
    "Feb 04 2024": {
        [NAMES.TROOPS]: 388750,
        [NAMES.TANKS]: 6343,
        [NAMES.BMP_BTR]: 11818,
        [NAMES.ARTA]: 9331,
        [NAMES.PPO]: 663,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7173,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12393
    },
    "Feb 05 2024": {
        [NAMES.TROOPS]: 389560,
        [NAMES.TANKS]: 6348,
        [NAMES.BMP_BTR]: 11822,
        [NAMES.ARTA]: 9349,
        [NAMES.PPO]: 664,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7173,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12412
    },
    "Feb 06 2024": {
        [NAMES.TROOPS]: 390580,
        [NAMES.TANKS]: 6365,
        [NAMES.BMP_BTR]: 11857,
        [NAMES.ARTA]: 9367,
        [NAMES.PPO]: 665,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7173,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12453
    },
    "Feb 07 2024": {
        [NAMES.TROOPS]: 391470,
        [NAMES.TANKS]: 6372,
        [NAMES.BMP_BTR]: 11879,
        [NAMES.ARTA]: 9387,
        [NAMES.PPO]: 665,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 324,
        [NAMES.UAV]: 7177,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12486
    },
    "Feb 08 2024": {
        [NAMES.TROOPS]: 392380,
        [NAMES.TANKS]: 6383,
        [NAMES.BMP_BTR]: 11899,
        [NAMES.ARTA]: 9411,
        [NAMES.PPO]: 666,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7191,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12513
    },
    "Feb 09 2024": {
        [NAMES.TROOPS]: 393290,
        [NAMES.TANKS]: 6385,
        [NAMES.BMP_BTR]: 11921,
        [NAMES.ARTA]: 9435,
        [NAMES.PPO]: 666,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7209,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12551
    },
    "Feb 10 2024": {
        [NAMES.TROOPS]: 394270,
        [NAMES.TANKS]: 6394,
        [NAMES.BMP_BTR]: 11942,
        [NAMES.ARTA]: 9459,
        [NAMES.PPO]: 666,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7235,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12579
    },
    "Feb 11 2024": {
        [NAMES.TROOPS]: 395200,
        [NAMES.TANKS]: 6406,
        [NAMES.BMP_BTR]: 11956,
        [NAMES.ARTA]: 9475,
        [NAMES.PPO]: 666,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7257,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12592
    },
    "Feb 12 2024": {
        [NAMES.TROOPS]: 395990,
        [NAMES.TANKS]: 6416,
        [NAMES.BMP_BTR]: 11977,
        [NAMES.ARTA]: 9481,
        [NAMES.PPO]: 666,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7302,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12599
    },
    "Feb 13 2024": {
        [NAMES.TROOPS]: 397080,
        [NAMES.TANKS]: 6424,
        [NAMES.BMP_BTR]: 12004,
        [NAMES.ARTA]: 9500,
        [NAMES.PPO]: 667,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7332,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12623
    },
    "Feb 14 2024": {
        [NAMES.TROOPS]: 398140,
        [NAMES.TANKS]: 6433,
        [NAMES.BMP_BTR]: 12043,
        [NAMES.ARTA]: 9566,
        [NAMES.PPO]: 671,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7371,
        [NAMES.SHIPS]: 25,
        [NAMES.AUTO]: 12662
    },
    "Feb 15 2024": {
        [NAMES.TROOPS]: 399090,
        [NAMES.TANKS]: 6442,
        [NAMES.BMP_BTR]: 12090,
        [NAMES.ARTA]: 9620,
        [NAMES.PPO]: 671,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7404,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 12691
    },
    "Feb 16 2024": {
        [NAMES.TROOPS]: 400300,
        [NAMES.TANKS]: 6465,
        [NAMES.BMP_BTR]: 12129,
        [NAMES.ARTA]: 9641,
        [NAMES.PPO]: 671,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7408,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 12716
    },
    "Feb 17 2024": {
        [NAMES.TROOPS]: 401350,
        [NAMES.TANKS]: 6476,
        [NAMES.BMP_BTR]: 12145,
        [NAMES.ARTA]: 9669,
        [NAMES.PPO]: 671,
        [NAMES.JETS]: 332,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7413,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 12734
    },
    "Feb 18 2024": {
        [NAMES.TROOPS]: 402430,
        [NAMES.TANKS]: 6487,
        [NAMES.BMP_BTR]: 12198,
        [NAMES.ARTA]: 9709,
        [NAMES.PPO]: 674,
        [NAMES.JETS]: 335,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7449,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 12736
    },
    "Feb 19 2024": {
        [NAMES.TROOPS]: 403720,
        [NAMES.TANKS]: 6498,
        [NAMES.BMP_BTR]: 12232,
        [NAMES.ARTA]: 9733,
        [NAMES.PPO]: 674,
        [NAMES.JETS]: 336,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7460,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 12767
    },
    "Feb 20 2024": {
        [NAMES.TROOPS]: 404950,
        [NAMES.TANKS]: 6503,
        [NAMES.BMP_BTR]: 12268,
        [NAMES.ARTA]: 9773,
        [NAMES.PPO]: 675,
        [NAMES.JETS]: 338,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7521,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 12805
    },
    "Feb 21 2024": {
        [NAMES.TROOPS]: 406080,
        [NAMES.TANKS]: 6516,
        [NAMES.BMP_BTR]: 12338,
        [NAMES.ARTA]: 9826,
        [NAMES.PPO]: 678,
        [NAMES.JETS]: 338,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7560,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 12861
    },
    "Feb 22 2024": {
        [NAMES.TROOPS]: 407240,
        [NAMES.TANKS]: 6523,
        [NAMES.BMP_BTR]: 12373,
        [NAMES.ARTA]: 9867,
        [NAMES.PPO]: 680,
        [NAMES.JETS]: 339,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7596,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 12924
    },
    "Feb 23 2024": {
        [NAMES.TROOPS]: 408240,
        [NAMES.TANKS]: 6526,
        [NAMES.BMP_BTR]: 12410,
        [NAMES.ARTA]: 9916,
        [NAMES.PPO]: 682,
        [NAMES.JETS]: 339,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7628,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 12960
    },
    "Feb 24 2024": {
        [NAMES.TROOPS]: 409010,
        [NAMES.TANKS]: 6534,
        [NAMES.BMP_BTR]: 12425,
        [NAMES.ARTA]: 9952,
        [NAMES.PPO]: 684,
        [NAMES.JETS]: 340,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7659,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 12988
    },
    "Feb 25 2024": {
        [NAMES.TROOPS]: 409820,
        [NAMES.TANKS]: 6542,
        [NAMES.BMP_BTR]: 12441,
        [NAMES.ARTA]: 9981,
        [NAMES.PPO]: 684,
        [NAMES.JETS]: 340,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7681,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 13011
    },
    "Feb 26 2024": {
        [NAMES.TROOPS]: 410700,
        [NAMES.TANKS]: 6555,
        [NAMES.BMP_BTR]: 12478,
        [NAMES.ARTA]: 9993,
        [NAMES.PPO]: 686,
        [NAMES.JETS]: 340,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7707,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 13037
    },
    "Feb 27 2024": {
        [NAMES.TROOPS]: 411550,
        [NAMES.TANKS]: 6556,
        [NAMES.BMP_BTR]: 12494,
        [NAMES.ARTA]: 10009,
        [NAMES.PPO]: 686,
        [NAMES.JETS]: 340,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7729,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 13065
    },
    "Feb 28 2024": {
        [NAMES.TROOPS]: 412610,
        [NAMES.TANKS]: 6570,
        [NAMES.BMP_BTR]: 12508,
        [NAMES.ARTA]: 10029,
        [NAMES.PPO]: 688,
        [NAMES.JETS]: 342,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7753,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 13112
    },
    "Feb 29 2024": {
        [NAMES.TROOPS]: 413760,
        [NAMES.TANKS]: 6593,
        [NAMES.BMP_BTR]: 12552,
        [NAMES.ARTA]: 10070,
        [NAMES.PPO]: 690,
        [NAMES.JETS]: 342,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7768,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 13152
    },
    "Mar 01 2024": {
        [NAMES.TROOPS]: 414680,
        [NAMES.TANKS]: 6610,
        [NAMES.BMP_BTR]: 12582,
        [NAMES.ARTA]: 10106,
        [NAMES.PPO]: 691,
        [NAMES.JETS]: 345,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7794,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 13206
    },
    "Mar 02 2024": {
        [NAMES.TROOPS]: 415640,
        [NAMES.TANKS]: 6624,
        [NAMES.BMP_BTR]: 12611,
        [NAMES.ARTA]: 10153,
        [NAMES.PPO]: 696,
        [NAMES.JETS]: 346,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7829,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 13267
    },
    "Mar 03 2024": {
        [NAMES.TROOPS]: 416800,
        [NAMES.TANKS]: 6640,
        [NAMES.BMP_BTR]: 12639,
        [NAMES.ARTA]: 10188,
        [NAMES.PPO]: 696,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7843,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 13332
    },
    "Mar 04 2024": {
        [NAMES.TROOPS]: 417950,
        [NAMES.TANKS]: 6648,
        [NAMES.BMP_BTR]: 12660,
        [NAMES.ARTA]: 10210,
        [NAMES.PPO]: 698,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7845,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 13374
    },
    "Mar 05 2024": {
        [NAMES.TROOPS]: 419020,
        [NAMES.TANKS]: 6657,
        [NAMES.BMP_BTR]: 12688,
        [NAMES.ARTA]: 10258,
        [NAMES.PPO]: 700,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7863,
        [NAMES.SHIPS]: 26,
        [NAMES.AUTO]: 13423
    }
};
